import React, { Fragment, type ReactChild } from 'react';
import styled from 'styled-components';
import sassVars from '../../../styles/variables.module.scss';
import { crossImg, crossImgDark, warningIng } from '../../../commonImage';
import { ModalType } from '../../../models/ModalType';
import Logo from '../header/Logo';
const { lightgray, gray40, fontInterstate } = sassVars;
interface IOwnProps {
  headerTitle: string;
  onCloseBtnClicked?: () => void;
  hideCloseIcon?: boolean;
  showHeaderToolTip?: boolean;
  headerToolTip?: string;
  showHeaderIcon?: boolean;
  headerIcon?: ReactChild;
  customHeaderClass?: string;
  showHeaderTitleIcon?: boolean;
  headerTitleIcon?: string;
  souceChange?: boolean;
  isLoading: boolean|undefined;
  type:ModalType;
}

const ModalHeader: React.FC<IOwnProps> = ({
  headerTitle,
  onCloseBtnClicked,
  hideCloseIcon,
  showHeaderToolTip = false,
  headerToolTip = '',
  showHeaderIcon,
  headerIcon,
  customHeaderClass = '',
  showHeaderTitleIcon = false,
  headerTitleIcon = '',
  souceChange,
  isLoading,
  type
}: IOwnProps) => {
  let acceptPolicy = JSON.parse(localStorage.getItem('acceptPolicy') || '{}')
  return (
    <StyledModalHeader acceptPolicy={acceptPolicy} isLoading={isLoading} data-testid='modal-header'>
      {type === ModalType.Feedback ? (
        <Fragment>
          <div className={`se-model-header ${customHeaderClass}`}>
            {showHeaderTitleIcon ? (
              <>
                <span className={`headerTitleIcon ${customHeaderClass === 'user-feedback-header' ? 'feedback-header-icon': ''}`}>
                    <img src={headerTitleIcon} />
                </span>{' '}
                <span className='headerTitle'>{headerTitle}</span>
              </>
            ) : (
              <span className={'app-modal'}>{headerTitle}</span>
            )}
            <span className='headerCrossIcon'>
              {showHeaderIcon && <img src={crossImgDark} onClick={onCloseBtnClicked} />}
              {!hideCloseIcon && <></>}
            </span>
          </div>
        </Fragment>
      ) : 
      (type === ModalType.MyJuniorAI) ? (
        <Fragment>
          <div className={`se-model-header`}>
          <span className={`app-modal ${customHeaderClass}`}>{headerTitle}</span>
            <span className='headerCrossIcon'>
              {showHeaderIcon && <img src={crossImgDark} onClick={onCloseBtnClicked} />}
              {!hideCloseIcon && <></>}
            </span>
          </div>
          <hr></hr>
        </Fragment>
      ) : (
        <>
          {!souceChange ? (
            <div
              className={
                showHeaderTitleIcon
                  ? 'header-error se-model-header error-header'
                  : `se-model-header ${customHeaderClass}`
              }>
              {type === ModalType.CreateNewProject && (
                <div className='create-project-modal-header'>
                  <Logo />
                </div>
              )}
              {showHeaderTitleIcon ? (
                <>
                  <span>
                    <img src={headerTitleIcon} />
                  </span>{' '}
                  <span className='headerTitle'>{headerTitle}</span>
                </>
              ) : (
                <span
                  className={
                    type == ModalType.Privacy
                      ? 'headerPrivacyChangeTitle app-modal'
                      : type == ModalType.Terms
                        ? 'headerTermChangeTitle app-modal'
                        : 'headerChangeTitle app-modal'
                  }>
                  {headerTitle}
                </span>
              )}
              <span className='headerCrossIcon'>
                {showHeaderIcon && <img src={crossImgDark} onClick={onCloseBtnClicked} />}
                {!hideCloseIcon && <></>}
              </span>
            </div>
          ) : (
            <div className={'se-model-change-header'}>
              <span>
                <img src={warningIng} />
              </span>
              <span className='crossImg'>
                <img src={crossImg} onClick={onCloseBtnClicked} />
              </span>
              <div
                className={
                  type == ModalType.SourceChange
                    ? 'headerSourceChangeTitle'
                    : 'headerPrivacyChangeTitle'
                }>
                {headerTitle}
              </div>
              {showHeaderIcon && headerIcon}
              {!hideCloseIcon && <></>}
            </div>
          )}
        </>
      )}
    </StyledModalHeader>
  );
};

const StyledModalHeader = styled.div<{acceptPolicy : string|undefined , isLoading:boolean|undefined}>`
  .close-modal-icon:hover {
    cursor: pointer;
  }
  .headerCrossIcon {
    margin-top: 2%; 
    cursor: pointer;
  }
  .overflow-wrapper {
    margin-left: 5px;
  }
  .close-modal-icon {
    position: absolute;
    right: 20px;
  }

  .ul-list-style {
    list-style-type: none;
  }
  .se-react-model{
    border-radius:1px !important ; 
  }
  .se-model-header {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 5px 15px;
    text-align: center;
    letter-spacing: -0.24px;
    //border-bottom: 1px solid ${gray40};
    font-family: ${fontInterstate};
    font-size: 16px;
    color: ${lightgray};
    text-align: left;
    & .se-close {
      float: right;
      cursor: pointer;
    }
    .threat-se-close {
      margin-top: 0.3rem;
    }
  }
  .new-project-header{
    padding:0px 20px;
  }
  .motif-h4 {
    font-family: ${fontInterstate};
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: ${lightgray} !important;
  }
  .headerTitle {
    position: relative;
    top: -10px;
  }
  .header-error {
    background-color: lightGrey;
    color: #000;
    font-weight: 700;
  }
  .error-header {
    padding: 8px;
  }

  .cdd {
    text-align: center;
    width: 100%;
  }

  .headerChangeTitle {
    color: rgb(46, 46, 56);
    font-family: EYInterstate;
    padding-top: 24px;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    width: 80%;
    position: relative;
    visibility:${(props) => (props.isLoading ? 'hidden' : 'visible')} !important;
    font-weight:bold ;
}

.headerTermChangeTitle{
  color:    ${(props) => (props.acceptPolicy ? '#2e2e38' : 'rgb(246, 246, 250)')} !important;
  font-family: EYInterstate;
  padding-top: 24px;
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  width: 80%;
  text-align: center;
  position: relative;
  left: 6rem !important;
  visibility:${(props) => (props.isLoading ? 'hidden' : 'visible')} !important;
  font-weight:bold ;
}

.headerSourceChangeTitle{
    color: rgb(46, 46, 56);
    font-family: EYInterstate;
    padding-top: 24px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
}

.headerPrivacyChangeTitle{
    color:    ${(props) => (props.acceptPolicy ? '#2e2e38' : 'rgb(246, 246, 250)')} !important;
    font-family: EYInterstate;
    padding-top: 24px;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    width: 80%;
    text-align: center;
    position: relative;
    left: 4rem !important;
    visibility:${(props) => (props.isLoading ? 'hidden' : 'visible')} !important;
    font-weight:bold ;
}

.headerTermChangeTitle{
  color:    ${(props) => (props.acceptPolicy ? '#2e2e38' : 'rgb(246, 246, 250)')} !important;
  font-family: EYInterstate;
  padding-top: 24px;
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  width: 80%;
  text-align: center;
  position: relative;
  left: 6rem !important;
  visibility:${(props) => (props.isLoading ? 'hidden' : 'visible')} !important;
  font-weight:bold ;
}

.headerSourceChangeTitle{
  color: rgb(46, 46, 56);
    font-family: EYInterstate;
    padding-top: 24px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
}

  }
  .app-modal {
    padding-top: 10px;
  }
  .crossImg {
    margin-left: 400px;
    cursor: pointer;
  }
  .btn-cointaner {
    justify-content: space-between;
    flex-direction: column;
    display: flex;
  }
  .se-model-change-header {
    position: relative;
    padding: 24px 0px 0px 24px;
    text-align: center;
    letter-spacing: -0.24px;
    font-family: ${fontInterstate};
    font-size: 16px;
    color: ${lightgray};
    text-align: left;    
    & .se-close {
      float: right;
      cursor: pointer;
    }
    .threat-se-close {
      margin-top: 0.3rem;
    }
  }
  .create-project-modal-header {
    margin-top:2%;
  }
  .headerTitleIcon {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .feedback-header-icon{
    height: 48px;
    width: 48px;
    background: #F3F3F5;
    border-radius: 200px;
    padding: 12px;
    gap: 8px;
  }
  .user-feedback-header{
    padding: 15px 24px;
  }
  .myjunior-header {
    display: flex;
    align-items: center;
    color: #2E2E38 !important;
  }
`;

export default ModalHeader;
