import React, { Fragment, useEffect, useRef, useState } from "react";
import LoadingIndicator from "../LoadingIndicator";
import {
  IContent,
  IQuery,
  IQueryContent,
  ISectorQueryContent,
  RESPONSE_BY,
  RESPONSE_TYPE,
} from "../../../models/IQueryType";
import { MotifButton, MotifChip, MotifToast } from "@ey-xd/motif-react";
import SE_IMG from "../../../assets/images/strategyedge.png";
import OptionsButton from "../OptionsButton/OptionsButton";
import { convertNumber } from "../../../utils/numberConverter";
import { useDispatch, useSelector } from "react-redux";
import {
  getResponseSourceType,
  sanitizeHeaderText,
  handleAvatarInitials,
  openLinkInSameTab,
  convertArrayToPlainTextTable,
  convertRootObjectsToTable,
} from "../../../utils/commonUtils";
import LABELS from "../../../ui-constants/en.json";
import PageConfig from "../../../ui-constants/en.json";
import Lodingchunks from "../../../assets/images/Bars-1s-200px.svg";
import "./ChatReply.scss";
import AvatarInitials from "../../common/AvatarInitials";
import { copyImg, copySuccessImg, regenerateImg } from "../../../commonImage";
import { IStore, store } from "../../../store";
import TableContent from "./TableContent";
import apiRoutes from "../../../services/api";
import { sectorsDownloadSlide } from "../../../services/chatQuery.service";
import { exportToCSV } from "../utils/exportToCSV";
import { DataSourceTypes } from "../../../models/IDataSourceTypes";
import { publicSources } from "../../../label.constant";
import suggestionIcon from "../../../assets/images/suggestion.png";
import OPENAI_IMG_ICON from "../../../assets/images/openAIIcon.png";
import EngagementTable from "./EngagementTable";
import {
  initializeSession,
  setCurrentSessionId,
  logoutandClearSession,
  getCurrentSessionId,
} from "../../../services/session.service";
import apis from "../../../services/api";
import copyIcon from "../../../assets/images/byod-images/copy.svg";
import copySuccessIcon from "../../../assets/images/byod-images/copy-success.svg";
import { useNavigate } from "react-router-dom";
import {
  clearChatHistory,
  setLastSavedThreadID,
  setSelectedChatThread,
  setSelectedDocumentThread,
} from "../../../store/actions/userChatActions";
import { triggerDnfOpenAiSearch } from "../../../store/actions/commonActions";
import DynamicTextRenderer from "./ChatSteamResponseFormatting";
import TableMarketReport from './TableMarketReport';

type GetChatResponseProps = {
  chatData?: IQuery;
  loader?: boolean;
  isNew?: boolean;
  content?: IContent[];
  isSE: boolean;
  source?: string;
  messageLoading?: boolean;
  chunkLoading?: boolean;
  userQueryWithResponse?: any;
  isLastItem?: boolean;
  isEngagement?: boolean;
  scrollToBottom?: () => void;
  handleRegenerate?: (ev: any, query: string) => void;
  handleSendMessage?: (ev: any, query: string) => void;
  isCompletelyAbortedResposne?: boolean;
  isParitallyAbortedResponse?: boolean;
  message?: string;
  showDnfSuggestion?: boolean;
  isMarketReports?:boolean;
};

type OptionsButtonProps = {
  options: { onClick: any; label: string }[];
};
interface RootObject {
  Project_End_Date: string;
  Project_type: string;
  Geography: string[];
  Authors: Author[];
}
interface Author {
  author_name: string;
  author_email: string;
  role: string;
}
export type ParsedTableData = { headers: string[]; values: string[][] };

export const errorMessage = {
  SEError:
    "Oops! This data is not covered by Embryonic. Please refer to secondary research for more information on this.",
  OpenAIError: "Oops! Something went wrong. Please try again after sometime.",
};

export const getSourcesList = (replyData: any, replySources: any) => {
  let result = replyData.split("\n");
  const uniqueSrc = [...replySources, ...result];
  return uniqueSrc.filter((string) => Boolean(string));
};

export const getSourcesDetails = (replySources: Array<any>) => {
  let result = [];
  if (
    replySources &&
    (replySources[0] === "Embryonic" ||
      replySources[0] === "Open AI" ||
      replySources[0] === "Market Reports" ||
      replySources[0] === "Discover: Sector Insights" ||
      replySources[0] === "Discover Knowledge Capture" ||
      replySources[0] === "Surveys" ||
      replySources[0] === "URL" ||
      replySources[0] === "EY-P Strategy projects")
  ) {
    result = replySources.slice(1);
  } else {
    result = replySources;
  }
  const uniqueSrc = [...result];
  return uniqueSrc.filter((string) => Boolean(string));
};

export const prepareContentForTable = (content: IContent[]) => {
  const tableData: ParsedTableData = { headers: [], values: [] };
  content.forEach((el) => {
    if (el && typeof el === "object") {
      let valuesSegment: any[] = [];
      Object.entries(el).forEach(([key, value]) => {
        if (!tableData.headers.includes(key)) {
          tableData.headers.push(key);
        }
        if (
          key.toLowerCase().includes("year") ||
          key.toLowerCase().includes("id") ||
          key.toLowerCase().includes("quarter") ||
          key.toLowerCase().includes("count") ||
          key.toLowerCase().includes("date") ||
          key.toLowerCase().includes("Code") ||
          key.toLocaleLowerCase().includes("portfolio")
        ) {
          valuesSegment.push({ val: value });
        } else {
          valuesSegment.push(convertNumber(value, 1));
        }
      });
      tableData.values.push(valuesSegment);
    }
  });
  return tableData;
};
const ContentTable = ({
  tableContent,
  tableLength,
}: {
  tableContent?: ParsedTableData;
  tableLength?: number;
}) => {
  return (
    <div className="table-wrapper">
      <table className="content-table">
        <tbody>
          <tr>
            {tableContent?.headers.map((header: string) => (
              <th key={`table-header-${header}`}>
                {sanitizeHeaderText(header)}
              </th>
            ))}
          </tr>
          {tableContent?.values.map((valArr: string[], i) => (
            <tr key={`table-row-${i}`}>
              {valArr.map((val: any, i) => (
                <td key={`table-row-value-${i}`}>
                  <span title={val.title ? val.title : null}>{val.val}</span>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const GetChatResponse = ({
  chatData,
  loader,
  isNew,
  isSE,
  messageLoading,
  chunkLoading = false,
  userQueryWithResponse,
  isLastItem = false,
  isEngagement = false,
  scrollToBottom,
  handleRegenerate = () => {},
  handleSendMessage = () => {},
  message,
  isCompletelyAbortedResposne = false,
  isParitallyAbortedResponse = false,
  showDnfSuggestion = false,
  isMarketReports = false
}: GetChatResponseProps) => {
  const dispatch = useDispatch();
  // const { exportToCSV, exportToPPT } = useExportToFile();
  const [collapseHiddenLinks, setCollapseHiddenLinks] = React.useState(true);
  const [collapseHiddenText, setCollapseHiddenText] = React.useState(true);
  const [replyData, setReplyData] = React.useState<IQueryContent | null>();
  const [tableContent, setTableContent] = React.useState<ParsedTableData>();
  const [visibleLinks, setVisibleLinks] = React.useState<string[]>([]);
  const [visibleTextList, setvisibleTextList] = React.useState<string[]>([]);
  const [hiddenLinks, setHiddenLinks] = React.useState<string[]>([]);
  const [hiddenTextList, setHiddenTextList] = React.useState<string[]>([]);
  const [showOptions, setShowOptions] = React.useState(false);
  const [tokenSqlErrorMessage, setTokenSqlErrorMessage] = React.useState('');
  const [hasSourceLink, setHasSourceLink] = React.useState(true);
  const [showTable, SetShowTable] = React.useState<boolean>(true);
  const [showUploadInitiated, setShowUploadInitiated] = useState({
    action: "",
    flag: false,
  });
  const [csvDownloadError, setCsvDownloadError] = useState(false);
  const [showCopyText, setShowCopyText] = useState(false);
  const [showCopyFailure, setShowCopyFailure] = useState(false);
  const {
    isRightSideBarOpen,
    anySourceSelected,
    eyIPInterRadio = "",
    validURLinputValue,
    checkedCheckboxes = [],
  } = useSelector((state: IStore) => state.common);
  const userData = useSelector((state: IStore) => state.userProfile?.userData);
  const selectedDataSourceSection = useSelector(
    (state: any) => state.userChat.selectedDataSourceSection
  );
  const [sourceType, setSourceType] = React.useState("");
  const isSideBarOpen = false;
  const pathName = location.pathname;
  const navigate = useNavigate();

  const chatbotImageClass = isNew
    ? "chatreply__img-container"
    : "chatreply__img-container-dark";
  const companyProfileDownloadSuggestion =
    "Would you like to download the company profile?";
  const sectorTrendDownloadSuggestion =
    "Would you like to download the sector trend analysis report?";

  React.useEffect(() => {
    let data: IQueryContent | null = chatData
      ? chatData[RESPONSE_BY.STRATEGY_EDGE] || chatData[RESPONSE_BY.OPENAI]
      : null;
    if (data && !data.Sources) {
      data.Sources = [eyIPInterRadio];
    }
    (data?.summary &&
      data?.summary.length &&
      eyIPInterRadio === LABELS.SECTOR_INSIGHTS_TILE &&
      data?.summary?.toLowerCase().includes("no result found")) ||
    data?.summary?.toLowerCase().includes("no results found")
      ? SetShowTable(false)
      : SetShowTable(true);
    if(data && isLastItem)
      data.isChunkLoading = chunkLoading;
    setReplyData(data);
  }, [chatData]);

  React.useEffect(() => {
    if(replyData && isLastItem)
      replyData.isChunkLoading = chunkLoading;
    if (replyData?.Content) {
      if (replyData?.Content.length > 10) {
        setTableContent(
          prepareContentForTable(replyData?.Content.slice(0, 10))
        );
      } else {
        setTableContent(prepareContentForTable(replyData?.Content));
      }
    }

    if (replyData?.Sources && replyData.Sources.length > 0) {
      if (typeof replyData?.Sources === "string") {
        replyData.Sources = [replyData?.Sources];
      }
      const sources = getSourcesDetails(replyData?.Sources || []);
      if (sources.length > 0) {
        if (sourceType === "Surveys") {
          setvisibleTextList(sources && sources[0]?.split(",").slice(0, 2));
          setHiddenTextList(sources && sources[0]?.split(",").slice(2));
        } else {
          setVisibleLinks(sources.slice(0, 5));
          setHiddenLinks(sources.slice(5));
        }
        sources?.map((links) => {
          if (links.includes("://") || links.includes("www.")) {
            setHasSourceLink(true);
          } else {
            setHasSourceLink(false);
          }
        });
      }
      setReplyData({ ...replyData, summary: replyData?.summary });
    }

    if (checkedCheckboxes && checkedCheckboxes.length > 0) {
      if (
        checkedCheckboxes.length === 1 &&
        checkedCheckboxes.includes(publicSources[2])
      )
        setSourceType(publicSources[2]);
      else if (
        checkedCheckboxes.length === 1 &&
        checkedCheckboxes.includes(publicSources[0])
      )
        setSourceType(publicSources[0]);
      else if (
        checkedCheckboxes.length === 1 &&
        checkedCheckboxes.includes(publicSources[1])
      )
        setSourceType(publicSources[1]);
      else if (
        checkedCheckboxes.length === 2 &&
        checkedCheckboxes.includes(publicSources[0]) &&
        checkedCheckboxes.includes(publicSources[1])
      )
        setSourceType("Combined");
    }
    if (eyIPInterRadio) {
      setSourceType(eyIPInterRadio);
    }
    if (validURLinputValue) {
      setSourceType("Upload URL");
    }
    if (
      selectedDataSourceSection === 5 &&
      checkedCheckboxes.length === 0 &&
      !eyIPInterRadio
    ) {
      setSourceType("Experts");
    }
  }, [replyData?.summary, replyData?.Sources]);

  const getInfoSources = React.useCallback(
    (replyData: any, replySources: any) => {
      return getSourcesList(replyData, replySources);
    },
    [replyData?.summary]
  );

  const formatLink = (link: string) => {
    const sourceName = link?.split("-")[0];
    return sourceName ? sourceName : link;
  };
  const totalSlide: any = {
    Content: [
      {
        file_name: "",
        page_number: [],
      },
    ],
  };

  useEffect(() => {
    if (
      (replyData?.summary && replyData?.summary?.includes(LABELS.TOKEN_ERR)) ||
      replyData?.summary?.includes("SQL_ERROR") ||
      replyData?.summary?.includes("https://aka.ms/oai/quotaincrease")
    ) {
      setTokenSqlErrorMessage(LABELS.chat_reply.SE_TOKEN_ERROR_MSG);
    } else {
      setTokenSqlErrorMessage("");
    }
  }, [replyData?.summary]);

  useEffect(() => {
    scrollToBottom?.();
  }, [replyData?.showTable]);

  const openDnfQuestionInNewChatHistory = () => {
    dispatch(triggerDnfOpenAiSearch(true));
    navigate(pathName + "/");
    dispatch(clearChatHistory());
    dispatch(setLastSavedThreadID(0));
    dispatch(setSelectedChatThread({}));
  };

  const handleExportToExcel = async () => {
    setCsvDownloadError(false);
    setShowUploadInitiated({ action: "", flag: true });
    try {
      await exportToCSV(
        replyData?.sqlQuery,
        RESPONSE_BY.EMBROYNIC,
        userData,
        checkedCheckboxes,
        eyIPInterRadio,
        validURLinputValue
      );
      setShowUploadInitiated({ action: "", flag: false });
    } catch (error) {
      setCsvDownloadError(true);
      setTimeout(() => {
        setShowUploadInitiated({ action: "", flag: false });
      }, 3500);
    }
  };

  const convertEngagementReleventFilesToArray = () => {
    const relevantFiles = replyData?.relevantFiles;
    const tableArray: any = {
      headers: [
        sanitizeHeaderText("Name(s)"),
        sanitizeHeaderText("Role(s)"),
        sanitizeHeaderText("Project type"),
        sanitizeHeaderText("Project end date"),
        sanitizeHeaderText("Geographic focus of work"),
      ],
      values: [],
    };
    let referenceDocumentsContentArray: any = [];
    relevantFiles?.map((file: any) => {
      let authorsText = "";
      file?.Authors.forEach((author: any) => {
        authorsText +=
          author.author_name +
          (author.author_email && "(" + author.author_email + "), ");
      });
      if (authorsText[authorsText.length - 1] === " ")
        authorsText = authorsText.slice(0, -2);
      //authorsText = wrapText(authorsText, 100);
      referenceDocumentsContentArray.push({ val: authorsText });
      referenceDocumentsContentArray.push({ val: "Role 1, Role 2" });
      referenceDocumentsContentArray.push({ val: "Pro Type" });
      referenceDocumentsContentArray.push({ val: "Pro End dtae" });
      referenceDocumentsContentArray.push({ val: "Geographic" });
      tableArray.values.push(referenceDocumentsContentArray);
    });
    return tableArray;
  };

  const convertReleventFilesToArray = () => {
    const relevantFiles = replyData?.relevantFiles;
    const tableArray: any = {
      headers: isMarketReports
      ? [sanitizeHeaderText('Document Title'), sanitizeHeaderText('Page no.s'), sanitizeHeaderText('Source')]
      : [sanitizeHeaderText('Relevant Document(s)'), sanitizeHeaderText('Authors')],
      values: [],
    };
    relevantFiles?.map((file: any) => {
      let referenceDocumentsContentArray: any = [];
      let authorsText = '';
      let pageNo = '';
      referenceDocumentsContentArray.push({ val: file.file_name });
      if (!isMarketReports) {
        file?.authors?.forEach((author: any) => {
          authorsText +=
            author.author_name + (author.author_email && '(' + author.author_email + '), ');
        });

        if (authorsText[authorsText.length - 1] === ' ') authorsText = authorsText.slice(0, -2);
        referenceDocumentsContentArray.push({ val: authorsText });
      } else {
        file.page_number.length &&
          file.page_number.forEach((numer: any, i: number) => {
            pageNo = pageNo + numer + (i === file.page_number.length - 1 ? '' : ', ');
          });
        referenceDocumentsContentArray.push({ val: pageNo });
        referenceDocumentsContentArray.push({ val: file?.source });
      }
      tableArray.values.push(referenceDocumentsContentArray);
    });
    return tableArray;
  };

  const handleCopy = async () => {
    try {
      const summaryText = replyData?.summary || "";

      const tableDataText =
        replyData?.Content && replyData?.Content?.length > 0
          ? convertArrayToPlainTextTable(tableContent)
          : "";
      const relevantFilesTableContent = isEngagement
        ? convertEngagementReleventFilesToArray()
        : convertReleventFilesToArray();
      const relevantFilesTableDataText =
        replyData?.relevantFiles && replyData?.relevantFiles?.length > 0
          ? convertArrayToPlainTextTable(relevantFilesTableContent)
          : "";
      const engagementTableDataText =
       (isEngagement && replyData?.relevantFiles && replyData?.relevantFiles?.length > 0)
          ? convertRootObjectsToTable(replyData?.relevantFiles)
          : "";

      let textToCopy = "";
      if (summaryText.length > 0) {
        textToCopy += summaryText;
      }

      if (summaryText.length > 0 && tableDataText.length > 0) {
        textToCopy += "\n";
      }

      if (tableDataText.length > 0) {
        textToCopy += tableDataText;
      }

      if (!isEngagement && relevantFilesTableDataText.length > 0) {
        textToCopy += !isMarketReports
          ? '\nReference Documents : \n' + relevantFilesTableDataText
          : '\nMarket Reports : \n' + relevantFilesTableDataText;
      }
      if(engagementTableDataText.length > 0) { 
        textToCopy += "\nHere is the list of experts : \n\n" + engagementTableDataText;
      }
      // Use the Clipboard API to copy the text
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setShowCopyText(true);
        })
        .catch((err) => {
          setShowCopyText(false);
          setShowCopyFailure(true);
        });
    } catch (err) {
      setShowCopyFailure(true);
    }
  };
  const handleCopyWrapper = (): void => {
    handleCopy().catch((error) => {
      setShowCopyFailure(true);
    });
  };

  useEffect(() => {
    if (showCopyText) {
      setTimeout(() => {
        setShowCopyText(false);
      }, 3000);
    }
    if (showCopyFailure) {
      setTimeout(() => {
        setShowCopyFailure(false);
      }, 3000);
    }
  }, [showCopyFailure, showCopyText]);

  const renderCopyButton = () => {
    let copyButtonText = "Copy";
    if(showCopyText) copyButtonText = "Copied to clipboard";
    if(showCopyFailure) copyButtonText = "Failed to copy response to clipboard";
    return (
      <div className="chat-copy-button-div">
        <div className="chat-reply-copy-response-icon" onClick={()=> handleCopyWrapper()} title={showCopyFailure? "Failed to copy response to clipboard" : showCopyText ? "Copied to clipboard" : "Copy response"}>
          <span className="chat-copy-text"><b>{copyButtonText}</b></span>
          <MotifButton
            className="copy-button"            
          >
            {(!showCopyText || showCopyFailure) && <img
              src={copyIcon}
              alt="copy icon"
            />}
            {showCopyText && <img
              src={copySuccessIcon}
              alt="copied icon"
            />}
          </MotifButton>          
        </div>
        <span className="spacer"></span>      
      </div>
    );
  };

  return (
    <Fragment>
      <div className={!isSideBarOpen ? 'chatreply' : 'chatreply sidebar-open'}>
        <div className={chatbotImageClass}>
          <img className='open-ai-icon' src={OPENAI_IMG_ICON} alt='openai-icon' />
        </div>
        {!loader && (
          <div
            className={!isSideBarOpen ? 'chatreply__resp-box' : 'chatreply__resp-box sidebar-open'}>
            <div className={'chatreply__resp-box__resp'}>
              <div>
                <span className={isCompletelyAbortedResposne ? 'stop-generatio-label' : ''}>
                  <DynamicTextRenderer text={replyData?.ResponseType !== RESPONSE_TYPE.ERROR && replyData?.summary
                      ? chatData?.[RESPONSE_BY.OPENAI] &&
                        replyData.summary.startsWith(PageConfig.SORRY_ERR) &&
                        !replyData.summary.startsWith(PageConfig.SORRY_ERR1)
                        ? PageConfig.GENERIC_OOPS_ERROR
                        : chatData?.[RESPONSE_BY.STRATEGY_EDGE] &&
                            replyData.summary.startsWith(PageConfig.SORRY_ERR) &&
                            !replyData.summary.startsWith(PageConfig.SORRY_ERR1)
                          ? validURLinputValue
                            ? PageConfig.chat_reply.UPLOAD_URL_ERROR
                            : PageConfig.chat_reply.EMBROYNIC_ERROR
                          : replyData.summary.trim()
                      : ''} 
                      />
                  {chunkLoading && isLastItem && (
                    <span>
                      <LoadingIndicator
                        color='#2E2E38'
                        parentWidth='8px'
                        zeroPercentheight='28px'
                        fifltyPercentheight='10px'
                        indicatorHeight={'19px'}
                      />
                    </span>
                  )}
                  {replyData?.ResponseType === RESPONSE_TYPE.ERROR &&
                    !validURLinputValue &&
                    chatData?.[RESPONSE_BY.STRATEGY_EDGE] &&
                    !tokenSqlErrorMessage &&
                    PageConfig.chat_reply.EMBROYNIC_ERROR}
                  {replyData?.ResponseType === RESPONSE_TYPE.ERROR &&
                    chatData?.[RESPONSE_BY.STRATEGY_EDGE] &&
                    validURLinputValue &&
                    !tokenSqlErrorMessage &&
                    PageConfig.chat_reply.UPLOAD_URL_ERROR}
                  {replyData?.ResponseType === RESPONSE_TYPE.ERROR &&
                    chatData?.[RESPONSE_BY.STRATEGY_EDGE] &&
                    tokenSqlErrorMessage.length > 0 &&
                    tokenSqlErrorMessage}
                  {replyData?.ResponseType === RESPONSE_TYPE.ERROR &&
                    chatData?.[RESPONSE_BY.OPENAI] &&
                    LABELS.chat_reply.OPENAI_ERROR_MSG}
                </span>
                <div>
                  {!isEngagement &&
                    replyData?.showTable &&
                    replyData?.ResponseType === RESPONSE_TYPE.TABLE && (
                      <>
                        <ContentTable
                          tableContent={tableContent}
                          tableLength={replyData?.Content?.length}
                        />
                      </>
                    )}
                  {!isMarketReports &&
                    !isEngagement &&
                    replyData?.showTable &&
                    showTable &&
                    (replyData?.relevantFiles?.length ?? 0) > 0 && (
                      <>
                        <TableContent tableContent={replyData?.relevantFiles || []} />
                      </>
                    )}

                  {replyData?.showTable &&
                    showTable &&
                    (replyData?.relevantFiles?.length ?? 0) > 0 &&
                    isEngagement && (
                      <div className='chatreply__engagement-container'>
                        <EngagementTable tableContent={replyData?.relevantFiles ?? []} />
                      </div>
                    )}
                </div>
              </div>
            </div>
            {!chunkLoading &&
              checkedCheckboxes &&
              checkedCheckboxes.length > 0 &&
              chatData?.[RESPONSE_BY.STRATEGY_EDGE]?.responseType !== RESPONSE_TYPE.ERROR && (
                <div className='disclaimer'>
                  {replyData?.sqlQuery  
                      && (
                      <div>
                        {`** To download the entire information, click on `}
                        <span
                          className='span-fontweight'
                          onClick={handleExportToExcel}>{`Export to excel.`}</span>
                      </div>
                    ) }
                </div>
              )}
            {!chunkLoading && (eyIPInterRadio?.length > 0 || selectedDataSourceSection == 5) && (
              <Fragment>
                <div className='disclaimer'></div>
              </Fragment>
            )}
            {replyData?.Sources && (
              <Fragment>
                {!isCompletelyAbortedResposne && (
                  <div
                    className={
                      isSideBarOpen
                        ? 'chatreply__resp-box__footer slider'
                        : 'chatreply__resp-box__footer'
                    }>
                    {isParitallyAbortedResponse && (
                      <div className='chatreply__resp-box__footer__source-box'>
                        <p className='stop-generatio-label'>
                          {LABELS.chat_reply.PARTIAL_STOP_GENERATION_MSG}
                        </p>
                      </div>
                    )}
                    {sourceType !== 'Surveys' && (
                      <div className='chatreply__resp-box__footer__source-box'>
                        <p>Source: {getResponseSourceType(sourceType, validURLinputValue)}</p>
                        {}
                      </div>
                    )}

                    {sourceType === 'Surveys' && (
                      <div
                        className={`chatreply__resp-box__footer__source-box ${
                          sourceType == 'Surveys' && !collapseHiddenText
                            ? 'chatreply__resp-box__footer__survey-moreitems'
                            : sourceType == 'Surveys' &&
                              replyData?.Sources[0]?.trim() !== 'EY conducted surveys' &&
                              replyData?.Sources[0]?.trim() !== 'Surveys' &&
                              'chatreply__resp-box__footer__survey-lessitems'
                        } ${isLastItem ? 'chatreply__resp-box__footer__source-lastItem' : ''}`}>
                        {visibleTextList?.length > 0 &&
                        replyData.Sources[0]?.trim() !== 'EY conducted surveys' ? (
                          <p>
                            <span
                              className={
                                isSideBarOpen
                                  ? 'chatreply__resp-box__footer__links-box chatreply__resp-box__footer__surveys-links-box slider'
                                  : 'chatreply__resp-box__footer__links-box chatreply__resp-box__footer__surveys-links-box'
                              }>
                              {(collapseHiddenText
                                ? visibleTextList
                                : [...visibleTextList, ...hiddenTextList]
                              ).map((link: string, index) => {
                                return (
                                  <>
                                    {index == 0 ? 'Source(s): ' : ''}
                                    {
                                      <MotifChip className='link-container surveychip' title={link}>
                                        {link}
                                      </MotifChip>
                                    }
                                  </>
                                );
                              })}
                              {collapseHiddenText &&
                                hiddenTextList &&
                                hiddenTextList?.length > 0 && (
                                  <MotifChip className='link-container surveychip'>
                                    {' '}
                                    <a
                                      href={''}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setCollapseHiddenText(false);
                                      }}
                                      className={'chatreply__no-links chatreply__survey_no-links'}>
                                      + {hiddenTextList?.length} more
                                    </a>
                                  </MotifChip>
                                )}
                            </span>
                          </p>
                        ) : (
                          ((visibleTextList?.length > 0 &&
                            replyData?.Sources[0]?.trim() === 'EY conducted surveys') ||
                            (replyData?.summary &&
                              replyData?.summary.toLowerCase()?.indexOf('no results found') >
                                -1)) && <p>Source: {getResponseSourceType(sourceType)}</p>
                        )}
                        {}
                      </div>
                    )}
                    {visibleLinks?.length > 0 && (
                      <div
                        className={
                          isSideBarOpen
                            ? hasSourceLink
                              ? 'chatreply__resp-box__footer__links-box slider'
                              : 'chatreply__no-links-gap'
                            : hasSourceLink
                              ? 'chatreply__resp-box__footer__links-box'
                              : 'chatreply__no-links-gap'
                        }>
                        {(collapseHiddenLinks
                          ? visibleLinks
                          : [...visibleLinks, ...hiddenLinks]
                        ).map((link: string, index) => {
                          const linkHref = link.includes('://')
                            ? link.split('://')[1]
                            : link.split('www.')[1];
                          return linkHref ? (
                            <div className='link-container'>
                              <a
                                className='chatreply__resp-box__footer__links-box__source-link'
                                target='_blank'
                                key={`sources-link-${index}`}
                                href={`http://${linkHref}`}>
                                {index + 1}.{formatLink(link)}
                              </a>
                            </div>
                          ) : (
                            <span
                              className={
                                hasSourceLink
                                  ? 'chatreply__resp-box__footer__links-box__source-link'
                                  : 'chatreply__no-links'
                              }
                              key={`sources-link-${index}`}>
                              {formatLink(link)}
                            </span>
                          );
                        })}
                        {collapseHiddenLinks && hiddenLinks && hiddenLinks?.length > 0 && (
                          <a
                            href={''}
                            onClick={(e) => {
                              e.preventDefault();
                              setCollapseHiddenLinks(false);
                            }}
                            className={
                              hasSourceLink
                                ? 'chatreply__resp-box__footer__links-box__source-link'
                                : 'chatreply__no-links'
                            }>
                            + {hiddenLinks?.length} more
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                )}
                <div className='regenerate-container'>
                  {isLastItem && messageLoading === false && (
                    <div
                      title="Regenerate response"
                      className='chatreply__regenerate'
                      onClick={() => handleRegenerate(null, message || '')}>
                      <img src={regenerateImg} />
                    </div>
                  )}
                  {!replyData?.isChunkLoading && (<div
                    title={
                      showCopyFailure
                        ? 'Failed to copy response to clipboard'
                        : showCopyText
                          ? 'Copied to clipboard'
                          : 'Copy response'
                    }
                    className='chatreply__copy'
                    onClick={() => handleCopyWrapper()}>
                    {(!showCopyText || showCopyFailure) && <img src={copyImg} alt='copy icon' />}
                    {showCopyText && <img src={copySuccessImg} alt='copied icon' />}
                  </div>
                  )}
                </div>
              </Fragment>
            )}
            {isMarketReports &&
              replyData?.showTable &&
              showTable &&
              (replyData?.relevantFiles?.length ?? 0) > 0 && (
                <>
                  <TableMarketReport tableContent={replyData?.relevantFiles || []} />
                </>
              )}
          </div>
        )}
        {loader && !chunkLoading && (
          <div className={'chatreply__loader-container'}>
            <LoadingIndicator
              color='#2E2E38'
              parentWidth='13px'
              zeroPercentheight='38px'
              fifltyPercentheight='16px'
            />
          </div>
        )}
      </div>
      {isLastItem &&
        messageLoading === false &&
        checkedCheckboxes?.length > 0 &&
        (replyData?.suggestions?.followup.length > 0 ||
          replyData?.suggestions?.download.length > 0) && (
          <div className='chatreply__suggestions_container'>
            <div className='suggestion-img-container'>
              <img src={suggestionIcon} alt='suggestionIcon-alt' />
              <span className='suggestions-text'>Suggestions</span>
            </div>
            <div className='chip-container'>
              {replyData?.suggestions?.followup.length > 0 &&
                replyData?.suggestions?.followup.map(
                  (question: any, index: number) =>
                    question &&
                    question.length > 0 && (
                      <MotifChip
                        key={index}
                        className='chatreply__suggestions'
                        onClick={() => handleSendMessage(null, question)}>
                        {question}
                      </MotifChip>
                    )
                )}

              {replyData?.suggestions?.portfolio?.length > 0 &&
                replyData?.suggestions?.portfolio.map(
                  (company: any, index: number) =>
                    company &&
                    company.length > 0 && (
                      <MotifChip
                        key={index}
                        className='chatreply__suggestions'
                        onClick={() => {
                          const queryObject = {
                            companyName: encodeURIComponent(company)
                          };
                          const queryValue = JSON.stringify(queryObject);

                          const linkObject = {
                            url: `${process.env.REACT_APP_GENERATE_APP_COMPANYPROFILE_URL}?q=${queryValue}`,
                            name: 'Company Profile'
                          };

                          openLinkInSameTab(linkObject, '');
                        }}>
                        {companyProfileDownloadSuggestion}
                      </MotifChip>
                    )
                )}

              {replyData?.suggestions?.sector?.length > 0 &&
                replyData?.suggestions?.sector.map(
                  (Sector: any, index: number) =>
                    Sector &&
                    Sector.length > 0 && (
                      <MotifChip
                        key={index}
                        className='chatreply__suggestions'
                        onClick={() => {
                          const linkObject = {
                            url: process.env.REACT_APP_GENERATE_APP_SECTORTREND_URL,
                            name: 'Sector Trend'
                          };

                          openLinkInSameTab(linkObject, '');
                        }}>
                        {sectorTrendDownloadSuggestion}
                      </MotifChip>
                    )
                )}

              {replyData?.sqlQuery &&
                replyData?.suggestions?.download.length > 0 &&
                replyData?.suggestions?.download.map(
                  (question: any, index: number) =>
                    question &&
                    question.length > 0 && (
                      <MotifChip
                        key={index}
                        className='chip'
                        onClick={async () => {
                          setCsvDownloadError(false);
                          setShowUploadInitiated({
                            action: 'excel',
                            flag: true
                          });
                          await exportToCSV(
                            replyData?.sqlQuery,
                            RESPONSE_BY.EMBROYNIC,
                            userData,
                            checkedCheckboxes,
                            eyIPInterRadio,
                            validURLinputValue
                          );
                          setShowUploadInitiated({ action: '', flag: false });
                        }}>
                        {question}
                      </MotifChip>
                    )
                )}
            </div>
          </div>
        )}
      {isLastItem && messageLoading === false && (
        <div
          className={`chatreply__suggestions_container ${
            showDnfSuggestion ? 'handleDnfvisible' : 'handleDnfHidden'
          }`}>
          <div className='suggestion-img-container'>
            <img src={suggestionIcon} />
            <span className='suggestions-text'>Suggestions</span>
          </div>
          <div className='chip-container'>
            <MotifChip
              key='open_ai_question'
              className='chatreply__suggestions'
              onClick={() => openDnfQuestionInNewChatHistory()}>
              Would you like to search for this question using OpenAI?
            </MotifChip>
          </div>
        </div>
      )}
      {showUploadInitiated.flag && (
        <MotifToast
          className={!csvDownloadError ? 'toast-container-public' : 'toast-container-public-error'}
          variant={!csvDownloadError ? 'info' : 'error'}
          actionName=''
          position='bottom'
          onClose={() => {
            setShowUploadInitiated({ action: '', flag: false });
          }}>
          {showUploadInitiated.action !== 'ppt' ? (
            <div>
              {!csvDownloadError
                ? 'Downloading Excel'
                : 'Download excel is not supported in history chats, try asking the question again! '}
            </div>
          ) : (
            <div>Downloading excel</div>
          )}
        </MotifToast>
      )}
    </Fragment>
  );
};

export default React.memo(GetChatResponse);
