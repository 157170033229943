import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import CustomButton from '../motifComponents/button/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../store';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  chatSearchInputValue,
  selectedSourceValues,
  setRightSideBarOpenClose,
  setSideBarOpenClose,
  setSurveyDocOpenClose
} from '../../store/actions/commonActions';
import { MotifLabel } from '@ey-xd/motif-react';
import { buttonImg, newChatIcon } from '../../commonImage';
import { MAX_TEXT_LENGTH, MIN_SYMBOLS_TO_START_SEARCHING, contentAge } from '../../app.constant';
import InsightHeader from '../sectorInsights/SectorComponents/InsightHeader';
import apiRoutes from '../../services/api';
import { getChatThreadConversations, getSectorsHierarchy } from '../../services/chatQuery.service';
import { RESPONSE_BY } from '../../models/IQueryType';
import {
  setAnySourceSelected,
  setChatHistory,
  setChatMessageHistory,
  setUserFirstQuery,
  toggleLoader,
  setIsChatThreadLoaded,
  setSurveyList
} from '../../store/actions/userChatActions';
import { DataSourceTypes } from '../../models/IDataSourceTypes';
import LABELS from '../../ui-constants/en.json';
import { IsJSONResponse, handleSessionID } from '../../utils/commonUtils';
import { initializeSession, setCurrentSessionId } from '../../services/session.service';
import QuestionsPage from './QuestionsPage';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';

const NewChatPage = () => {
  const textFieldValue = useSelector((state: IStore) => state?.common?.chatSearchInput);
  const isTextFieldEmpty = textFieldValue !== undefined && textFieldValue.length > 0;
  const anySourceSelected = useSelector((state: IStore) => state?.common?.anySourceSelected);
  const isRightSideBarOpen = useSelector((state: IStore) => state.common.isRightSideBarOpen);
  const [isSourceSelected, setSourceSelected] = useState(true);
  const [inputValuelength, setInputValueLength] = useState('');
  const eyIPInterRadio = useSelector((state: IStore) => state?.common?.eyIPInterRadio);
  const [checkedsector, setCheckedsector] = useState<[]>([]);
  const [checkedgeography, setCheckedgeography] = useState<[]>([]);
  const [selectedContentAge, setSelectedContentAge] = useState<string>('');
  const { isClearLoading, isLoading } = useSelector((state: any) => state.userChat);
  const selectedChatThread = useSelector((store: IStore) => store.userChat.selectedChatThread);
  const isSurveyDocOpen = useSelector((state: IStore) => state.common.isSurveyDocOpen);
  const selectedSurveyList = useSelector((store: IStore) => store.userChat.selectedSurveyList);
  const refToScroll = useRef<HTMLDivElement>(null);
  const [isSurveyDocument, setIsSurveyDocument] = useState(isSurveyDocOpen || false);
  const [errorText, setErrorMessage] = useState('');
  const [chatSearchInput, setChatSearchInputValue] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(chatSearchInputValue(chatSearchInput));

  useEffect(() => {
    if (anySourceSelected) setSourceSelected(true);
  }, [anySourceSelected]);

  useEffect(() => {
    if (!selectedChatThread?.threadId && eyIPInterRadio === 'Surveys') {
      dispatch(setSurveyList([]));
      setIsSurveyDocument(false);
    }
  }, []);

  const handleSourceRoute = (type?: string) => {
    switch (type) {
      case 'EY-P Strategy projects':
        return navigate('/kcChatPage');
        case 'Market Reports':
          return navigate('/marketReports');
      case 'Surveys':
        return navigate('/surveyChatPage');
      // case 'Transcripts':
      //   return navigate('/transScriptChatPage');
      case 'Discover: Sector Insights':
        return navigate('/sectorChatPage');
      default:
        return navigate('/publicChatPage');
    }
  };

  const handleSendMessage = (query?: string) => {
    if (!isClearLoading && !isLoading && anySourceSelected) {
      setSourceSelected(true);
      // getInputData?.(chatSearchInput || '');
      if ((chatSearchInput && chatSearchInput.trim().length) || query) {
        handleSessionID(selectedChatThread);
        let newMsg = {
          [RESPONSE_BY.USER]: {
            summary: chatSearchInput || query
          }
        };
        dispatch(setChatHistory([newMsg]));
        refToScroll?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });

        setChatSearchInputValue('');
        dispatch(toggleLoader(true));
        setSourceSelected(true);
        dispatch(setUserFirstQuery(chatSearchInput));
        handleSourceRoute(eyIPInterRadio);
        if (Object.keys(selectedChatThread).length === 0 && !localStorage.isSidebarManuallyClosed) {
          dispatch(setRightSideBarOpenClose(true));
        }
      }
    } else {
      dispatch(setAnySourceSelected(false));
      setSourceSelected(false);
    }
  };
  const handleQueryApi = () => {
    if (!anySourceSelected) {
      setErrorMessage('Please select a data source to continue');
      setSourceSelected(false);
      dispatch(setSideBarOpenClose(true));
    } else if (
      !selectedSurveyList?.length &&
      eyIPInterRadio === 'Surveys' &&
      chatSearchInput.length
    ) {
      setIsSurveyDocument(true);
      setErrorMessage('Please select the relevant survey(s) to continue');
    } else {
      handleSendMessage();
    }
  };
  const handleKeyDown = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      if (!anySourceSelected) {
        setErrorMessage('Please select a data source to continue');
      } else if (!selectedSurveyList?.length && eyIPInterRadio === 'Surveys') {
        dispatch(setSurveyDocOpenClose(true));
        setErrorMessage('Please select the relevant survey(s) to continue');
      } else {
        handleSendMessage();
      }
    }
  };
  const handleInputClick = () => {
    if (eyIPInterRadio === 'Surveys' && selectedSurveyList && selectedSurveyList.length == 0)
      setIsSurveyDocument(true);
    return;
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue.length <= 1000) {
      setInputValueLength(newValue);
    }
    setChatSearchInputValue(event.target.value);
  };

  const loadSelectedThreadConversations = async () => {
    dispatch(setIsChatThreadLoaded(false));
    setCurrentSessionId(selectedChatThread?.sessionId);
    let currentThreadConversations = await getChatThreadConversations(selectedChatThread?.threadId);
    if (currentThreadConversations?.messages) {
      let sources: any = [];
      let formattedREsponse = currentThreadConversations.messages.map((message: any) => {
        if (sources.indexOf(message.dataSubSource) === -1) sources.push(message.dataSubSource);
        let computedResponse: any = {};
        if (IsJSONResponse(message.response)) {
          computedResponse = JSON.parse(message.response);
          computedResponse.messageId = message.messageId;
        } else {
          computedResponse = {
            summary: message.response,
            Sources: [message.dataSubSource],
            messageId: message.messageId
          };
        }

        const obj = {
          [RESPONSE_BY.USER]: {
            summary: message.question
          },
          [RESPONSE_BY.STRATEGY_EDGE]: computedResponse
        };
        return obj;
      });
      dispatch(setChatMessageHistory(formattedREsponse));

      dispatch(
        selectedSourceValues({
          eyIPInterRadio:
            selectedChatThread.dataSource === DataSourceTypes.EYIP
              ? selectedChatThread.dataSubSource
              : '',
          checkedCheckboxes:
            selectedChatThread.dataSource === DataSourceTypes.PublicData
              ? selectedChatThread.dataSubSource.split(',')
              : [],
          validURLinputValue:
            selectedChatThread.dataSource === DataSourceTypes.UploadURL
              ? selectedChatThread.dataSubSource
              : ''
        })
      );

      if (selectedChatThread.dataSource === DataSourceTypes.EYIP) {
        if (currentThreadConversations.messages[0].dataSubSource == 'Surveys')
          navigate('/surveyChatPage');
        if (currentThreadConversations.messages[0].dataSubSource == 'Discover: Sector Insights')
          navigate('/sectorChatPage');
      }
      dispatch(setIsChatThreadLoaded(true));
    }
  };

  useEffect(() => {
    if (selectedChatThread?.threadId) {
      dispatch(setSideBarOpenClose(true));
      loadSelectedThreadConversations();
    }
    localStorage.removeItem('surveycustomperiod');
    localStorage.removeItem('surveyDateSelectedOption');
    localStorage.removeItem('selectedSurveyList');
    localStorage.removeItem('selectedDropSectors');
    localStorage.removeItem('surveytoDate');
    localStorage.removeItem('surveyfromDate');
    localStorage.removeItem('queryFromDate');
    localStorage.removeItem('queryToDate');
  }, [selectedChatThread]);

  const handleSurveyDocView = () => {
    setIsSurveyDocument(!isSurveyDocument);
  };

  const handleSurveyQuerySelection = (query: string) => {
    setChatSearchInputValue(query);
    handleQueryApi();
  };
  useEffect(() => {
    dispatch(setSurveyDocOpenClose(isSurveyDocument));
  }, [isSurveyDocument]);

  const queryInfo = JSON.stringify({
    sectors: checkedsector,
    geographies: checkedgeography,
    content_ages: selectedContentAge
  });
  const breadcrumbs = [{ label: 'Home' }, { label: 'EY Source Data', current: true }];

  return (
    <StyleNewChatComponent
      isSourceSelected={isSourceSelected}
      isSurveyDocument={isSurveyDocument}
      whichSourceSelected={eyIPInterRadio}
      isRightSideBarOpen={isRightSideBarOpen}>
      {eyIPInterRadio === 'Surveys' && (
        <>
          <div className={isRightSideBarOpen ? 'breadcrumbOpen' : 'breadcrumb'}>
            <Breadcrumb items={breadcrumbs} />
          </div>
          <div className='survey-button-container'>
            <div className='survey-button' onClick={handleSurveyDocView}>
              {!isSurveyDocument ? LABELS.VIEW_DOCS : LABELS.HIDE_DOCS}
            </div>
          </div>
        </>
      )}
      <div className='center'>
        <img src={newChatIcon} alt='new chat icon' className='responsive-image' />
      </div>
      <div className='search-bar'>
        <div className='input-container'>
          <input
            type='text'
            value={chatSearchInput}
            onChange={handleInputChange}
            className='input-bar'
            placeholder='What would you like to ask?'
            maxLength={MAX_TEXT_LENGTH}
            onClick={handleInputClick}
            onKeyDown={(evt: any) => handleKeyDown(evt)}
          />
          <div className='warning-length'>
            {!isSourceSelected && <div className='warning'>{errorText}</div>}
            {!selectedSurveyList?.length && eyIPInterRadio === 'Surveys' && (
              <div className='warning'>
                {errorText || (
                  <span className='span-text'>
                    {'Please select the relevant survey(s) to continue'}
                  </span>
                )}
              </div>
            )}
            <span className='number-validate'>{`(${inputValuelength.length ?? 0}/1000)`}</span>
          </div>
        </div>
        <span>
          <CustomButton disabled={!isTextFieldEmpty} onClickHandler={handleQueryApi}>
            <img src={buttonImg} alt='search button' />
          </CustomButton>
        </span>
      </div>
      <QuestionsPage handleSurveyQuerySelection={handleSurveyQuerySelection} />
    </StyleNewChatComponent>
  );
};

const StyleNewChatComponent = styled.div<{
  isSourceSelected: boolean;
  isSurveyDocument?: boolean;
  whichSourceSelected?: string;
  isRightSideBarOpen?: boolean;
}>`
  padding-bottom: 10%;
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${(props) => (props.whichSourceSelected === 'Surveys' ? '' : '28vh')};
    margin-top: ${(props) => (props.whichSourceSelected === 'Surveys' ? '5%' : '10%')};
  }

  .responsive-image {
    max-width: 100%;
    height: auto;
  }
  .breadcrumbOpen {
    margin-left: 4%;
    margin-top: 2%;
  }
  .breadcrumb {
    margin-left: 4%;
    margin-top: 3vh;
  }
  .search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .input-bar {
    width: 100%;
    height: 44px;
    border-radius: 5px;
    border: 1px solid #c3c3cb;
  }
  .warning {
    color: red;
    font-size: 12px;
    width: 100%;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    width: 95%;
    position: relative;
    top: 11px;
    margin-left: 37px;
  }
  .question-card {
    width: 15%;
    height: 100px;
    background-color: #f9f9fa;
    flex: 0.5;
    margin: 0 10px 0 30px;
    font-size: 14px;
    line-height: 20;
    font-weight: 300;
    justify-content: space-around;
    align-items: center;
    display: flex;
    padding: 1%;
    
  }
  .survey-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: ${(props) => (props.isSurveyDocument ? '18px 26px' : '18px 34px')};
  }
  .survey-button {
    height: 44px;
    display: flex;
    align-self: flex-end;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--dev-only-transparent, rgba(0, 0, 0, 0));
    background: var(--Neutrals-Light-theme-50, #f3f3f5);
    color: black;
    padding: 18px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }
  .card-container {
    display: flex;
    justify-content: space-between;
  }

  .motif-label {
    font-size: 16px;
    font-weight: 600;
    color: #2e2e38;
    // height: 7vh;
    text-align: center;
    position: relative;
    // margin-top: 4%;
    padding-bottom: ${(props) => (props.isRightSideBarOpen ? '7% ' : '4%')};
    padding-top: 1%;
    @media screen and (min-width: 1000px) and (max-width: 1300px) {
      padding-bottom: ${(props) => (props.isRightSideBarOpen ? '8% ' : '4%')};
    }
    @media screen and (min-width: 1700px) and (max-width: 1920px) {
      padding-bottom: ${(props) => (props.isRightSideBarOpen ? '7%' : '5%')};
    }
  }
  .number-validate {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    position: relative;
    top: 0px;
  }
  .warning-length {
    width: 100%;
    display: flex;
    color: #747480;
  }
  .span-text {
    color: #2e2e38;
  }
`;

export default NewChatPage;
