import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import {
  MotifFormField,
  MotifInput,
  MotifCheckbox,
  MotifOption,
  MotifSelect,
  MotifTextArea,
  MotifTypeahead,
  MotifButton,
  MotifErrorMessage,
  MotifLabel
} from '@ey-xd/motif-react';
import {
  AreaConfig,
  CategoryConfig,
  CountryConfig,
  DealConfig,
  RegionConfig,
  SectorConfig,
  SubSectorConfig,
  SubSubSectorConfig,
  ValidPaceIDConfig
} from './projectMasterData';
import { tooltip } from '../../../commonImage';
import Tippy from '@tippy.js/react';
import { getPaceID } from '../../../services/teamsProject.service';
import { LOCALSTORAGE_PROJECT_DATA } from '../../../label.constant';
import OverflowTooltip from '../OverflowTooltip';
import { set } from 'lodash';

type IAddProject = {
  disablecheck: any;
  tabIndex?: number;
};

type createData = {
  category?: string;
  validPaceId?: string;
  paceId?: string;
  engagementId?: string;
  isConfidetnial?: boolean;
  projectName?: string;
  clientName?: string;
  area?: string;
  region?: string;
  country?: string;
  dealSize?: string;
  targetSector?: string;
  subSector?: string;
  subSubSector?: string;
  description?: string;
  editFlag?: string;
};

const AddProject = forwardRef(({ disablecheck, tabIndex }: IAddProject, ref) => {
  let createData: createData = {};

  const [category, setCategory] = useState<any>('');
  const [categoryDisable, setCategoryDisable] = useState(false);
  const [categoryList, setCategoryList] = useState<any>([]);

  const [validPaceId, setValidPaceId] = useState<any>('');
  const [validPaceIdDisable, setValidPaceIdDisable] = useState(false);
  const [validPaceIdList, setValidPaceIdList] = useState<any>([]);

  const [paceID, setPaceID] = useState('');
  const [paceIdList, setPaceIdList] = useState<any>([]);
  const [paceIDDisable, setpaceIDDisable] = useState(false);
  const [paceDropdownList, setPaceDropdownList] = useState([]);
  const [paceIDListShow, setPaceIDListShow] = useState(false);
  const [paceIDValid, setPaceIDValid] = useState(true);

  const [confidentialProject, setConfidentialProject] = useState<any>(false);
  const [confidentialProjectDisable, setConfidentialProjectDisable] = useState(false);

  const [engagementId, setEngagementId] = useState<any>('');
  const [engagementIdDisable, setEngagementIdDisable] = useState(false);

  const [projectName, setProjectName] = useState<any>('');
  const [projectNameDisable, setProjectNameDisable] = useState(false);
  const [showProjectNameValid, setShowProjectNameValid] = useState(false);

  const [clientName, setClientName] = useState<any>('');
  const [clientNameDisable, setClientNameDisable] = useState(false);

  const [area, setArea] = useState('');
  const [areaDisable, setAreaDisable] = useState(false);
  const [areaList, setAreaList] = useState<any>([]);

  const [region, setRegion] = useState('');
  const [regionDisable, setRegionDisable] = useState(false);
  const [regionList, setRegionList] = useState<any>([]);

  const [country, setCountry] = useState('');
  const [countryDisable, setCountryDisable] = useState(false);
  const [countryList, setCountryList] = useState<any>([]);

  const [dealSize, setDealSize] = useState<any>('');
  const [dealSizeDisable, setDealSizeDisable] = useState(false);
  const [dealSizeList, setDealSizeList] = useState<any>([]);

  const [targetSector, setTargetSector] = useState('');
  const [targetSectorDisable, setTargetSectorDisable] = useState(false);
  const [targetSectorList, setTargetSectorList] = useState<any>([]);

  const [targetSubSector, setTargetSubSector] = useState('');
  const [targetSubSectorDisable, setTargetSubSectorDisable] = useState(false);
  const [targetSubSectorList, setTargetSubSectorList] = useState<any>([]);

  const [targetSubSubSector, setTargetSubSubSector] = useState<any>([]);
  const [targetSubSubSectorDisable, setTargetSubSubSectorDisable] = useState(false);
  const [targetSubSubSectorList, setTargetSubSubSectorList] = useState<any>([]);

  const [description, setDescription] = useState<any>('');
  const [descriptionDisable, setDescriptionDisable] = useState(false);

  const [validateError, setValidationError] = useState<boolean>(false);

  // useEffect(() => {
  //   let obj = JSON.parse(localStorage.getItem('addProjectDetails') || '{}');
  //   if(obj?.validPaceId){
  //     setValidPaceId(obj?.validPaceId);
  //   }
  // },[tabIndex]);

  const onCategoryChange = (data: any) => {
    setPaceIDValid(true);
    let categoryListMaster = [
      { id: 1, content: 'Client project' },
      { id: 2, content: 'Internal project' }
    ];
    setCategory(data);
    if (data !== null) {
      let selectedCategory = categoryListMaster.find((category: any) => category.content === data);
      if (selectedCategory) {
        setCategory(data);
      }
    }
    if (data === 'Internal project') {
      setValidPaceIdDisable(true);
      setpaceIDDisable(true);
      setConfidentialProjectDisable(true);
      setEngagementIdDisable(true);
      setClientNameDisable(true);
      setDealSizeDisable(true);

      setDealSize('');
      setValidPaceId('');
      setPaceID('');
      setEngagementId('');
      setClientName('');
      setConfidentialProject(false);
      setPaceIDValid(true);
    } else {
      setValidPaceIdDisable(false);
      setpaceIDDisable(false);
      setEngagementIdDisable(false);
      setConfidentialProjectDisable(false);
      setClientNameDisable(false);
      setDealSizeDisable(false);

      setValidPaceId(1);
      setPaceID('');
      setEngagementId('');
      setClientName('');
      setConfidentialProject(false);
    }
    setIsUIValidPaceIDSelection(true);
    setIsUIValidCategory(data != '');
  };

  const onValidPaceIDChange = (data: any) => {
    let validPaceIDListMaster = [
      { id: 1, content: 'Yes' },
      { id: 2, content: 'No' }
    ];
    const selectedValidPaceIdData: any = validPaceIDListMaster.find(
      (element: any) => element.id === data
    );
    if (selectedValidPaceIdData) {
      setValidPaceId(data);
      setpaceIDDisable(false);
      setIsUIValidPaceIDSelection(true);
    }
    if (data === 2) {
      setPaceID('');
      setClientName('');
      setPaceIDValid(true);
      setpaceIDDisable(true);
      setIsUIValidClientName(true);
    }
    // setIsUIValidPaceID(data!="");
  };

  const onPaceIdChange = async (e: any) => {
    if (e.target.value.length <= 8) {
      setPaceIDValid(true);
      setPaceID('');
      setClientName('');
      const regex = /^[0-9\b]+$/;
      if (regex.test(e.target.value)) {
        setPaceID(e.target.value);
        setPaceIdList([]);
        setPaceIDListShow(false);
        if (e.target.value.length > 2) {
          let responsePace = await getPaceID(e.target.value);
          if (responsePace !== undefined && !responsePace?.status) {
            let paceRequest: any = [];
            if (responsePace.length > 0) {
              responsePace.forEach((key: any, index: number) => {
                paceRequest.push(key.paceId.toString());
              });
            }
            let filteredPaceRequest = paceRequest.filter((o: any) => o.startsWith(e.target.value));
            if(filteredPaceRequest?.length > 0){
              setPaceDropdownList(filteredPaceRequest);
              setPaceIdList([...responsePace]);
              setPaceIDListShow(true);              
            }
            else {
              setPaceIDListShow(false);
              setPaceIDValid(false);
            }
          }
          else {
            setPaceIDListShow(false);
            setPaceIDValid(false);
          }
        } else {
          setPaceIDListShow(false);
          setPaceIDValid(true);
        }
      } else {
        setPaceIDListShow(false);
        setPaceIDValid(true);
      }
    }
  };

  const onPaceIdSelect = (e: any) => {
    if (!confidentialProject) {
      setPaceID(e);
      let paceDetails = paceIdList.find((s: any) => s.paceId === Number(e));
      setClientName(paceDetails?.clientName);
      setPaceIDValid(true);
      setIsUIValidClientName(paceDetails?.clientName?.length > 0);
    } else {
      setPaceID(e);
      setClientName('Confidential');
      setIsUIValidClientName(true);
    }
    setPaceIDListShow(false);
  };

  const onEngagementIdChange = (e: any) => {
    // if (e.target.value.length < 10) {
    //   const regex = /^[0-9\b]+$/;
    //   if (regex.test(e.target.value)) {
    //     setEngagementId(e.target.value);
    //   }
    //   if (e.target.value.length === 0) {
    //   }
    // }
    if (e.target.value.length <= 50)
      setEngagementId(e.target.value);
  };

  const onConfidentialChange = () => {
    if (!confidentialProject) {
      setClientName('Confidential');
      setClientNameDisable(true);
      setIsUIValidClientName(true);
    } else {
      setClientName('');
      setClientNameDisable(false);
    }
    setConfidentialProject(!confidentialProject);
  };

  const onProjectNameChange = (e: any) => {
    if (e.target.value.length <= 100) {
      setProjectName(e.target.value);
      localStorage.setItem('projectName', e.target.value);
      setIsUIValidProjectName(e.target.value != '');
    }
  };

  const onClientNameChange = (e: any) => {
    if (e.target.value.length <= 255) {
      setClientName(e.target.value);
      setIsUIValidClientName(e.target.value != '');
    }
  };

  const onAreaChange = (data: any, isCreate = true, isEdit = false) => {
    setRegion('');
    setCountry('');
    if (isCreate) {
      if (data !== null) {
        let selectedArea = areaList.find((area: any) => area.id === data);
        if (selectedArea) {
          setArea(data);
          setRegionList(RegionConfig.filter((region) => region.areaId === data));
        }
      }
    }
    if (isEdit) {
      setArea(data);
      setRegionList(RegionConfig.filter((region) => region.areaId === data));
    }
    setIsUIValidArea(data != '');
  };

  const onRegionChange = (data: any, isCreate = true, isEdit = false) => {
    setCountry('');
    if (isCreate) {
      if (data !== null) {
        let selectedRegion = regionList.find((region: any) => region.id === data);
        if (selectedRegion) {
          setRegion(data);
          setCountryList(
            CountryConfig.filter((country) => country.regionId === data).sort(function (a, b) {
              const textA = a.content.toUpperCase();
              const textB = b.content.toUpperCase();
              if (textA < textB) {
                return -1;
              } else if (textA > textB) {
                return 1;
              } else {
                return 0;
              }
            })
          );
        }
      }
    }
    if (isEdit) {
      setRegion(data);
      setCountryList(
        CountryConfig.filter((country) => country.regionId === data).sort(function (a, b) {
          const textA = a.content.toUpperCase();
          const textB = b.content.toUpperCase();
          if (textA < textB) {
            return -1;
          } else if (textA > textB) {
            return 1;
          } else {
            return 0;
          }
        })
      );
    }
    setIsUIValidRegion(data != '');
  };

  const onCountryChange = (e: any) => {
    setCountry(e);
    setIsUIValidCountry(e != '');
  };

  const onDealSizeChange = (data: any) => {
    setDealSize(data);
    if (data !== null) {
      let selectedDealSize = dealSizeList.find((dealSize: any) => dealSize.id === data);

      if (selectedDealSize) {
        setDealSize(data);
      }
    }
    setIsUIValidDealSize(data != '');
  };

  const onTargetSectorChange = (data: any, isCreate = true, isEdit = false) => {
    setTargetSubSector('');
    setTargetSubSubSector([]);
    if (isCreate) {
      if (data !== null) {
        let selectedTarget = targetSectorList.find((target: any) => target.id === data);
        if (selectedTarget) {
          setTargetSector(data);
          setTargetSubSectorList(
            SubSectorConfig.filter((subsector: any) => subsector.sectorId === data)
          );
        }
      }
    }
    if (isEdit) {
      setTargetSector(data);
      setTargetSubSectorList(SubSectorConfig.filter((subsector) => subsector.sectorId === data));
    }
    setIsUIValidTargetSector(data != '');
  };

  const onSubSectorChange = (data: any, isCreate = true, isEdit = false) => {
    setTargetSubSubSector([]);
    if (isCreate) {
      if (data !== null) {
        let selectedsubSector = targetSubSectorList.find((target: any) => target.id === data);
        if (selectedsubSector) {
          setTargetSubSector(data);
          setTargetSubSubSectorList(
            SubSubSectorConfig.filter((subsector: any) => subsector.sectorId === data)
          );
        }
      }
    }
    if (isEdit) {
      setTargetSubSector(data);
      setTargetSubSubSectorList(
        SubSubSectorConfig.filter((subsector) => subsector.sectorId === data)
      );
    }
    setIsUIValidTargetSubSector(data != '');
  };

  const onSubSubSectorChange = (data: any) => {
    setTargetSubSubSector(data);
    setIsUIValidTargetSubSubSector(data != '');
  };

  const onDescriptionChange = (e: any) => {
    setDescription(e.target.value);
    setIsUIValidDescription(e.target.value != '');
  };

  const [isUIValidCategory, setIsUIValidCategory] = useState<boolean>(true);
  const [isUIValidPaceIDSelection, setIsUIValidPaceIDSelection] = useState<boolean>(true);
  const [isUIValidEngagementId, setIsUIValidEngagementId] = useState<boolean>(true);
  const [isUIValidProjectName, setIsUIValidProjectName] = useState<boolean>(true);
  const [isUIValidClientName, setIsUIValidClientName] = useState<boolean>(true);
  const [isUIValidArea, setIsUIValidArea] = useState<boolean>(true);
  const [isUIValidRegion, setIsUIValidRegion] = useState<boolean>(true);
  const [isUIValidCountry, setIsUIValidCountry] = useState<boolean>(true);
  const [isUIValidDealSize, setIsUIValidDealSize] = useState<boolean>(true);
  const [isUIValidTargetSector, setIsUIValidTargetSector] = useState<boolean>(true);
  const [isUIValidTargetSubSector, setIsUIValidTargetSubSector] = useState<boolean>(true);
  const [isUIValidTargetSubSubSector, setIsUIValidTargetSubSubSector] = useState<boolean>(true);
  const [isUIValidDescription, setIsUIValidDescription] = useState<boolean>(true);

  const resetAddProjectUIValidations = () => {
    setIsUIValidCategory(category != '');
    category == 'Internal project'
      ? setIsUIValidPaceIDSelection(true)
      : setIsUIValidPaceIDSelection(validPaceId != '');
    setIsUIValidEngagementId(engagementId != '');
    setIsUIValidProjectName(projectName != '');
    setIsUIValidArea(area != '');
    setIsUIValidRegion(region != '');
    setIsUIValidCountry(country != '');
    setIsUIValidDealSize(dealSize != '');
    setIsUIValidTargetSector(targetSector != '');
    setIsUIValidTargetSubSector(targetSubSector != '');
    setIsUIValidTargetSubSubSector(targetSubSubSector != '');
    setIsUIValidDescription(description != '');
  };

  const isValidFields = (isNextButtonClick = false) => {
    let validate = true;
    if (isNextButtonClick) resetAddProjectUIValidations();
    if (category == '') {
      validate = false;
      if (isNextButtonClick) setIsUIValidCategory(false);
    }
    if (category == 'Client project') {
      if (paceID == '' && validPaceId == 1) {
        if (isNextButtonClick) {
          setPaceIDValid(false);
        }
        validate = false;
      }
      if(validPaceId == 1 && paceID != '') {
        validate = paceIDValid;
      }
      if (validPaceId == 2 && paceID != '') setPaceIDValid(true);
    }
    if (projectName == '') {
      validate = false;
      if (isNextButtonClick) setIsUIValidProjectName(false);
    }
    if (category == 'Client project') {
      if (validPaceId == 1 && clientName === '') {
        validate = false;
        if (isNextButtonClick) setIsUIValidClientName(false);
      }
      else {
        setIsUIValidClientName(true);
      }  
    }
      
    if (area == '') {
      validate = false;
      if (isNextButtonClick) setIsUIValidArea(false);
    }
    if (region == '') {
      validate = false;
      if (isNextButtonClick) setIsUIValidRegion(false);
    }
    if (country == '') {
      validate = false;
      if (isNextButtonClick) setIsUIValidCountry(false);
    }
    if (category == 'Client project') {
      if (dealSize == '') {
        validate = false;
        if (isNextButtonClick) setIsUIValidDealSize(false);
      }
    }
    if (targetSector == '') {
      validate = false;
      if (isNextButtonClick) setIsUIValidTargetSector(false);
    }
    if (targetSubSector == '') {
      validate = false;
      if (isNextButtonClick) setIsUIValidTargetSubSector(false);
    }
    if (targetSubSubSector == '') {
      validate = false;
      if (isNextButtonClick) setIsUIValidTargetSubSubSector(false);
    }
    if (description == '') {
      validate = false;
      if (isNextButtonClick) setIsUIValidDescription(false);
    }
    if(category == 'Internal project')
    {
      setIsUIValidClientName(true);
      setIsUIValidDealSize(true);
    }
    return validate;
  };

  const validateCreatePage = () => {
    if (isValidFields()) {
      fillProjectDetails();
      disablecheck(false, createData);
    } else {
      disablecheck(true, createData);
    }
  };

  useImperativeHandle(ref, () => ({
    validateData: () => {
      if (isValidFields(true)) return true;
      else return false;
    },
    resetUIValidations: () => {
      resetAddProjectUIValidations();
      return;
    }
  }));

  const fillProjectDetails = () => {
    createData.category = category;
    createData.paceId = paceID;
    createData.validPaceId = validPaceId;
    createData.engagementId = engagementId;
    createData.isConfidetnial = confidentialProject;
    createData.projectName = projectName;
    createData.clientName = clientName;
    createData.area = area;
    createData.region = region;
    createData.country = country;
    createData.dealSize = dealSize === '' ? 0 : dealSize;
    createData.targetSector = targetSector;
    createData.subSector = targetSubSector;
    createData.subSubSector = targetSubSubSector;
    createData.description = description;
    localStorage.setItem(LOCALSTORAGE_PROJECT_DATA, JSON.stringify(createData));
  };

  useEffect(() => {
    setCategoryList(CategoryConfig);
    setValidPaceIdList(ValidPaceIDConfig);
    setAreaList(AreaConfig);
    setDealSizeList(DealConfig);
    setTargetSectorList(SectorConfig);
  }, []);

  useEffect(() => {
    let savedData = localStorage.getItem(LOCALSTORAGE_PROJECT_DATA);
    if (savedData) {
      let projectData: createData = JSON.parse(savedData);
      onCategoryChange(projectData.category);
      onValidPaceIDChange(projectData.validPaceId);
      onPaceIdSelect(projectData.paceId);
      setEngagementId(projectData.engagementId);
      setConfidentialProject(projectData.isConfidetnial);
      projectData.isConfidetnial ? setClientNameDisable(true) : setClientNameDisable(false);
      setProjectName(projectData.projectName);
      setClientName(projectData.clientName);
      if (projectData?.clientName && projectData.clientName.length > 0)
        setIsUIValidClientName(true);
      onAreaChange(projectData.area, false, true);
      onRegionChange(projectData.region, false, true);
      onCountryChange(projectData.country);
      onDealSizeChange(projectData.dealSize);
      onTargetSectorChange(projectData.targetSector, false, true);
      onSubSectorChange(projectData.subSector, false, true);
      onSubSubSectorChange(projectData.subSubSector);
      setDescription(projectData.description);
    }
  }, []);

  useEffect(() => {
    validateCreatePage();
  }, [
    category,
    validPaceId,
    paceID,
    confidentialProject,
    engagementId,
    projectName,
    clientName,
    area,
    region,
    country,
    dealSize,
    targetSector,
    targetSubSector,
    targetSubSubSector,
    description
  ]);

  const downloadSectorMapping = async () => {
    const response: any = await fetch('/assets/files/Sector_Mapping.csv');
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const reader = response.body.getReader();
    const result = await reader.read(); // raw array
    const decoder = new TextDecoder('utf-8');
    const csvText = decoder.decode(result.value);
    const blob = new Blob([csvText], { type: 'text/csv' });
    const link = document.createElement('a');
    link.download = 'Sector_Mapping.csv';
    link.href = window.URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <StyledAddProjectDetails>
      <div className='frame-1 flex-container'>
        <div className='flex-box'>
          <span className='label'>
            Category<span className='required'>*</span>
          </span>
          <MotifFormField className={isUIValidCategory ? 'valid-field' : 'invalid-field'}>
            {!category ? <MotifLabel className='fieldLable'> {'Select category'}</MotifLabel> : ''}
            <MotifSelect
              className='Input-motif'
              disabled={categoryDisable}
              ariaLabelledBy='select-label'
              onChange={onCategoryChange}
              value={category}
              required
              visibleOptions={3}>
              {categoryList.map((item: any) => {
                return (
                  <MotifOption className={item.content} value={item.content} key={item.id}>
                    {item.content}
                  </MotifOption>
                );
              })}
            </MotifSelect>
          </MotifFormField>
        </div>

        <div className='flex-box'>
          <span className='label'>
            Do you have a valid PACE ID
            {category === 'Client project' && <span className='required'>*</span>}
          </span>
          <div className='select-dropdown'>
            <MotifFormField className={isUIValidPaceIDSelection ? 'valid-field' : 'invalid-field'}>
              {!validPaceId ? (
                <MotifLabel className='fieldLable'> {'Select option'}</MotifLabel>
              ) : (
                ''
              )}
              <MotifSelect
                className='Input-motif'
                disabled={validPaceIdDisable}
                ariaLabelledBy='select-label'
                onChange={onValidPaceIDChange}
                value={validPaceId}
                required
                visibleOptions={3}>
                {validPaceIdList.map((element: any, index: any) => (
                  <MotifOption className={element.content} key={element.id} value={element.id}>
                    {element.content}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>
        </div>

        <div className='flex-box'>
          <span className='label'>
            PACE ID
            {category === 'Client project' && validPaceId === 1 ? (
              <span className='required'>*</span>
            ) : (
              <></>
            )}
          </span>
          <MotifFormField>
            {!paceID ? <MotifLabel className='fieldLable'> {'Enter PACE ID'}</MotifLabel> : ''}
            <MotifTypeahead
              label='inputpaceid'
              className='motif-input PaceIdInput'
              disabled={paceIDDisable}
              filterProp='paceId'
              id='users-typeahead'
              items={paceDropdownList}
              onChange={onPaceIdChange}
              onSelect={onPaceIdSelect}
              onClear={() => {
                setPaceIDListShow(false);
                setPaceIDValid(true);
              }}
              onClick={() => {}}
              onBlur={() => setPaceIDListShow(false)}
              //onFocus={() => setPaceIDValid(false)}
              open={paceIDListShow}
              value={paceID}
              visibleOptions={5}
              autocomplete='off'
            />
          </MotifFormField>
          {paceIDValid.toString().length > 0 && !paceIDValid && validPaceId != 2 && (
            <MotifErrorMessage id='users-typeahead'>
              <span className='validation-error-paceid'>Please select a valid PACE ID </span>
            </MotifErrorMessage>
          )}
        </div>
      </div>

      <div className='frame-2 flex-container'>
        <div className='flex-box empty-box'>{/* Left space is empty */}</div>

        <div className='flex-box'>
          <div className='checkbox-container'>
            <MotifCheckbox
              id='checkbox-confidential'
              name='checkbox-confidential'
              checked={confidentialProject}
              onChange={onConfidentialChange}
              value={'checkbox-confidential'}
              disabled={confidentialProjectDisable}>
              <span className='confidential-label'>Confidential project</span>
            </MotifCheckbox>
            <div className='confidential-project-tooltip'>
              <OverflowTooltip
                tooltipContent={'Selecting confidential will remove the client name'}
                placement='bottom'
                trigger='mouseenter'
                tooltipClassName='project-tooltip-placement'>
                <img className='tooltip-image' src={tooltip} alt='tooltip'></img>
              </OverflowTooltip>
            </div>
          </div>
        </div>

        <div className='flex-box'>
          <span className='label'>Engagement ID</span>
          <MotifFormField>
            {!engagementId ? (
              <MotifLabel className='fieldLable'> {'Enter Engagement ID'}</MotifLabel>
            ) : (
              ''
            )}

            <MotifInput
              label='inputengagementid'
              disabled={engagementIdDisable}
              onChange={onEngagementIdChange}
              className='PaceIdInput'
              type='text'
              required
              value={engagementId}
              autocomplete='off'
            />
          </MotifFormField>
        </div>
      </div>

      <div className='frame-3 flex-container'>
        <div className='flex-box'>
          <span className='label'>
            Project name<span className='required'>*</span>
          </span>
          <MotifFormField className={isUIValidProjectName ? 'valid-field' : 'invalid-field-textbox'}>
            {!projectName ? (
              <MotifLabel className='fieldLable'> {'Enter project name'}</MotifLabel>
            ) : (
              ''
            )}
            <MotifInput
              label='inputprojectname'
              disabled={projectNameDisable}
              onChange={onProjectNameChange}
              className='Input'
              value={projectName}
              maxLength={100}
              autocomplete='off'
            />
          </MotifFormField>
        </div>

        <div className='flex-box'>
          <span className='label'>
            Client name
            {category === 'Client project' && validPaceId === 'Yes' ? (
              <span className='required'>*</span>
            ) : (
              <></>
            )}
          </span>
          <MotifFormField className={isUIValidClientName ? 'valid-field' : 'invalid-field-textbox'}>
            {!clientName ? (
              <MotifLabel className='fieldLable'> {'Enter client name'}</MotifLabel>
            ) : (
              ''
            )}
            <MotifInput
              label='inputclientname'
              disabled={clientNameDisable}
              onChange={onClientNameChange}
              className='InputCLient'
              value={clientName}
              autocomplete='off'
            />
          </MotifFormField>
        </div>
      </div>

      <div className='frame-4 flex-container'>
        <div className='flex-box'>
          <span className='label'>
            Area<span className='required'>*</span>
          </span>
          <MotifFormField className={isUIValidArea ? 'valid-field' : 'invalid-field'}>
            {!area ? <MotifLabel className='fieldLable'> {'Select area'}</MotifLabel> : ''}
            <MotifSelect
              disabled={areaDisable}
              className='Input-motif'
              ariaLabelledBy='select-label'
              onChange={onAreaChange}
              value={area}
              required
              visibleOptions={3}>
              {areaList.map((item: any) => {
                return (
                  <MotifOption className={item.content} value={item.id} key={item.id}>
                    {item.content}
                  </MotifOption>
                );
              })}
            </MotifSelect>
          </MotifFormField>
        </div>

        <div className='flex-box'>
          <span className='label'>
            Region<span className='required'>*</span>
          </span>
          <MotifFormField className={isUIValidRegion ? 'valid-field' : 'invalid-field'}>
            {!region ? <MotifLabel className='fieldLable'> {'Select region'}</MotifLabel> : ''}
            <MotifSelect
              disabled={regionDisable}
              className='Input-motif'
              ariaLabelledBy='select-label'
              onChange={onRegionChange}
              value={region}
              required
              visibleOptions={3}>
              {regionList.map((item: any) => {
                return (
                  <MotifOption className={item.content} value={item.id} key={item.id}>
                    {item.content}
                  </MotifOption>
                );
              })}
            </MotifSelect>
          </MotifFormField>
        </div>

        <div className='flex-box'>
          <span className='label'>
            Country<span className='required'>*</span>
          </span>
          <MotifFormField className={isUIValidCountry ? 'valid-field' : 'invalid-field'}>
            {!country ? <MotifLabel className='fieldLable'> {'Select country'}</MotifLabel> : ''}
            <MotifSelect
              disabled={countryDisable}
              className='Input-motif'
              ariaLabelledBy='select-label'
              onChange={onCountryChange}
              value={country}
              required
              visibleOptions={3}>
              {countryList.map((item: any) => {
                return (
                  <MotifOption className={item.content} value={item.id} key={item.id}>
                    {item.content}
                  </MotifOption>
                );
              })}
            </MotifSelect>
          </MotifFormField>
        </div>
      </div>

      <div className='frame-5 flex-container'>
        <div className='flex-box'>
          <span className='label'>
            Engagement TER (USD)
            {category === 'Client project' && <span className='required'>*</span>}
          </span>
          <MotifFormField className={isUIValidDealSize ? 'valid-field' : 'invalid-field'}>
            {!dealSize ? (
              <MotifLabel className='fieldLable'> {'Select Engagement TER (USD)'}</MotifLabel>
            ) : (
              ''
            )}
            <MotifSelect
              className='Input-motif'
              ariaLabelledBy='select-label'
              onChange={onDealSizeChange}
              disabled={dealSizeDisable}
              value={dealSize}
              required
              visibleOptions={3}>
              {dealSizeList.map((item: any) => {
                return (
                  <MotifOption className={item.content} value={item.id} key={item.id}>
                    {item.content}
                  </MotifOption>
                );
              })}
            </MotifSelect>
          </MotifFormField>
        </div>

        <div className='flex-box'>
          <span className='label'>
            Target sector<span className='required'>*</span>
            <a onClick={downloadSectorMapping} className='full-list-link'>
              {' '}
              (full list)
            </a>
          </span>
          <MotifFormField className={isUIValidTargetSector ? 'valid-field' : 'invalid-field'}>
            {!targetSector ? (
              <MotifLabel className='fieldLable'> {'Select sector'}</MotifLabel>
            ) : (
              ''
            )}
            <MotifSelect
              className='Input-motif'
              ariaLabelledBy='select-label'
              onChange={onTargetSectorChange}
              disabled={targetSectorDisable}
              value={targetSector}
              required
              visibleOptions={3}>
              {targetSectorList.map((item: any) => {
                return (
                  <MotifOption className={item.content} value={item.id} key={item.id}>
                    {item.content}
                  </MotifOption>
                );
              })}
            </MotifSelect>
          </MotifFormField>
        </div>

        <div className='flex-box'>
          <span className='label'>
            Target sub-sector<span className='required'>*</span>
          </span>
          <MotifFormField className={isUIValidTargetSubSector ? 'valid-field' : 'invalid-field'}>
            {!targetSubSector ? (
              <MotifLabel className='fieldLable'> {'Select sub-sector'}</MotifLabel>
            ) : (
              ''
            )}
            <MotifSelect
              className='Input-motif'
              ariaLabelledBy='select-label'
              onChange={onSubSectorChange}
              disabled={targetSubSectorDisable}
              value={targetSubSector}
              required
              visibleOptions={3}>
              {targetSubSectorList.map((item: any) => {
                return (
                  <MotifOption className={item.content} value={item.id} key={item.id}>
                    {item.content}
                  </MotifOption>
                );
              })}
            </MotifSelect>
          </MotifFormField>
        </div>
      </div>

      <div className='frame-6'>
        <div className='InputWithLabel'>
          <div className='label'>
            Target sub-sub-sector<span className='required'>*</span>
          </div>
          <MotifFormField className={isUIValidTargetSubSubSector ? 'valid-field' : 'invalid-field'}>
            {!targetSubSubSector || targetSubSubSector.length === 0 ? (
              <MotifLabel className='fieldLable'> {'Select sub-sub-sector'}</MotifLabel>
            ) : (
              ''
            )}
            <MotifSelect
              className='Input-motif'
              ariaLabelledBy='select-label'
              onChange={onSubSubSectorChange}
              disabled={targetSubSubSectorDisable}
              value={targetSubSubSector}
              multiple={true}
              required
              visibleOptions={3}>
              {targetSubSubSectorList.map((item: any) => {
                return (
                  <MotifOption className={item.content} value={item.id} key={item.id}>
                    {item.content}
                  </MotifOption>
                );
              })}
            </MotifSelect>
          </MotifFormField>
        </div>
      </div>

      <div className='frame-7'>
        <div className='label'>
          Project description<span className='required'>*</span>
        </div>
        <MotifFormField className={isUIValidDescription ? 'valid-field' : 'invalid-field'}>
          {!description ? (
            <MotifLabel className='fieldLable'> {'Enter a description'}</MotifLabel>
          ) : (
            ''
          )}
          <MotifTextArea
            label='inputdescription'
            onChange={onDescriptionChange}
            disabled={descriptionDisable}
            className='InputDescription'
            value={description}
            maxLength={500}
            autocomplete='off'
          />
        </MotifFormField>
      </div>
    </StyledAddProjectDetails>
  );
});

const StyledAddProjectDetails = styled.div`
  .flex-container {
    display: flex;
    justify-content: space-between;
  }

  .flex-box {
    flex: 1;
    margin: 0 5px;
    padding: 0px 0px 10px 0px;
    display: flex;
    flex-direction: column;
  }

  .label {
    text-align: left;
    margin-bottom: 5px;
  }

  .required {
    color: red;
  }

  .empty-box {
    background-color: transparent;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    top: -7px;
    position: relative;
  }

  .confidential-label {
    position: relative;
    top: 2px;
  }

  .tooltip-image {
    position: relative;
    cursor: pointer;
  }

  .frame-6,
  .frame-7,
  .frame-8 {
    margin: 0 5px;
  }

  .frame-8 {
    margin-top: 10px;
  }

  .validation-error-paceid {
    color: red;
  }

  .full-list-link {
    color: #0f69ae;
    cursor: pointer;
  }

  .confidential-project-tooltip {
    margin-top: 5%;
  }

  .invalid-field, .invalid-field .motif-select {
    border: 1px solid red;
    border-radius: 5px;
  }
  .invalid-field-textbox {
    border: 1px solid red;
    border-radius: 2px;
  }
  .motif-checkbox-label {
    text-decoration:none !important;
  }
`;
export default AddProject;
