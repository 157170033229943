import React from 'react';
import styled from 'styled-components';
import varScss from '../../styles/variables.module.scss';
const { yellow2, gray, white2 } = varScss;

interface IOwnProps {
  text: string;
  name: string;
  checked: boolean;
  onChange: (e: React.SyntheticEvent<HTMLInputElement>, type: string) => void;
  checkboxSelcted?: any;
  filterType?: any;
  colorAboveText?: any;
  view?: any;
  headerIconWithTip?: React.ReactNode;
  isToolTipRequired?: boolean;
}

export const Checkbox: React.FC<IOwnProps> = ({
  text,
  name,
  checked,
  onChange,
  checkboxSelcted,
  filterType,
  colorAboveText,
  view,
  headerIconWithTip,
  isToolTipRequired
}: IOwnProps): JSX.Element => {
  const limit = 2;
  return (
    <StyledCheckbox
      marginBottom={colorAboveText ? '5px' : '12px'}
      colorAboveText={colorAboveText}
      view={view}>
      {colorAboveText && <span className='color-palette'></span>}{' '}
      {isToolTipRequired ? (
        <span className='toolTipIconQF'>
          {text}
          {headerIconWithTip}
        </span>
      ) : (
        <span className='footer-checkbox-text'>{text}</span>
      )}
      <input
        name={name}
        data-testid='checkbox'
        type='checkbox'
        checked={checked}
        // disabled={!checked && checkboxSelcted && checkboxSelcted.length >= limit}
        onChange={(e) => {
          onChange(e, filterType);
        }}
      />
      <span
        className={`checkmark ${
          checked ? 'checkmark_checked' : ''
        } checkmark bg-header-gray`}></span>
    </StyledCheckbox>
  );
};

const StyledCheckbox = styled.label<{ colorAboveText: string; marginBottom: string; view: string }>`
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: ${(props): string => (props.marginBottom ? props.marginBottom : '12px')};
  cursor: pointer;
  font-size: 14px;
  user-select: none;

  &:hover input ~ .checkmark {
    background-color: ${white2};
  }

  .color-palette {
    background-color: ${(props): string => (props.colorAboveText ? props.colorAboveText : 'unset')};
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 10px;
    margin-right: 4px;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    border: 1px solid ${gray};
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid #31302d;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .checkmark_checked {
    background-color: ${yellow2};
    border: none;
  }

  .checkmark_checked:after {
    display: block;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input[type='checkbox']:disabled ~ .checkmark {
    border-color: #62626b;
    color: #62626b;
    background-color: #62626b;
    cursor: not-allowed;
    pointer-events: all !important;
  }
  .toolTipIconQF {
    position: relative;
    top: -11px;
  }
  .footer-checkbox-text {
    color:#2E2E38;
  }
`;

export default Checkbox;
