import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { IQuery, RESPONSE_BY } from '../../models/IQueryType';
import SendMessage from '../openAIChat/SendMessage';
import ChatReply from '../openAIChat/ChatReply';
import InputBox from '../openAIChat/InputBox';
import LABELS from '../../ui-constants/en.json';
import { useDispatch, useSelector } from 'react-redux';
import { HttpStatusCode } from '../../ui-constants/base/enum';
import { IStore } from '../../store';
import {
  setChatHistory,
  setChatSummary,
  setChunkLoading,
  setWhoelChatTable,
  toggleLoader,
  clearDownloadFileMessage,
  setDownloadFileMessageWord,
  setRelevantFiles,
  setChatTable,
  setChatErrors,
  regenerateChatSummary,
  saveUserChatThreadHistory,
  setLastDeletedThreadID
} from '../../store/actions/userChatActions';
import LinkButton from '../LinkButton/LinkButton';
import { downloadImg } from '../../commonImage';
import exportToWord from '../openAIChat/utils/exportToWord';
import './MarketReports.scss';
import {
  getMRQueryResponse,
  saveDownloadChatActivity,
  saveUserChatHistory
} from '../../services/chatQuery.service';
import {
  checkOnySpecialCharacter,
  getComputedRequestObject,
  handleSessionID,
  handleUserChatThreadHistory,
  setThreadsUnSelected
} from '../../utils/commonUtils';
import { initializeSession } from '../../services/session.service';
import errorStatus from '../../utils/ErrorStatus';
import { MotifToast } from '@ey-xd/motif-react';
import { setRightSideBarOpenClose } from '../../store/actions/commonActions';

type ISectorChatProps = {
  chatQueryApi?: string;
  sectors: string[];
  subSector?: string[];
  geographies: string[];
  reports?: string[];
  contentage: string;
  selectedSourceRadio?: string;
  selectedReport?: string[];
  handleFocus?: () => void;
};

const MarketReports = ({
  chatQueryApi = '',
  sectors,
  subSector,
  geographies,
  contentage,
  selectedSourceRadio,
  selectedReport,
  handleFocus
}: ISectorChatProps) => {
  const [seQuery, setSeQuery] = useState<IQuery[]>([]);
  const [queryText, setQueryText] = useState<string>('');
  const [isValidQuery, setIsValidQuery] = useState<boolean>(false);
  const refInput = useRef<HTMLTextAreaElement>(null);
  const refToScroll = useRef<HTMLDivElement>(null);
  const { isClearLoading, chatDataSummmary, isLoading, isSideBarOpen, chunkLoading } = useSelector(
    (state: any) => state.userChat
  );
  const userData = useSelector((state: IStore) => state.userProfile?.userData);
  const { isRightSideBarOpen, anySourceSelected, isMRChipActive } = useSelector(
    (state: IStore) => state.common
  );

  const [showDownloadInitiated, setsShowDownloadInitiated] = useState(false);

  const dispatch = useDispatch();
  const fileRegex = new RegExp('relevant_files:', 'i');

  const timeoutError = [
    {
      [RESPONSE_BY.STRATEGY_EDGE]: {
        summary: LABELS.chat_reply.SE_TOKEN_ERROR_MSG,
        content: [],
        responseType: 'error',
        sources: ['Market Reports']
      }
    }
  ];

  const replyServerError = [
    {
      [RESPONSE_BY.STRATEGY_EDGE]: {
        summary: LABELS.chat_reply.OPENAI_ERROR_MSG,
        content: [],
        responseType: 'error',
        sources: ['Market Reports']
      }
    }
  ];
  const replyNotFoundError = [
    {
      [RESPONSE_BY.STRATEGY_EDGE]: {
        summary: `\n\n\n${LABELS.chat_reply.MR_NOT_FOUND_ERROR_MSG}`,
        content: [],
        responseType: 'error',
        sources: ['Market Reports']
      }
    }
  ];

  const userChatThreads = useSelector((store: IStore) => store.userChat.chatThreadHistory);
  const selectedChatThread = useSelector((store: IStore) => store.userChat.selectedChatThread);
  const checkedCheckboxes = useSelector((state: any) => state.common?.checkedCheckboxes);
  const eyIPInterRadio = useSelector((state: IStore) => state?.common?.eyIPInterRadio);
  const validURLinputValue = useSelector((state: IStore) => state?.common?.validURLinputValue);
  const lastDeletedThreadID = useSelector((state: IStore) => state?.userChat?.lastDeletedThreadID);
  const [chatLoadCompleted, setChatLoadCompleted] = useState(false);
  const [regeneratedMessageId, setRegeneratedMessageId] = useState(0);
  const [firstResponseTime, setFirstResponseTime] = useState(0);
  const [totalResponseTime, setTotalResponseTime] = useState<number | null>();
  const [controller, setController] = useState<any>(null);
  const [isCompletelyAbortedResposne, setIsCompletelyAbortedResposne] = useState<boolean>(false);
  const [isParitallyAbortedResponse, setIsParitallyAbortedResponse] = useState<boolean>(false);
  const [currentUserChatThreads, setCurrentUserChatThreads] = useState(userChatThreads);

  useEffect(() => {
    setSeQuery(() => chatDataSummmary?.table);
  }, [chatDataSummmary, chunkLoading]);

  const updateFirstResponseTime = (firstByteTime: any, startTime: number) => {
    if (firstByteTime === null) {
      firstByteTime = performance.now();
      setFirstResponseTime((firstByteTime - startTime) / 1000);
    }
  };

  const updateTotalResponseTime = (startTime: number, isStaticResponse: boolean = false) => {
    if (isStaticResponse) {
      setTotalResponseTime(null);
      return;
    } else {
      const endTime = performance.now(); // Record the end time
      const duration = (endTime - startTime) / 1000; // Calculate the duration
      setTotalResponseTime(duration); // Update state with the response time
    }
  };

  const sendChatQuery = async (summary: string) => {
    setIsCompletelyAbortedResposne(false);
    setIsParitallyAbortedResponse(false);
    scrollToBottom();
    let chunks = '';
    let restSummaryPart = '';
    const controller = new AbortController();
    setController(controller);
    let check = false;
    const startTime = performance.now();
    let firstByteTime: any = null;
    try {
      const summaryData = JSON.stringify({
        query: summary.trim(),
        mr_files: [],
        sectors: sectors,
        subsectors: subSector,
        geographies: geographies,
        reporttype: selectedReport,
        content_ages: contentage,
        source: selectedSourceRadio
      });

      const response: any = await getMRQueryResponse(summaryData, userData, controller);
      const { status, statusText } = response;
      if (controller.signal.aborted) {
        const stopGenerationErrorMsg = [
          {
            [RESPONSE_BY.STRATEGY_EDGE]: {
              summary: LABELS.chat_reply.STOP_GENERATION_MSG,
              content: [],
              responseType: 'stop_generation',
              sources: [],
              isCompletelyAbortedResposne: true,
              isParitallyAbortedResponse: false
            }
          }
        ];
        dispatch(setChatErrors([...stopGenerationErrorMsg]));
        dispatch(toggleLoader(false));
        scrollToBottom();
        setChatLoadCompleted(true);
      } else if (
        status === HttpStatusCode.InternalServerError ||
        status === HttpStatusCode.TimedOut ||
        status === HttpStatusCode.BadRequest ||
        statusText === LABELS.SERVER_ERROR
      ) {
        updateFirstResponseTime(firstByteTime, startTime);
        dispatch(setChatErrors([...replyServerError]));
        updateTotalResponseTime(startTime, true);
        dispatch(toggleLoader(false));
        setTimeout(() => {
          return scrollToBottom();
        }, 100);
        setChatLoadCompleted(true);
      } else if (status === HttpStatusCode.NotFound) {
        updateFirstResponseTime(firstByteTime, startTime);
        dispatch(setChatErrors([...replyNotFoundError]));
        updateTotalResponseTime(startTime, true);
        dispatch(toggleLoader(false));
        setTimeout(() => {
          return scrollToBottom();
        }, 100);
        setChatLoadCompleted(true);
      } else {
        updateFirstResponseTime(firstByteTime, startTime);
        const reader = response.body.getReader();
        reader
          .read()
          .then(function pump({ done, value }: any) {
            const decodedChunks = new TextDecoder().decode(value).replaceAll('\n\n', '\n');
            chunks += decodedChunks;
            dispatch(setChunkLoading(true));
            if (!check) {
              dispatch(
                setChatTable([
                  {
                    SEResponse: {
                      Content: [],
                      Sources: ['Market Reports'],
                      downloadURL: ''
                    }
                  }
                ])
              );
              if (chunks) dispatch(setChatSummary(decodedChunks));
              check = true;
            } else {
              if (chunks && !chunks.includes('relevant_files:')) {
                dispatch(setChatSummary(decodedChunks));
                scrollToBottom();
              } else {
                restSummaryPart += decodedChunks;
              }
            }
            if (done) {
              const restChunks = restSummaryPart.split('relevant_files:');
              if (restChunks.length > 1) {
                dispatch(setChatSummary(restChunks[0]));
              }
              updateTotalResponseTime(startTime);
              setChatLoadCompleted(true);
              dispatch(setChunkLoading(false));
              dispatch(setWhoelChatTable(true));
              dispatch(toggleLoader(false));
              scrollToBottom();
              setRelevantTableData(chunks);
              chunks = '';
              return;
            }
            return reader.read().then(pump);
          })
          .catch((err: any) => {
            dispatch(setChunkLoading(false));
            setChatLoadCompleted(true);
            scrollToBottom();
            dispatch(toggleLoader(false));
            updateTotalResponseTime(startTime, true);
          });
      }
    } catch (error) {
      setChatLoadCompleted(true);
      dispatch(setChunkLoading(false));
      if (controller.signal.aborted) {
        dispatch(setChatErrors([...timeoutError]));
        dispatch(toggleLoader(false));
        scrollToBottom();
        updateTotalResponseTime(startTime, true);
      } else {
        dispatch(setChatErrors([...replyServerError]));
        dispatch(toggleLoader(false));
        setChatLoadCompleted(true);
        scrollToBottom();
        updateTotalResponseTime(startTime, true);
      }
    }
  };

  const setRelevantTableData = (str: string) => {
    try {
      const splitwiseFiles = str.split(fileRegex);
      if (splitwiseFiles.length > 1) {
        const filesstring = splitwiseFiles[1].replaceAll('```', '');
        const result = JSON.parse(filesstring.replace(/\n/g, '').replace(/\t/g, ''));
        if (result.length) {
          const updatedRes = result.map((res: any) => {
            let pagelist = res.page_number.reduce((newPages: any, page: any) => {
              if (newPages.includes(page)) {
                return newPages;
              }
              return [...newPages, page];
            }, []);
            return { ...res, page_number: pagelist };
          });
          dispatch(setRelevantFiles(updatedRes));
        }
      }
    } catch (er: any) {
      return errorStatus(er?.response?.status);
    }
  };

  const handleSendMessage = (
    ev: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLTextAreaElement>,
    query?: string
  ) => {
    if (!isClearLoading && !isLoading) {
      if ((queryText && queryText.trim().length) || query) {
        handleSessionID(selectedChatThread);
        setRegeneratedMessageId(0);
        let newMsg = {
          [RESPONSE_BY.USER]: {
            summary: queryText || query
          }
        };
        dispatch(setChatHistory([newMsg]));
        refToScroll?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });
        sendChatQuery((queryText || query || '').trim());
        setQueryText('');
        dispatch(toggleLoader(true));
        if (Object.keys(selectedChatThread).length === 0 && !localStorage.isSidebarManuallyClosed) {
          dispatch(setRightSideBarOpenClose(true));
        }
      }
    }
  };

  const handleRegenerate = (
    ev: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLTextAreaElement>,
    query?: string
  ) => {
    if (!isClearLoading && !isLoading && anySourceSelected) {
      if ((queryText && queryText.trim().length) || query) {
        handleSessionID(selectedChatThread);
        let newMsg = {
          [RESPONSE_BY.USER]: {
            summary: queryText || query
          }
        };
        setRegeneratedMessageId(
          chatDataSummmary?.table?.[chatDataSummmary?.table?.length - 1]?.SEResponse?.messageId ||
            regeneratedMessageId
        );
        dispatch(regenerateChatSummary([newMsg]));
        dispatch(setChatHistory([newMsg]));
        sendChatQuery((queryText || query || '').trim());
        setQueryText('');
        dispatch(toggleLoader(true));
      }
    }
  };

  const scrollToBottom = () => {
    refToScroll?.current?.scroll({
      left: 0,
      top: refToScroll.current.scrollHeight,
      behavior: 'smooth'
    });
  };

  const handleScrollToBottom = () => {
    scrollToBottom();
  };

  const handleOnChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (checkOnySpecialCharacter(ev.target.value)) setIsValidQuery(false);
    else {
      setIsValidQuery(true);
    }
    setQueryText(ev.target.value);
  };

  useEffect(() => {
    initializeSession();
  }, []);

  useEffect(() => {
    if (seQuery) {
      refInput.current?.focus();
    }
    const timeoutId = setTimeout(() => {
      scrollToBottom();
    }, 100);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [seQuery]);

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      handleSendMessage(ev);
    }
  };

  useEffect(() => {
    if (chatQueryApi?.length > 0 && Object.keys(selectedChatThread).length === 0) {
      sendChatQuery(chatQueryApi);
    }
  }, [chatQueryApi]);

  useEffect(() => {
    setCurrentUserChatThreads(userChatThreads);
  }, [userChatThreads]);

  useEffect(() => {
    if (lastDeletedThreadID != 0) {
      const updated = userChatThreads.filter((item: any) => item.threadId !== lastDeletedThreadID);
      setCurrentUserChatThreads(updated);
      dispatch(saveUserChatThreadHistory(updated));
      dispatch(setLastDeletedThreadID(0));
    }
  }, [lastDeletedThreadID]);

  const handleDocDownload = async () => {
    dispatch(setDownloadFileMessageWord());
    setsShowDownloadInitiated(true);
    await exportToWord(
      seQuery,
      userData.profileImage,
      RESPONSE_BY.MARKET_REPORT,
      checkedCheckboxes,
      eyIPInterRadio,
      validURLinputValue
    );
    dispatch(clearDownloadFileMessage());
    saveDownloadChatActivity('Market Reports');
    setTimeout(() => {
      setsShowDownloadInitiated(false);
    }, 1000);
  };

  useEffect(() => {
    if (chatLoadCompleted) {
      saveChatConversation();
    }
  }, [chatLoadCompleted]);

  const saveChatConversation = async () => {
    if (chatDataSummmary?.table?.length === 0) return;
    let requestObject = getComputedRequestObject(
      selectedChatThread,
      regeneratedMessageId,
      chatDataSummmary,
      checkedCheckboxes,
      eyIPInterRadio,
      validURLinputValue,
      firstResponseTime,
      totalResponseTime,
      false,
      isCompletelyAbortedResposne,
      isParitallyAbortedResponse
    );
    const response: any = await saveUserChatHistory(requestObject);
    if (response && response?.threadId) {
      if (response.threadId !== selectedChatThread?.threadId) {
        setThreadsUnSelected(currentUserChatThreads);
        handleUserChatThreadHistory(
          requestObject,
          response.threadId,
          currentUserChatThreads,
          userData,
          dispatch
        );
      }
    }
    setRegeneratedMessageId(response?.messageId);
    setChatLoadCompleted(false);
  };

  const handleStopGeneration = () => {
    controller.abort();
    setIsCompletelyAbortedResposne(false);
    setIsParitallyAbortedResponse(false);
    if (chunkLoading) {
      setIsParitallyAbortedResponse(true);
      let lastChatData =
        chatDataSummmary?.table[chatDataSummmary?.table?.length - 1]?.SEResponse || undefined;
      if (lastChatData) {
        if (
          !('isCompletelyAbortedResposne' in lastChatData) &&
          !('isParitallyAbortedResponse' in lastChatData)
        ) {
          lastChatData.isCompletelyAbortedResposne = false;
          lastChatData.isParitallyAbortedResponse = true;
        }
      }
      setChatLoadCompleted(true);
    } else {
      setIsCompletelyAbortedResposne(true);
    }
  };

  const getResponseFlag = (SEResponse: any, flag: string) => {
    if (flag in SEResponse) {
      return SEResponse[flag];
    } else {
      return false;
    }
  };

  return (
    <>
      <div
        className={`marketChatContainer ${isRightSideBarOpen && 'marketChatContainer-block'} ${selectedSourceRadio === 'All' && 'no-padding'} ${isMRChipActive && 'mrWidth'}`}>
        <div
          ref={refToScroll}
          className={`marketChatContainer__scroll-container ${seQuery?.length > 0 && 'scroll-height'}`}>
          {seQuery?.map((chat: IQuery, ind: number) => {
            const { user, SEResponse, LLMResponse } = chat;
            return (
              <React.Fragment key={ind}>
                {showDownloadInitiated && (
                  <MotifToast
                    className={'marketChatContainer__toast-container-public'}
                    variant='info'
                    actionName=''
                    position='bottom'
                    onClose={() => {
                      setsShowDownloadInitiated(false);
                    }}>
                    <div>Downloading</div>
                  </MotifToast>
                )}
                {user && (
                  <SendMessage
                    key={ind}
                    message={user}
                    isNew={true}
                    userQueryWithResponse={chat}
                    loader={isLoading}
                    chatData={{ SEResponse }}
                    handleSendMessage={handleSendMessage}
                  />
                )}

                {SEResponse && (
                  <ChatReply
                    message={user?.summary}
                    chatData={{ SEResponse }}
                    key={ind.toString() + '__strctrd-se-chats'}
                    isNew={true}
                    isSE={true}
                    messageLoading={isLoading}
                    chunkLoading={chunkLoading}
                    isLastItem={ind === seQuery.length - 1}
                    isMarketReports={true}
                    scrollToBottom={handleScrollToBottom}
                    handleRegenerate={handleRegenerate}
                    isCompletelyAbortedResposne={getResponseFlag(
                      SEResponse,
                      'isCompletelyAbortedResposne'
                    )}
                    isParitallyAbortedResponse={getResponseFlag(
                      SEResponse,
                      'isParitallyAbortedResponse'
                    )}
                  />
                )}
              </React.Fragment>
            );
          })}
          {isLoading && !chunkLoading && <ChatReply loader={isLoading} isNew={true} isSE={true} />}
          <div className='stop-generation-container'>
            {(isLoading || chunkLoading) && (
              <button onClick={handleStopGeneration} className='stop-generation-button'>
                Stop Generating
              </button>
            )}
          </div>
        </div>
      </div>
      <div className='marketChatContainer__input-container'>
        <InputBox
          selectedSegment={true}
          queryText={queryText}
          refInput={refInput}
          hasQueries={seQuery?.length > 0}
          handleOnChange={handleOnChange}
          handleResetMessage={() => {}}
          handleSendMessage={handleSendMessage}
          handleKeyDown={handleKeyDown}
          disabled={isLoading}
          handleOnFocus={handleFocus}
        />
        <div className='marketChatContainer__download-btn-box'>
          <LinkButton onClick={() => handleDocDownload()} disabled={isLoading || isClearLoading}>
            <img src={downloadImg} /> &nbsp;
            <span className='marketChatContainer__download-text'>Download Chat</span>
          </LinkButton>
        </div>
      </div>
    </>
  );
};

export default MarketReports;
