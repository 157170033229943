import React, { useEffect } from 'react';
import styled from 'styled-components';
import { openInNewTabIcon } from '../../commonImage';
import { getAppURL, openLinkInSameTab } from '../../utils/commonUtils';
import { useSelector } from 'react-redux';
import { IStore } from '../../store';

interface CommonAppsContainerProps {
  item: { name: string; isFavourite: boolean; url?: string };
}

const CommonAppsContainer: React.FC<CommonAppsContainerProps> = ({ item }) => {
  const userMailID = useSelector((state: IStore) => state.common?.userEmail);
  useEffect(() => {
    item['url'] = getAppURL(item?.name);
  }, [item]);

  const nameMapping = {
    PEVC: 'Private Equity & Venture Capital',
    'Secondary Research': 'Outside-In Web Data'
  };

  return (
    <ButtonContainer>
      <Button onClick={() => openLinkInSameTab(item, userMailID)}>
        <span
          className={
            item?.name === 'Private Equity & Venture Capital'
              ? 'pevcName'
              : item?.name === 'Expert Interviews (MyJunior.AI)'
                ? 'myJuniorTitle'
                : 'appName'
          }>
          {nameMapping[item?.name as keyof typeof nameMapping] || item?.name}{' '}
        </span>
        <LinkIcon>
          <a>
            <img src={openInNewTabIcon} alt={item?.url} />
          </a>
        </LinkIcon>
      </Button>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div``;

const Button = styled.button`
cursor : pointer ; 
background-color:#FFFFFF;
border-radius: 10px;
height: 48px;
width: 100%;
display: flex;
-webkit-box-pack: justify;
justify-content: space-between;
border-left: 6px solid rgb(255, 230, 0);
margin-top: 6px;
border-top:#FFFFFF;
border-bottom:#FFFFFF;
border-right:#FFFFFF;

.pevcName{
    color: #4D4D5C;
    position: relative;
    top: 10px;
    font-size: 14px;
    left: 8px;
    text-align:left;
}
.appName{
    color: #4D4D5C;
    position: relative;
    top: 13px;
    font-size: 14px;
    left: 8px;
}
.myJuniorTitle{
    color: #4D4D5C;
    position: relative;
    top: 6px;
    font-size: 14px;
    left: 8px;
    text-align:left;
    }
}
}
`;

const LinkIcon = styled.div`
  a {
    cursor: pointer;
  }
  color: white;
  position: relative;
  top: 12px;
`;

export default CommonAppsContainer;
