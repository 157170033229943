import { COMMON_ERROR_ICON, feedbackMenuIcon, helpIcon, logoutIcon, mailIcon } from './commonImage';

// #region "URL"
export const HELP_URL = 'https://sites.ey.com/sites/DS_SaT/Pages/ey-competitive-edge.aspx';
// #endregion "URL"

// #region "API TENANT ID"
export const TENANT = process.env.REACT_APP_TENANT;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const X_TENANT_ID = process.env.REACT_APP_TENANT;
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL; // "https://euwdsatstewap02.azurewebsites.net/";
export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
export const PRODUCTION = 'production';
// #endregion "API TENANT ID"

// #region "APP URLs"
export const EMBRYONIC_APP_URL = process.env.REACT_APP_EMBRYONIC_APP_URL;
export const KC_APP_URL = process.env.REACT_APP_KC_APP_URL;
export const PEVC_APP_URL = process.env.REACT_APP_PEVC_APP_URL;
export const SUSTAINABILITY_APP_URL = process.env.REACT_APP_SUSTAINABILITY_APP_URL;
export const GENERATE_APP_URL = process.env.REACT_APP_GENERATE_APP_URL;
export const SI_APP_URL = process.env.REACT_APP_SI_APP_URL;
export const SUI_APP_URL = process.env.REACT_APP_SUI_APP_URL;
export const DISCOVERY_URL = process.env.REACT_APP_DISCOVERY_ROUTE;
export const SECONDARY_RESEARCH_APP_URL = process.env.REACT_APP_SECONDARY_RESEARCH_APP_URL;
export const VALUE_ACCELERATOR_APP_URL = process.env.REACT_APP_VALUE_ACCELERATOR_APP_URL;
export const MARKET_REPORTS_APP_URL = process.env.REACT_APP_MARKET_REPORT_APP_URL;
export const SAT_PORTAL_ROUTE = process.env.REACT_APP_SAT_PORTAL_ROUTE;
export const REACT_APP_POLLING_TIME_IN_SECOND: any =
  process.env.REACT_APP_POLLING_TIME_IN_SECOND ?? 60;
// #endregion "APP URLs"

// #region "Custom Defined Types"
export const profileMenuList = [
  {
    id: 1,
    item: 'Share Feedback',
    icon: feedbackMenuIcon
  },
  {
    id: 2,
    item: 'Support',
    icon: helpIcon
  },
  {
    id: 3,
    item: 'Contact us',
    icon: mailIcon
  },
  {
    id: 4,
    item: 'Logout',
    icon: logoutIcon
  }
];
// #endregion "Custom Defined Types"

export const SOMETHING_WENT_WRONG = 'Something went wrong.';
export const PLEASE_TRY_AFTER_SOMETIME = 'Please try after sometime.';

//#region "ErroPages"

export const PAGE_NOT_FOUND = 'Page Not Found';
export const INTERNAL_SERVER_ERROR = 'Internal Server Error';
export const ERROR_STATUS = {
  CODE_500: {
    HEADING: 'Internal server error',
    SUBHEADING: '500',
    DESCRIPTION1: 'Internal server error.',
    DESCRIPTION2:
      'There is a problem with the resource you are looking for and it can not be displayed.',
    ICON: COMMON_ERROR_ICON
  },
  CODE_404: {
    HEADING: 'Page Not Found',
    SUBHEADING: ':-( 404',
    DESCRIPTION1: 'Ooops! Page Not Found',
    DESCRIPTION2:
      'Sorry.. but the page you are looking for does not exist, has been removed, or is temporarily unavailable',
    ICON: COMMON_ERROR_ICON
  }
};

export const RESPONSE_STATUS_CODE = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  RELOAD_DATA: 429,
  UNAVAILABLE: 451,
  INTERNAL_SERVER_ERROR: 500,
  FORBIDDEN: 403
};

export const MAX_TEXT_LENGTH = 1000;
export const MIN_SYMBOLS_TO_START_SEARCHING = 2;
export const contentAge = [
  { label: 'Last 6 months', value: '0' },
  { label: '1 year', value: '1' },
  { label: '2 years', value: '2' },
  { label: '3 years', value: '3' }
];
export const surveyPeriod = [
  { label: '6 months', value: '0' },
  { label: '1 year', value: '1' },
  { label: '2 years', value: '2' },
  { label: '3 years', value: '3' },
  { label: 'More than 3 years', value: '4' }
];
export const surveyPeriod1 = [
  // { label: 'Select All', value: '0', isChecked: false },
  { label: '6 months', value: '0', isChecked: false },
  { label: '1 year', value: '1', isChecked: false },
  { label: '2 years', value: '2', isChecked: false },
  { label: '3 years', value: '3', isChecked: false }
];
export const expertContentAge = [
  { label: 'Last 6 months', value: '0' },
  { label: '1 year', value: '1' },
  { label: '2 years', value: '2' },
  { label: '3 years', value: '3' },
  { label: '3+ years', value: '4' }
];
export const eypProspectiveQueries = [
  { query: 'What is the global plant-based protein market size?' },
  {
    query: 'Which segment is fast growing in freeze-dried pet food?'
  },
  { query: 'How is the sustainable fiber value chain segmented?' }
];

export const expertsQueries = [
  { query: 'Find me experts in pet-care' },
  {
    query: 'Find me experts who have worked on electric vehicle due diligence projects'
  },
  { query: 'Find me experts on diabetes treatments' }
];
export const sectorQueries = [
  { query: 'What are some of the recent trends in debt financing?' },
  {
    query: 'What percentage of electric vehicle growth does the US represent?'
  },
  { query: 'What are the elements of the fiber cable value chain?' }
];
export const marketRepotQueries = [
  { query: 'What are some of the recent trends in debt financing?' },
  {
    query: 'What percentage of electric vehicle growth does the US represent?'
  },
  { query: 'What are the elements of the fiber cable value chain?' }
];
export const surveyQueries = [
  { query: `What do CEO's see as the greatest global challenges facing their companies?` },
  {
    query: 'How do Chief Sustainability Officers feel about the rate of emission reduction?'
  },
  {
    query:
      'What are company executives planning to prioritize to address political risk management?'
  }
];
// #endregion "ErroPages"
export const LOCAL_STORAGE = {
  USER_PROFILE_PIC: 'userProfilePic'
};
export const FEEDBACK_ATTACHMENT_MAX_LIMIT = 2000000; // 2 MB
export const mrDataSources = [
  { label: 'All', value: 'All', isDisabled: true },
  { label: 'IBIS World', value: 'IBIS World', isDisabled: true },
  { label: 'TBRC', value: 'TBRC', isDisabled: false }
];
export const MY_JUNIOR_PORTAL_URL = 'https://eyp.myjunior.ai/?app=CoE'