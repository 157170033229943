const apiRoutes = {
  sqlEgptPrompt: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/e-gpt/sql-prompt`,
  exportEgptCSVChat: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/e-gpt/sql-query-to-csv`,
  clearEgptChat: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/e-gpt/clearchat`,
  sqlKcPrompt: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/e-gpt/sql-prompt`,
  webResponseEgpt: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/e-gpt/web-response`,
  getEyIpKcResponse: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/KC/extract_and_get_answers_streaming`,
  kcExperts: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/KC/experts`,
  downloadKCDoc: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/KC/download-file`,
  clearKcUserSession: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/KC/clear_user_session`,
  sectorGeography: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/SI/create-hierarchy`,
  marketREportsSectorGeography: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/SUI/get_mr_filter_json`,
  chatConversations: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/chathistory/conversations`,
  saveChatHistory: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/chathistory/save`,
  queryResponseSector: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/SI/extract_and_get_answers_streaming`,
  downloadSectorDoc: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/SI/download-file`,
  chatThreadHistory: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/chathistory/threads`,
  chatdocumentHistory: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/byod/GetProjectMasterData`,
  updateChatThreadTitle: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/chathistory/updateThreadTitle`,
  deleteChatThreadHistory: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/chathistory/delete`,
  exportCSVChat: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/e-gpt/sql-query-to-csv`,
  surveySummaryChunks: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/SUI/extract_and_get_answers_streaming`,
  queryResponseMarketReports: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/SUI/extract_and_get_mr_answers_streaming`,
  discovery_route: `${process.env.REACT_APP_DISCOVERY_ROUTE}`,
  createProject: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/byod/create-project`,
  deleteDocThreadHistory: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/byod/delete-project`,
  uploadDocument: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/byod/upload-document`,
  getPromptResponse: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/byod/get-prompt-response`,
  surveyLists: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/SUI/survey_files`,
  clearSIUserSession: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/SI/clear_user_session`,
  clearSUIUserSession: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/SUI/clear_user_session`,
  uploadURL: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/e-gpt/web_response_streaming`,
  getDocumentDetails: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/byod/get-document-details?Project_UUID=`,
  deleteDocument: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/byod/delete-document`,
  getPromtDetailsByProject: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/byod/get-prompt-details-by-project`,
  deletePrompt: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/byod/delete-prompt`,
  downloadDocument: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/byod/download-document`,
  getDocumentSetting: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/byod/GetDocumentSetting`,
  queryResponseExperts: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/kc/ey-experts`,
  expertsHirarchy: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/kc/create-hierarchy`,
  // *** uploadSharePointDocument need to chnage after end poits ready ***
  uploadSharePointDocument: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/byod/`,
  ///#region MS Team's Project Workspace
  createNewProject: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/projectworkspace/project`,
  createTeamsChannel: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/projectworkspace/Graph/createTeamsChannel`,
  addMembersToChannel: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/projectworkspace/Graph/addMembers`,
  createTemplateFolders: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/projectworkspace/template/uploadfolders`,
  installAppForTeams: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/projectworkspace/Graph/installappforteams`,
  getPaceID: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/projectworkspace/PaceDb`,
  createProjectTemplate : `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/projectworkspace/template`,
  createProjectSearchMember :  `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/projectworkspace/Graph/searchuser`,
  getWebUrlforTeams: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/projectworkspace/project/channeltabweburl`,
  getSharePointTokenForTeams: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/tab/getuseraccesstoken`,
  ///#endregion MS Team's Project Workspace
  saveDownloadChatActivity: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/kpi/save-download`,
  saveuserFeedback: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/feedback/save`,
  marketFileDownload: `${process.env.REACT_APP_BACKEND_API_URL}ie/api/v1/SUI/get_pdf/`,
};

export default apiRoutes;
