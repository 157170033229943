import React, { useState, Fragment, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Modal from '../../common/modal/Modal';
import scssVar from '../../../styles/variables.module.scss';
import { ModalType } from '../../../models/ModalType';
import LoadIndicator from '../loadIndicator/LoadIndicator';
import { useDispatch } from 'react-redux';
import MotifTabNavigation, { MotifTabControl } from '@ey-xd/motif-react/components/tab-navigation';
const { lightgray, fontInterstate, newProjectModalWidth, grey50 } = scssVar;
import PROJECT_CREATION_SUCCESS_IMG from '../../../assets/images/project-success.png';
import { MotifProgressIndicatorStep, MotifProgressIndicator } from '@ey-xd/motif-react';
import {
    ENGAGEMENT_MANAGEMENT_GUIDANCE_URL,
    LOCALSTORAGE_PROJECT_DATA,
    USER_TYPE,
    PROGRESS_BAR_STATUS,
    PROGRESS_BAR_STEPS
} from '../../../label.constant';
import AddMilestones from './AddMilestones';
import EditControlFIleStructure from './EditControlFIleStructure';
import AddMembers from './AddMembers';
import ProjectPreview from './ProjectPreview';
import AddProject from './AddProject';
import {
    addMembersAsync,
    createTeamsChannelAsync,
    getWebUrlforTeams,
    initateNewProject,
    installAppForTeams,
    uploadFolderAsync
} from '../../../services/teamsProject.service';
import { RESPONSE_STATUS_CODE } from '../../../app.constant';
import { createRequestBody, projectDataConfig } from './addProjectUtils';
import { clearCreateProjectStorageValues } from '../../../utils/commonUtils';

interface IOwnProps {
    show: boolean;
    onClose: () => void;
    headerIcon: React.ReactChild;
}

const NewProjectModal: React.FC<IOwnProps> = ({ show, onClose, headerIcon }: IOwnProps) => {
    const addProjectRef: any = useRef("");
    const [newProjectId, setNewProjectId] = useState<number>(0);

    const [isLoading, setIsLoading] = useState(false);
    const [isTeamLoading, setIsTeamLoading] = useState(false);
    const [isTeamLoadingFailed, setIsTeamLoadingFailed] = useState(false);
    const [isTeamLoadingSuccess, setIsTeamLoadingSuccess] = useState(false);

    const [isMemberLoading, setIsMemberLoading] = useState(false);
    const [isMemberLoadingFailed, setIsMemberLoadingFailed] = useState(false);
    const [isMemberLoadingSuccess, setIsMemberLoadingSuccess] = useState(false);

    const [isAppLoading, setIsAppLoading] = useState(false);
    const [isAppLoadingFailed, setIsAppLoadingFailed] = useState(false);
    const [isAppLoadingSuccess, setIsAppLoadingSuccess] = useState(false);

    const [isfolderLoading, setIsFolderLoading] = useState(false);
    const [isfolderLoadingFailed, setIsFolderLoadingFailed] = useState(false);
    const [isfolderLoadingSuccess, setIsFolderLoadingSuccess] = useState(false);
    const [folderErrorMsg, setFolderErrorMsg] = React.useState<string>('');
    const [savedProjectName, setSavedProjectName] = useState<string>('');

    const [disableAddProjectNavigation, setDisableAddProjectNavigation] = useState(false);
    const [validateErrorAddProject, setValidationErrorAddProject] = useState<boolean>(false);
    const refToScroll = useRef<HTMLDivElement>(null);
    const refToScrollError = useRef<HTMLSpanElement>(null);

    const scrollToTop = () => {
        setTimeout(() => {
            if (refToScroll.current) {
                refToScroll.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 500);
    };

    const scrollToBottom = () => {
        setTimeout(() => {
            if (refToScrollError.current) {
                refToScrollError.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 500);
    };

    const [teamsWebURL, setTeamsWebURL] = useState('');
    let selectedTemplateID = localStorage.getItem('selectedTemplateID') || '';
    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = React.useState(0);

    // milestone
    const [milestones, setMilestones] = useState([]);
    const [disableNavigation, setDisableNavigation] = useState(false);

    const handleSaveMilestones = (milestonesArray: any) => {
        setMilestones(milestonesArray);
        milestonesArray.forEach((element: any) => {
            if (element.date instanceof Date) {
                var utcDate = new Date(Date.UTC(element.date.getFullYear(), element.date.getMonth(), element.date.getDate()));
                element.date = utcDate;
            }
        });

        localStorage.setItem('milestones', JSON.stringify(milestonesArray));
    };

    const handleValidation = (isValid: any) => {
        setDisableNavigation(!isValid);
    };
    //-------------------

    const goBack = () => {
        scrollToTop();
        setTabIndex(tabIndex - 1);
    };
    const goNext = () => {
        scrollToTop();
        setTabIndex(tabIndex + 1);
    };

    /**
     * Create Teams API call
     * @param projectId
     */
    const createTeam = async (projectId: number) => {
        try {
            setIsTeamLoading(true);
            setIsTeamLoadingFailed(false);
            setIsTeamLoadingSuccess(false);

            if (projectId !== 0) {
                let response = await createTeamsChannelAsync(projectId.toString());
                if (response && response?.status === RESPONSE_STATUS_CODE.OK) {
                    if (response.data) {
                        let teamID = response.data?.split(':')[1] ? response.data.split(':')[1] : '';
                        let teamsWebURLResponse = await getWebUrlforTeams(teamID.trim());
                        if (teamsWebURLResponse.status === RESPONSE_STATUS_CODE.OK)
                            setTeamsWebURL(teamsWebURLResponse.data);
                    }
                    addMembers(projectId);
                    setIsTeamLoading(false);
                    setIsTeamLoadingFailed(false);
                    setIsTeamLoadingSuccess(true);
                } else {
                    setIsTeamLoading(false);
                    setIsTeamLoadingFailed(true);
                }
            } else {
                setIsTeamLoading(false);
                setIsTeamLoadingFailed(true);
            }
        } catch (error) {
            setIsTeamLoading(false);
            setIsTeamLoadingFailed(true);
        }
    };

    /**
     * Add members API call
     * @param projectId
     */
    const addMembers = async (projectId: number) => {
        try {
            setIsMemberLoading(true);
            setIsMemberLoadingFailed(false);
            setIsMemberLoadingSuccess(false);
            if (projectId !== 0) {
                let responseMembers = await addMembersAsync(projectId.toString());
                if (responseMembers && responseMembers?.status === RESPONSE_STATUS_CODE.OK) {
                    proActiveAppInstallation(projectId);
                    setIsMemberLoading(false);
                    setIsMemberLoadingFailed(false);
                    setIsMemberLoadingSuccess(true);
                } else {
                    setIsMemberLoading(false);
                    setIsMemberLoadingFailed(true);
                }
            }
        } catch (error) {
            setIsMemberLoading(false);
            setIsMemberLoadingFailed(true);
        }
    };

    /**
     * App install API call
     * @param projectId
     */
    const proActiveAppInstallation = async (projectId: number) => {
        try {
            setIsAppLoading(true);
            setIsAppLoadingFailed(false);
            setIsAppLoadingSuccess(false);
            let response = await installAppForTeams(projectId);
            if (response && response?.status === RESPONSE_STATUS_CODE.OK) {
                createFolder(projectId);
                setIsAppLoading(false);
                setIsAppLoadingFailed(false);
                setIsAppLoadingSuccess(true);
            } else {
                setIsAppLoading(false);
                setIsAppLoadingFailed(true);
            }
        } catch (err) {
            setIsAppLoading(false);
            setIsAppLoadingFailed(true);
        }
    };

    /**
     * Create Folder API call
     * @param projectId
     */
    const createFolder = async (projectId: number) => {
        try {
            setIsFolderLoading(true);
            setIsFolderLoadingFailed(false);
            setIsFolderLoadingSuccess(false);
            let response = await uploadFolderAsync(projectId.toString(), selectedTemplateID);
            if (response && response?.status === RESPONSE_STATUS_CODE.OK) {
                setIsFolderLoading(false);
                setTabIndex(7);
                setIsFolderLoadingFailed(false);
                setIsFolderLoadingSuccess(true);
                setSavedProjectName(localStorage.getItem('projectName') || '');
            } else {
                setIsFolderLoading(false);
                setIsFolderLoadingFailed(true);
            }
        } catch (error: any) {
            setIsFolderLoading(false);
            setFolderErrorMsg(error.message);
            setIsFolderLoadingFailed(true);
        }
    };

    window.onload = function () {
        localStorage.removeItem('projectName');
        localStorage.removeItem('selectedTemplate');
        localStorage.removeItem('selectedTemplateID');
        localStorage.removeItem('milestones');
        localStorage.removeItem(USER_TYPE.CO_OWNER);
        localStorage.removeItem(USER_TYPE.MEMBER);
        localStorage.removeItem(USER_TYPE.ENGAGEMENT_PARTNER);
        localStorage.removeItem(USER_TYPE.ENGAGEMENT_MANAGER);
        localStorage.removeItem('templateName');
        localStorage.removeItem(LOCALSTORAGE_PROJECT_DATA);
        setIsTeamLoading(false);
        setIsTeamLoadingFailed(false);
        setIsTeamLoadingSuccess(false);
        setIsMemberLoading(false);
        setIsMemberLoadingFailed(false);
        setIsMemberLoadingSuccess(false);
        setIsAppLoading(false);
        setIsAppLoadingFailed(false);
        setIsAppLoadingSuccess(false);
        setIsFolderLoading(false);
        setIsFolderLoadingFailed(false);
        setIsFolderLoadingSuccess(false);
    };

    const selectedTemplete: any = localStorage.getItem('selectedTemplate');
    const selctedMilestone: any = localStorage.getItem('milestones');
    const selectedTemplateId = localStorage.getItem('selectedTemplateID');
    const coOwner: any = localStorage.getItem(USER_TYPE.CO_OWNER);
    const member: any = localStorage.getItem(USER_TYPE.MEMBER);
    const engagementPartner: any = localStorage.getItem(USER_TYPE.ENGAGEMENT_PARTNER);
    const engagementManager: any = localStorage.getItem(USER_TYPE.ENGAGEMENT_MANAGER);
    const milestone =
        selctedMilestone == '[{"title":"","date":"","fromTime":"","toTime":""}]'
            ? []
            : JSON.parse(selctedMilestone);
    const requestMilestones =
        milestone && milestone.length > 0
            ? milestone.map((item: any) => {
                return {
                    title: item.title,
                    startDate: item.date,
                    startTime: item.fromTime,
                    endTime: item.toTime
                };
            })
            : [];
    const updatedCoOwner = JSON.parse(coOwner)?.map((item: any) => {
        return {
            ...item,
            type: 'coowner'
        };
    });

    const updatedMember = JSON.parse(member)?.map((item: any) => {
        return {
            ...item,
            type: 'members'
        };
    });

    const updatedEngagementPartner = JSON.parse(engagementPartner)?.map((item: any) => {
        return {
            ...item,
            type: 'engagementPartner'
        };
    });

    const updatedEngagementManager = JSON.parse(engagementManager)?.map((item: any) => {
        return {
            ...item,
            type: 'engagementManager'
        };
    });

    let allMembers: any = [];

    if (
        Array.isArray(updatedCoOwner) &&
        Array.isArray(updatedMember) &&
        Array.isArray(updatedEngagementPartner) &&
        Array.isArray(updatedEngagementManager)
    ) {
        allMembers = [
            ...updatedCoOwner,
            ...updatedMember,
            ...updatedEngagementPartner,
            ...updatedEngagementManager
        ];
    }

    const isValidCoOWner = useRef(true);
    const isValidEngagementPartner = useRef(true);
    const isValidEngagementManager = useRef(true);

    const validateMembers = () => {
        const projectDataLocalStorage: any = localStorage.getItem(LOCALSTORAGE_PROJECT_DATA);
        const projectData = projectDataConfig(JSON.parse(projectDataLocalStorage), false);
        const coOwner: any = localStorage.getItem(USER_TYPE.CO_OWNER);
        const engagementPartner: any = localStorage.getItem(USER_TYPE.ENGAGEMENT_PARTNER);
        const engagementManager: any = localStorage.getItem(USER_TYPE.ENGAGEMENT_MANAGER);
        if (projectData?.category === 'Client project') {
            isValidCoOWner.current = JSON.parse(coOwner).length > 0;
            isValidEngagementPartner.current = JSON.parse(engagementPartner).length > 0;
            isValidEngagementManager.current = JSON.parse(engagementManager).length > 0;
            if (
                !isValidCoOWner.current ||
                !isValidEngagementPartner.current ||
                !isValidEngagementManager.current
            ) {
                handleValidation(false);
                return false;
            }
            if (
                isValidCoOWner.current &&
                isValidEngagementPartner.current &&
                isValidEngagementManager.current
            ) {
                handleValidation(true);
                return true;
            }
        } else {
            handleValidation(true);
            return true;
        }
    };

    /**
     * Create New Project API call
     */
    const createNewProject = async () => {
        let tempData = localStorage.getItem(LOCALSTORAGE_PROJECT_DATA);
        let projectData = {};
        if (tempData) {
            projectData = JSON.parse(tempData);
        }
        const requestObject = createRequestBody(
            projectData,
            selectedTemplete,
            requestMilestones,
            allMembers,
            selectedTemplateId
        );
        const newProjectCreationResponse: any = await initateNewProject(requestObject);
        if (
            newProjectCreationResponse &&
            newProjectCreationResponse?.status === RESPONSE_STATUS_CODE.OK
        ) {
            setNewProjectId(newProjectCreationResponse.data);
            createTeam(newProjectCreationResponse.data);
        } else {
            setNewProjectId(0);
        }
    };

    useEffect(() => {
        if (tabIndex === 6) createNewProject();
    }, [tabIndex]);
    const [isTemplateSelected, setIsTemplateSelected] = useState(true);
    const onActionButtonClick = () => {
        if (tabIndex === 1) {
            if (addProjectRef.current) {
                const isValid = addProjectRef.current.validateData();
                if (isValid) {
                    goNext();
                }
                else {
                    setValidationErrorAddProject(true);
                    scrollToBottom();
                    return;
                }
            }
        }
        if (!disableNavigation) {
            if (tabIndex >= 0 && tabIndex <= 5) {
                if (tabIndex === 2) {
                    isValidCoOWner.current = true;
                    isValidEngagementPartner.current = true;
                    isValidEngagementManager.current = true;
                    goNext();
                }
                if (tabIndex === 3) {
                    if (validateMembers()) {
                        goNext();
                    } else return;
                }
                if (tabIndex === 4) {
                    if (localStorage.getItem('selectedTemplateID') && localStorage.getItem('selectedTemplateID') !== '')
                        goNext();
                    else
                        setIsTemplateSelected(false);
                }
                else goNext();
            }
        }
    };

    const onSkipButtonClick = () => {
        if (tabIndex == 2) {
            setMilestones([]);
            localStorage.setItem('milestones', JSON.stringify([]));
            setDisableNavigation(false);
            isValidCoOWner.current = true;
            isValidEngagementPartner.current = true;
            isValidEngagementManager.current = true;
            goNext();
        }
    };
    const disableSave = (isNavigationDisable: boolean, detail: any) => {
        setDisableAddProjectNavigation(isNavigationDisable);
        setValidationErrorAddProject(false);
    };

    const onCloseButtonClick = () => {
        closeModal();
    };

    const onCancelBtnClicked = () => {
        if (!disableNavigation) {
            if (tabIndex === 5) {
                setTabIndex(1);
            } else if (tabIndex > 1 && tabIndex <= 5) {
                goBack();
            } else {
                closeModal();
            }
        }

        if (tabIndex === 1) {
            localStorage.removeItem(LOCALSTORAGE_PROJECT_DATA);
        }
        if (tabIndex === 2 || tabIndex === 3 || tabIndex === 4) {
            setDisableNavigation(false);
            goBack();
        }
    };

    const closeModal = () => {
        clearCreateProjectStorageValues();
        setTabIndex(0);
        setIsTeamLoading(false);
        setIsTeamLoadingFailed(false);
        setIsTeamLoadingSuccess(false);
        setIsMemberLoading(false);
        setIsMemberLoadingFailed(false);
        setIsMemberLoadingSuccess(false);
        setIsAppLoading(false);
        setIsAppLoadingFailed(false);
        setIsAppLoadingSuccess(false);
        setIsFolderLoading(false);
        setIsFolderLoadingFailed(false);
        setIsFolderLoadingSuccess(false);
        setDisableNavigation(false);
        if (tabIndex > 1) addProjectRef?.current?.resetUIValidations();
        onClose();
    };

    const onTeamsChannelClicked = () => {
        if (tabIndex === 7 && teamsWebURL) {
            window.open(teamsWebURL, '_blank');
        }
    };

    const projectCreationSteps = [
        {
            isLoading: isTeamLoading,
            hasFailed: isTeamLoadingFailed,
            label: 'Step 1 failed',
            onRetry: () => createTeam(newProjectId)
        },
        {
            isLoading: isMemberLoading,
            hasFailed: isMemberLoadingFailed,
            label: 'Step 2 failed',
            onRetry: () => addMembers(newProjectId)
        },
        {
            isLoading: isAppLoading,
            hasFailed: isAppLoadingFailed,
            label: 'Step 3 failed',
            onRetry: () => proActiveAppInstallation(newProjectId)
        },
        {
            isLoading: isfolderLoading,
            hasFailed: isfolderLoadingFailed,
            label: 'Step 4 failed',
            onRetry: () => createFolder(newProjectId)
        }
    ];

    const getStepStatus = (step: number) => {
        const stepStatuses: any = {
            1: {
                isLoadingSuccess: isTeamLoadingSuccess,
                isLoadingFailed: isTeamLoadingFailed,
                isLoading: isTeamLoading
            },
            2: {
                isLoadingSuccess: isMemberLoadingSuccess,
                isLoadingFailed: isMemberLoadingFailed,
                isLoading: isMemberLoading
            },
            3: {
                isLoadingSuccess: isAppLoadingSuccess,
                isLoadingFailed: isAppLoadingFailed,
                isLoading: isAppLoading
            },
            4: {
                isLoadingSuccess: isfolderLoadingSuccess,
                isLoadingFailed: isfolderLoadingFailed,
                isLoading: isfolderLoading
            }
        };

        const status = { ...stepStatuses[step] };

        if (status.isLoadingSuccess) {
            return PROGRESS_BAR_STATUS.COMPLETED;
        } else if (status.isLoadingFailed) {
            return PROGRESS_BAR_STATUS.WARNING;
        } else if (status.isLoading) {
            return PROGRESS_BAR_STATUS.ACTIVE;
        } else {
            return PROGRESS_BAR_STATUS.DEFAULT;
        }
    };

    return (
        <Modal
            isOpen={show}
            type={ModalType.CreateNewProject}
            headerTitle=''
            actionBtnEnabled={!isLoading && !disableNavigation}
            actionBtnText={
                tabIndex === 0
                    ? 'Ok'
                    : tabIndex < 4
                        ? 'Next'
                        : tabIndex === 4
                            ? 'Preview'
                            : 'Create Project'
            }
            onActionBtnClicked={() => onActionButtonClick()}
            onCancelBtnClicked={() => onCancelBtnClicked()}
            cancelBtnText={
                tabIndex > 0 ? (tabIndex === 5 ? 'Edit' : tabIndex === 1 ? 'Cancel' : 'Back') : ''
            }
            onCloseBtnClicked={() => onCloseButtonClick()}
            onSkipBtnClicked={() => onSkipButtonClick()}
            headerIcon={headerIcon}
            showHeaderIcon={true}
            width={+newProjectModalWidth}
            customHeaderClass='new-project-header-1'
            tabIndex={tabIndex}
            teamsWebURL={teamsWebURL}
            onTeamsChannelClicked={() => onTeamsChannelClicked()}>
            <StyledLogoutModal className='new-project-header-1' ref={refToScroll}>
                {isLoading ? (
                    <div className='loader'>
                        <LoadIndicator color={'#000000'} />
                    </div>
                ) : (
                    <div className='create-project-container'>
                        {tabIndex === 0 && (
                            <Fragment>
                                <h4>Create New Project</h4>
                                <p>
                                    Important to know when creating a new MS Teams site using ”Create Project” button.
                                    <br />
                                    <br />• Remember to follow EY and SaT guidance on use of MS Teams as included in
                                    the
                                    <a href={ENGAGEMENT_MANAGEMENT_GUIDANCE_URL} target="_blank" className='url-link-font'>
                                        {' '}
                                        Competitive Edge Engagement Management Guidance
                                    </a>
                                    .
                                    <br />
                                    <br />
                                    • You should not use MS Teams as a long-term repository for client
                                    deliverables/project files, as files are retained for the life of the MS Teams
                                    site and will be deleted upon expiry or termination of the MS Teams site.
                                    <br />
                                    <br />• Documents required to be maintained under SaT policy must be transferred
                                    from MS Teams to an appropriate archiving site or repository
                                </p>
                            </Fragment>
                        )}
                        {tabIndex > 0 && tabIndex <= 4 && (
                            <div>
                                <h4>Create New Project</h4>
                                <MotifTabNavigation>
                                    <MotifTabControl className={tabIndex > 0 && tabIndex <= 5 ? 'motif-active' : ''}>
                                        <span
                                            className={
                                                tabIndex > 0 && tabIndex <= 5 ? 'active-tab-font' : 'inactive-tab-font'
                                            }>
                                            Add project details
                                        </span>
                                    </MotifTabControl>
                                    <MotifTabControl className={tabIndex > 1 && tabIndex <= 5 ? 'motif-active' : ''}>
                                        <span
                                            className={
                                                tabIndex > 1 && tabIndex <= 5 ? 'active-tab-font' : 'inactive-tab-font'
                                            }>
                                            Add milestones
                                        </span>
                                    </MotifTabControl>
                                    <MotifTabControl className={tabIndex > 2 && tabIndex <= 5 ? 'motif-active' : ''}>
                                        <span
                                            className={
                                                tabIndex > 2 && tabIndex <= 5 ? 'active-tab-font' : 'inactive-tab-font'
                                            }>
                                            Add members
                                        </span>
                                    </MotifTabControl>
                                    {/* <MotifTabControl className={tabIndex > 3 && tabIndex <= 5 ? 'motif-active' : ''}>
                    Request tools
                  </MotifTabControl> */}
                                    <MotifTabControl className={tabIndex == 4 ? 'motif-active' : ''}>
                                        <span className={tabIndex == 4 ? 'active-tab-font' : 'inactive-tab-font'}>
                                            Edit control file structure
                                        </span>
                                    </MotifTabControl>
                                </MotifTabNavigation>
                                <div className='tab-container'>
                                    {tabIndex === 1 && (
                                        <span>
                                            <AddProject ref={addProjectRef} disablecheck={disableSave} tabIndex={tabIndex} />
                                            {validateErrorAddProject && (
                                                <span className='validation-error' ref={refToScrollError}>
                                                    Please select all the mandatory fields marked with *
                                                </span>
                                            )}
                                        </span>
                                    )}
                                    {tabIndex === 2 && (
                                        <span>
                                            <AddMilestones
                                                onSave={handleSaveMilestones}
                                                onValidation={handleValidation}
                                            />
                                        </span>
                                    )}
                                    {tabIndex === 3 && (
                                        <span>
                                            <AddMembers
                                                isValidCoOwner={isValidCoOWner.current}
                                                isValidEngagementPartner={isValidEngagementPartner.current}
                                                isValidEngagementManager={isValidEngagementManager.current}
                                                onValidation={handleValidation}
                                            />
                                        </span>
                                    )}
                                    {/* {tabIndex === 4 && <span>Request Tools Content</span>} */}
                                    {tabIndex === 4 && (
                                        <span>
                                            <EditControlFIleStructure
                                                key={isTemplateSelected}
                                                isValidTemplateSelected={isTemplateSelected}
                                            />
                                        </span>
                                    )}
                                </div>
                            </div>
                        )}
                        {tabIndex === 5 && (
                            <div className='preview-mode-div'>
                                <ProjectPreview />
                            </div>
                        )}
                        {tabIndex === 6 && (
                            <div className='creation-progress-div'>
                                <span className='progress-info'>
                                    Project is being created, please wait for the response. Do not close this task
                                    module. Known Issue: Step 3 may fail occasionally mainly due to network/MS
                                    SharePoint connection issues. We are currently working on resolving this issue. In
                                    the event Step 3 fails, please reboot your laptop and re-try the new project
                                    creation, or try a different network. If the issue persists - inform{' '}
                                    <a className='email-link-font' href='mailto:competitiveedge@uk.ey.com'>
                                        competitiveedge@uk.ey.com
                                    </a>
                                    . Thanks for your patience and support!
                                </span>
                                <div className='status-stepper-div'>
                                    <MotifProgressIndicator className='progress-bar' variant={'row'}>
                                        {PROGRESS_BAR_STEPS.map((step, index) => (
                                            <MotifProgressIndicatorStep
                                                key={index}
                                                status={getStepStatus(index + 1)}
                                                heading={step.heading}
                                                body={step.body}
                                            />
                                        ))}
                                    </MotifProgressIndicator>
                                </div>
                                <div className='retry-loader-container'>
                                    {projectCreationSteps.map((step, index) => (
                                        <div key={index} className='flex-box-retry'>
                                            {step.isLoading && <div className='loader-retry'></div>}
                                            {step.hasFailed && (
                                                <Fragment>
                                                    <span className='retry-label'>{step.label}</span>
                                                    <span className='retry-button' onClick={step.onRetry}>
                                                        Retry
                                                    </span>
                                                </Fragment>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {tabIndex === 7 && (
                            <div className='creation-success-div'>
                                <img src={PROJECT_CREATION_SUCCESS_IMG}></img>
                                <h4 className='project-success-message'>Project created successfully!</h4>
                                <span className='success-info'>
                                    {`A team is created by the name ${savedProjectName}. Find your newly created
                  teams site saved as your project name in the left navigation bar on your Teams
                  menu`}
                                </span>
                            </div>
                        )}
                    </div>
                )}
            </StyledLogoutModal>
        </Modal>
    );
};

const StyledLogoutModal = styled.div`
  min-height: 455px;
  max-height: auto;
  overflow-y: hidden;
  color: ${lightgray};
  .loader {
    position: relative;
    bottom: 30px;
  }

  * {
    font-family: ${scssVar.fontInterstate} !important;
    font-size: ${'12px'} !important;
    color: ${scssVar.alternateblack};
  }

  .progress-bar {
    width: '100%';
    color: 'grey';
  }

  .create-project-container {
    padding: 0% 4% 14% 4%;
    text-align: left;
  }
  .new-project-header-1 {
    /* max-height: calc(90vh - 100px); */
    overflow-y: auto;
    word-wrap: break-word;
  }
  .motif-tab-button {
    margin-right: 2%;
    padding: 5px 25px 10px 2px;
    pointer-events: none;
  }

  .motif-tab-navigation {
    border-style: none;
  }
  .motif-tab-navigation .motif-tab-button {
    border-width: 3px 0px 0px 0px !important;
  }
  .tab-container,
  .preview-mode-div {
    margin-top: 2%;
  }
  .creation-success-div {
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .success-info {
    text-align: center;
    padding: 0rem 7rem;
  }
  .creation-progress-div {
    padding: 6%;
  }
  .progress-info {
    color: rgba(185, 37, 28, 1);
  }
  .status-stepper-div {
    margin-top: 5%;
  }

  .retry-loader-container {
    display: flex;
    justify-content: space-between;
    // margin-left: -5px;
    margin-top: 30px;
  }

  .flex-box-retry {
    flex: 1;
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    align-items: center;
  }

  .retry-label {
    color: #b9251c;
    font-size: 12px !important;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .retry-button {
    color: #0f69ae;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
  }

  .loader-retry {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #2e2e38;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .validation-error {
    margin-left: 5px;
    color: red;
    top: 10px;
    position: relative;
  }

  .motif-tab-button {
    --tab-navigation-horizontal-button--border-color: rgb(152, 151, 166);
  }

  .active-tab-font {
    color: #2e2e38;
  }

  .inactive-tab-font {
    color: #9897a6;
  }
  .url-link-font {
    color: #0f69ae;
    padding-left: 5px;
  }
  .email-link-font {
    color: #0f69ae;
  }
  .project-success-message {
    font-size: 18px !important;
  }
  .motif-tree-node {
    margin-top: 10px;
  }
`;

export default React.memo(NewProjectModal);