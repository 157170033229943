export enum RESPONSE_TYPE {
  ANSWER = 'answer',
  TABLE = 'table',
  ERROR = 'error',
  STOP_GENRATION = 'stop_generation'
}

export type IContent = {
  [key: string]: string;
};
export interface ISectorContent extends IRelevantFiles {}

export type IQueryContent = {
  Content?: IContent[];
  ResponseType?: RESPONSE_TYPE;
  responseType?: string;
  Sources?: string[];
  summary: string;
  downloadUrl?: string;
  sqlQuery?: string;
  showTable?: boolean;
  showSummary?: boolean;
  relevantFiles?: Partial<IRelevantFiles>[];
  suggestions?: { followup: any; download: any; portfolio: any, sector: any };
  isCompletelyAbortedResposne?: boolean;
  isParitallyAbortedResponse?: boolean;
  isChunkLoading?: boolean;
};
export type ISectorQueryContent = {
  Content?: IContent[];
  ResponseType?: RESPONSE_TYPE;
  Sources?: string[];
  summary: string;
  downloadUrl?: string;
  sqlQuery?: string;
  showTable?: boolean;
  eyAEMID?: string;
};

export type IQuery = {
  [key: string]: IQueryContent | User ;
};

export enum RESPONSE_BY {
  USER = 'user',
  OPENAI = 'OpenAI',
  STRATEGY_EDGE = 'SEResponse',
  EMBROYNIC = 'Emb',
  EMBAI = 'EmbOpenAI',
  SURVEY = 'SurveyInsights',
  SECTOR = 'SectorInsights',
  URL = 'uploadURL',
  EXPERTS = 'Experts',
  PESPECTIVE= "Perspective",
  MARKET_REPORT = "MarketReports",
}
export interface ISurveyList {
  files: [
    {
      title?: string;
      surveydate?: string;
      sectors?: string[];
    }
  ];
  sectors: string[];
}

export interface User {
  summary: string;
  responseType?:string
}

export type ItableCntentProps = {
  val: string | number;
  title: string | undefined | number;
};

export type Iauthor = {
  author_name: string;
  profile: string;
  author_email: string;
  role: string;
};

export interface User {
  summary: string;
}

export interface IAppConfig {
  baseApiUrl: string;
  activeDirectory: IActiveDirectoryConfig;
  azureMapsClientId: string;
}

export interface IActiveDirectoryConfig {
  tenant: string;
  clientId: string;
}
export interface IRelevantFiles {
  file_name: string;
  page_number: string[];
  authors: Iauthor[];
  sector?: string[];
  geography?: string[];
  contentAge: string;
  eyAEMID?: string;
  Project_End_Date?: any;
  Project_type?: any;
  Authors?: Iauthor[];
  source?: string;
}
export interface IAppConfig {
  baseApiUrl: string;
  activeDirectory: IActiveDirectoryConfig;
  azureMapsClientId: string;
}

export interface IActiveDirectoryConfig {
  tenant: string;
  clientId: string;
}
export interface IRelevantFiles {
  file_name: string;
  page_number: string[];
  authors: Iauthor[];
}
export interface IRelevantFilesEngagement {
  Geography?: string[];
  Project_End_Date?: string;
  Project_type?: string;
  Authors: {
    author_name: string;
    role: string;
  }[];
}
