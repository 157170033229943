import React, { type ReactElement, type ReactNode, type ReactChild, Fragment } from 'react';
import ReactModal, { type Styles } from 'react-modal';
import styled from 'styled-components';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';
import sassVars from '../../../styles/variables.module.scss';
import { ModalType } from '../../../models/ModalType';
const { alternateblack, gray40, white} = sassVars;
export interface IOwnProps {
  isOpen: boolean;
  type: ModalType;
  width?: number;
  children: ReactNode;
  headerTitle: string;
  actionBtnEnabled: boolean;
  actionBtnText?: string;
  cancelBtnText?: string;
  onActionBtnClicked?: () => void;
  onCancelBtnClicked?: () => void;
  onCloseBtnClicked: () => void;
  onSkipBtnClicked?: () => void;
  hideCloseIcon?: boolean;
  isLoading?: boolean;
  showModalFooter?: boolean;
  showHeaderToolTip?: boolean;
  headerToolTip?: string;
  showHeaderIcon?: boolean;
  headerIcon?: ReactChild;
  customClass?: string;
  footerClass?: string;
  customHeaderClass?: string;
  showHeaderTitleIcon?: boolean;
  headerTitleIcon?: string;
  souceChange?:boolean
  tabIndex?: number;
  teamsWebURL?: string;
  onTeamsChannelClicked?: () => void;
  onChangeCheckBox?: (e: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => void;
}

const getPortalStyles = (width: number): Styles => {
  const obj: Styles = {
    content: {
      backgroundColor: `${white}`,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: `${width}px`,
      boxShadow: '0 0 30px 0 rgba(0,0,0,0.7)',
      right: 'auto',
      bottom: 'auto',
      padding: 0,
      outline: 'none'
    },
    overlay: {
      backgroundColor: 'rgba(26, 26, 36, 0.8)',
      zIndex: 100
    }
  };

  return obj;
};
const geSourceChnageModelStyles = (width: number): Styles => {
  const obj: Styles = {
    content: {
      backgroundColor: '#fff',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '540px',
      height:'300px',
      boxShadow: "0 0 30px 0 rgba(0,0,0,0.7)",
      right: 'auto',
      bottom: 'auto',
      border: `1px solid ${gray40}`,
      borderRadius: '8px',
      padding: 0
    },
    overlay: {
      backgroundColor: 'rgba(26, 26, 36, 0.8)',
      zIndex: 100
    }
  };

  return obj;
};

const Modal: React.FC<IOwnProps> = ({
  isOpen,
  type,
  width = window.innerWidth * 0.3,
  children,
  headerTitle,
  actionBtnEnabled,
  actionBtnText = 'Create',
  cancelBtnText = 'Cancel',
  onActionBtnClicked,
  onCancelBtnClicked,
  onCloseBtnClicked,
  onSkipBtnClicked,
  hideCloseIcon,
  isLoading,
  showModalFooter = true,
  showHeaderToolTip = false,
  headerToolTip = '',
  showHeaderIcon,
  headerIcon,
  customClass,
  footerClass = '',
  customHeaderClass = '',
  showHeaderTitleIcon = false,
  headerTitleIcon,
  souceChange,
  tabIndex,
  teamsWebURL,
  onTeamsChannelClicked,
  onChangeCheckBox
}: IOwnProps): ReactElement => {
  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      style={
        type == ModalType.SourceChange ? geSourceChnageModelStyles(width) : getPortalStyles(width)
      }
      className={`se-react-model se-model-${type} ${customClass ?? ''}`}>
      <ModalHeader
        headerTitle={headerTitle}
        onCloseBtnClicked={ souceChange ? onActionBtnClicked : onCloseBtnClicked }
        hideCloseIcon={hideCloseIcon}
        showHeaderToolTip={showHeaderToolTip}
        headerToolTip={showHeaderToolTip ? headerToolTip : ''}
        showHeaderIcon={showHeaderIcon}
        headerIcon={showHeaderIcon === true ? headerIcon : <Fragment></Fragment>}
        customHeaderClass={customHeaderClass}
        showHeaderTitleIcon={showHeaderTitleIcon}
        headerTitleIcon={showHeaderTitleIcon ? headerTitleIcon : ''}
        souceChange={souceChange}
        isLoading={isLoading}
        type={type}
      />

      <StyledModalContentWrapper>
        {children}
        {showModalFooter && (
          <ModalFooter
            type={type}
            actionBtnEnabled={actionBtnEnabled}
            actionBtnText={actionBtnText}
            cancelBtnText={cancelBtnText}
            onActionBtnClicked={onActionBtnClicked}
            onCancelBtnClicked={onCancelBtnClicked}
            onCloseBtnClicked={onCloseBtnClicked}
            onSkipBtnClicked={onSkipBtnClicked}
            onTeamsChannelClicked={onTeamsChannelClicked}
            isLoading={isLoading}
            footerClass={footerClass}
            tabIndex={tabIndex}
            teamsWebURL={teamsWebURL}
            onChangeCheckBox={(e: React.SyntheticEvent<EventTarget>) => onChangeCheckBox?.({ target: { checked: (e.target as HTMLInputElement).checked } })}
          />
        )}
      </StyledModalContentWrapper>
    </ReactModal>
  );
};

Modal.displayName = 'Modal';

const StyledModalContentWrapper = styled.div`
  max-height: calc(90vh - 100px);
  overflow-y: auto;
  word-wrap: break-word;
 
`;

export default Modal;
