import { FC, useCallback, memo } from 'react';
import Checkbox from './Checkbox';
import './CheckboxTreeNode.scss';
import { MotifTreeNode } from '@ey-xd/motif-react';

export type CheckboxTreeNodeProps = {
  data: any;
  hierarchy?: string;
  ind?: any;
  index: number;
  onChange: (node: any, isChecked: boolean) => void;
};

const CheckboxTreeNode: FC<CheckboxTreeNodeProps> = ({
  data,
  hierarchy = '',
  ind,
  index,
  onChange
}: CheckboxTreeNodeProps) => {
  const path: any = hierarchy ? `${hierarchy}.${ind}` : `${index}`;
  const currentIndex = '';
  const nodeName = data.folder
    ? `${currentIndex} ${data.folder}`
    : `${currentIndex} ${data.subFolder}`;
  const uniqueKey = hierarchy ? `${hierarchy}-${data.key}` : data.key.toString();

  const handleCheckBoxSelection = useCallback(
    (isChecked: boolean) => {
      onChange({ ...data, path }, isChecked);
    },
    [data, onChange, path]
  );

  const hasChildren = data?.subfolder || data?.subfolder?.length > 0;

  return (
    <MotifTreeNode
      key={uniqueKey}
      className={`tree-node ${data?.subfolder?.length>0 ? 'with-child' : 'with-out-child'}`}
      value={
        <Checkbox
          id={`checkbox-${uniqueKey}`}
          value={uniqueKey}
          name={data.folder}
          className={`tree-node__checkbox ${!hasChildren ? 'tree-node__leaf' : ''}`}
          onChange={(e) => handleCheckBoxSelection(e.target.checked)}
          checked={data.checked}>
          {nodeName}
        </Checkbox>
      }>
      {hasChildren &&
        data.subfolder.map((childData: any, indx: any) => (
          <CheckboxTreeNode
            index={index}
            key={`${uniqueKey}-${index}`}
            data={childData}
            ind={indx}
            onChange={onChange}
            hierarchy={path}
          />
        ))}
    </MotifTreeNode>
  );
};

export default memo(CheckboxTreeNode);
