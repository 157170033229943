import React, { useState, Fragment, useEffect, useRef } from 'react';
import styled from 'styled-components';
import scssVar from '../../../styles/variables.module.scss';
import { ModalType } from '../../../models/ModalType';
import Modal from '../modal/Modal';
import { MY_JUNIOR_PORTAL_URL } from '../../../app.constant';
const { lightgray, myJuniorTermsWidth, myJuniorPrivacyWidth, grey50 } = scssVar;

interface IOwnProps {
  show: boolean;
  onClose: () => void;
  headerIcon: React.ReactChild;
}

const MyJuniorModal: React.FC<IOwnProps> = ({ show, onClose, headerIcon }: IOwnProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [actionBtnEnabled, setActionButtonEnabled] = useState<true | false>(false);
  const onCloseButtonClick = () => {
    setActionButtonEnabled(false);
    setCurrentPage(1);
    onClose();
  };

  const handleAcknowledgement = () => {
    if (currentPage === 1) setCurrentPage(2);
    if (currentPage === 2) {
      onCloseButtonClick();
      window.open(MY_JUNIOR_PORTAL_URL, '_blank');
    }
  };
  const handleCheck = (e: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
    setActionButtonEnabled(e.target.checked);
  };

  return (
    <Modal
      isOpen={show}
      actionBtnEnabled={actionBtnEnabled}
      onActionBtnClicked={() => {
        handleAcknowledgement();
      }}
      onCloseBtnClicked={() => onCloseButtonClick()}
      cancelBtnText={'Cancel'}
      onCancelBtnClicked={() => onCloseButtonClick()}
      headerIcon={headerIcon}
      headerTitle={
        currentPage == 1
          ? 'Terms of Use for MyJunior.AI (External Application)'
          : 'Privacy Notice - MyJunior.AI'
      }
      width={currentPage == 1 ? +myJuniorTermsWidth : +myJuniorPrivacyWidth}
      tabIndex={currentPage}
      customHeaderClass='myjunior-header'
      showHeaderIcon={true}
      type={ModalType.MyJuniorAI}
      onChangeCheckBox={handleCheck}>
      <StyledMyJuniorModal currentPage={currentPage}>
        {
          <div className='myjunior-modal-container'>
            <Fragment>
              {currentPage == 1 ? (
                <>
                  <p>By proceeding, you acknowledge and agree to the following terms: </p>
                  <p>
                    <b>1. Approved Usage</b>
                    <div className='myjunior-content approved-usage'>
                      <p>
                        The application is strictly for use in external expert or customer
                        referencing calls. It must not be used for internal meetings or discussions.
                      </p>
                    </div>
                  </p>
                  <p>
                    <b>2. Permissible User Regions:</b>
                    <br></br>
                    <div className='myjunior-content'>
                      The MyJunior.AI application is approved for use only in the following
                      countries:
                      <div className='countries-list'>
                        <span className='triangle-list'>
                          <p className='triangle-right'></p>{' '}
                          <span className='list-item'> United States (US) </span>
                        </span>
                        <span className='triangle-list'>
                          <p className='triangle-right'></p>{' '}
                          <span className='list-item'> United Kingdom (UK) </span>
                        </span>
                        <span className='triangle-list'>
                          <p className='triangle-right'></p>{' '}
                          <span className='list-item'> Germany </span>
                        </span>
                        <span className='triangle-list'>
                          <p className='triangle-right'></p>{' '}
                          <span className='list-item'> France </span>
                        </span>
                        <span className='triangle-list'>
                          <p className='triangle-right'></p>{' '}
                          <span className='list-item'> Netherlands </span>
                        </span>
                        <span className='triangle-list'>
                          <p className='triangle-right'></p>{' '}
                          <span className='list-item'> Nordics </span>
                        </span>
                        <span className='triangle-list'>
                          <p className='triangle-right'></p>{' '}
                          <span className='list-item'> Australia </span>
                        </span>
                      </div>
                    </div>
                  </p>
                  <p>
                    <p className='continue-text'>
                      By continuing, you confirm that you meet these conditions and agree to comply
                      with the stated terms.
                    </p>
                  </p>
                </>
              ) : (
                <>
                  <div>
                    <b>1. Introduction </b>
                    <br></br>
                    <p>
                      This Privacy Notice is intended to describe the practices EY follows in
                      relation to the Junior.ai (“Tool”) with respect to the privacy of all
                      individuals whose personal data is processed and stored in the Tool. This
                      Privacy Notice should be read together with the{' '}
                      <a href='https://www.ey.com/en_gl/privacy-statement' target='_blank'>
                        ey.com Privacy Statement
                      </a>
                      , and in case of any conflict with the{' '}
                      <a href='https://www.ey.com/en_gl/privacy-statement' target='_blank'>
                        ey.com Privacy Statement
                      </a>
                      , the terms of this Privacy Notice will prevail. Please read this Privacy
                      Notice carefully.
                    </p>
                  </div>
                  <div>
                    <b>2. Who manages the Tool?</b>
                    <br></br>
                    <p>
                      “EY” refers to one or more of the member firms of Ernst & Young Global Limited
                      (“EYG”) each of which is a separate legal entity and can determine the
                      purposes and means for data processing in its own right (i.e., act as a data
                      controller or in a similar capacity).
                    </p>
                    <p>
                      The entity that is acting as data controller (or similar capacity) by
                      providing this Tool on which your personal data will be processed and stored
                      is:
                    </p>
                    <ul>
                      <li>
                        For the personal data of EY personnel: The data controller is the EY entity
                        which employs you.
                      </li>
                      <li>
                        For the personal data of third-party personnel (including EY clients): The
                        data controller is the EY local member firm with which the third party has a
                        relationship.
                      </li>
                    </ul>
                    <p>
                      You can find a list of local EY member firms and affiliates on the{' '}
                      <a href='https://www.ey.com/en_gl/privacy-statement' target='_blank'>
                        ey.com Privacy Statement
                      </a>
                      <br></br>
                      <p>
                        The personal data in the Tool is shared by the above data controller with
                        one or more member firms of EYG (see “Who can access your personal data”
                        section 6 below).
                      </p>
                      <p>
                        The Tool is hosted on external servers of the vendor´s third-party hosting
                        provider. Particularly, the Tool is hosted in AWS EAST datacenter located in
                        the United States.
                      </p>
                    </p>
                  </div>
                  <div>
                    <b>3. How does the Tool process personal data? </b>
                    <br></br>
                    <p>
                      The Tool is a solution used by EY users to manage their primary research
                      activities. In this sense, EY users will upload interview transcripts or audio
                      recordings to the Tool; these transcripts or recordings are originally
                      provided from expert network vendors which provide EY with access to vetted
                      experts for the purpose of market research. EY is also involved and conducts
                      the interviews with the experts. EY users will use the Tool to query this
                      unstructured text data in real-time. The tool includes an AI “chat”
                      functionality allowing users to interrogate the transcripts available in the
                      engagement workspace.
                    </p>
                    <p>
                      The Tool processes personal data of EY users to provide access/log in to the
                      system. Also, it will process personal data to identify which participant in
                      the market ecosystem has provided which answers.
                    </p>
                    <p>
                      Your data processed in the Tool is used as follows: to identify the expert
                      being interviewed and to provide access to the tool by the EY practitioner.
                    </p>
                    <p>
                      EY relies on the following basis to legitimize the processing of your personal
                      data in the Tool:{' '}
                    </p>
                    <p>
                      Processing of your personal data is necessary for the purposes of the
                      legitimate interests pursued by the data controller or by a third party,
                      except where such interests are overridden by the interests or fundamental
                      rights and freedoms of the data subject which require protection of personal
                      data. The specific legitimate interest(s) are to identify EY users as being a
                      part of the engagement team as each engagement team has its own secured
                      project workspace.
                    </p>
                    <p>
                      You have the right to object at any time, on grounds relating to your
                      particular situation, to the processing of personal data concerning you based
                      on the above legitimate interest(s).
                    </p>
                  </div>
                  <div>
                    <b>4. What type of personal data is processed in the Tool? </b>
                    <br></br>
                    <p>The Tool processes these personal data categories: </p>
                    <u>For EY individuals: </u>
                    <ul>
                      <li>First name</li>
                      <li>Last name</li>
                      <li>Email address</li>
                    </ul>
                    <u>For Market Experts: </u>
                    <ul>
                      <li>First name</li>
                      <li>Last name</li>
                      <li>Job title</li>
                      <li>Employer</li>
                    </ul>
                    <p>
                      This data is sourced from: Provided directly by EY Partners, employees or
                      contractors; and provided by other parties.
                    </p>
                  </div>
                  <div>
                    <b>5. Sensitive personal data</b>
                    <br></br>
                    <p>
                      Sensitive personal data reveals your racial or ethnic origin, political
                      opinions, religious or philosophical beliefs, trade union membership, genetic
                      data, biometric data, data concerning health or data concerning sex life or
                      sexual orientation.
                    </p>
                    <p>
                      EY does not intentionally collect any sensitive personal data from you via the
                      Tool. The Tool’s intention is not to process such information.
                    </p>
                  </div>
                  <div>
                    <b>6. Who can access your personal data</b>
                    <br></br>
                    <p>
                      Your personal data is accessed in the Tool by the following persons/teams:
                    </p>
                    <p>
                      EY Case Team (L1-L4) and EY individuals (EY Partners and Employees). Admin
                      users from Junior AI will also be able to access the data.
                    </p>
                    <br></br>
                    <table className='myjunior-table'>
                      <thead>
                        <tr>
                          <td>USER GROUP</td>
                          <td>LOCATION</td>
                          <td>PURPOSE</td>
                          <td>ACCESS</td>
                          <td>AMOUNT</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>EY USers</td>
                          <td>Global</td>
                          <td>Interviewer, upload transcript, analyze research</td>
                          <td>ALL: Read, Update, Add</td>
                          <td>Short term: &lt;1000 Longer term: &lt;5000</td>
                        </tr>
                        <tr>
                          <td>Junior AI (Admin)</td>
                          <td>US</td>
                          <td>To allow access and process the project</td>
                          <td>ALL: Read, Update, Add</td>
                          <td>2-5</td>
                        </tr>
                      </tbody>
                    </table>
                    <br></br>
                    <p>
                      The access rights detailed above involves transferring personal data in
                      various jurisdictions (including jurisdictions outside the European Union) in
                      which EY operates (EY office locations are listed at {''}
                      <a href='https://www.ey.com/en_gl/locations' target='_blank'>
                        https://www.ey.com/en_gl/locations
                      </a>
                      ). An overview of EY network entities providing services to external clients
                      is accessible{' '}
                      <a href='https://www.ey.com/en_uk/legal-statement' target='_blank'>
                        here
                      </a>{' '}
                      (See Section 2 (About EY) - “View a list of EY member firms and affiliates”).
                      EY will process your personal data in the Tool in accordance with applicable
                      law and professional regulations in your jurisdiction. Transfers of personal
                      data within the EY network are governed by EY’s{' '}
                      <a
                        href='https://www.ey.com/en_gl/data-protection-binding-corporate-rules-program'
                        target='_blank'>
                        Binding Corporate Rules.
                      </a>
                    </p>
                    <p>
                      We transfer or disclose the personal data we collect to third-party service
                      providers (and their subsidiaries and affiliates) who are engaged by us to
                      support our internal ancillary processes. For example, we engage service
                      providers to provide, run and support our IT infrastructure (such as identity
                      management, hosting, data analysis, back-up, security and cloud storage
                      services) and for the storage and secure disposal of our hard copy files. It
                      is our policy to only use third-party service providers that are bound to
                      maintain appropriate levels of data protection, security and confidentiality,
                      and that comply with any applicable legal requirements for transferring
                      personal data outside the jurisdiction in which it was originally collected.{' '}
                    </p>
                    <p>
                      To the extent that personal data has been rendered anonymous in such a way
                      that you or your device are no longer reasonably identifiable, such
                      information will be treated as non-personal data and the terms of this Privacy
                      Notice will not apply.
                    </p>
                    <p>
                      For data collected in the European Economic Area (EEA) or which relates to
                      individuals in the EEA, EY requires an appropriate transfer mechanism as
                      necessary to comply with applicable law. The transfer of personal data from
                      the Tool to SAMO C, Inc. is governed by an agreement between EY and SAMO C,
                      Inc. that includes standard data protection clauses adopted by the European
                      Commission.
                    </p>
                  </div>
                  <div>
                    <b>7. Data retention</b>
                    <br></br>
                    <p>
                      Our policy is to retain personal data only for as long as it is needed for the
                      purposes described in the section “How does the Tool process personal data”.
                      Retention periods vary in different jurisdictions and are set in accordance
                      with local regulatory and professional retention requirements.
                    </p>
                    <p>
                      In order to meet our professional and legal requirements, to establish,
                      exercise or defend our legal rights and for archiving and historical purposes,
                      we need to retain information for significant periods of time.
                    </p>
                    <p>
                      The policies and/or procedures for the retention of personal data in the Tool
                      are:{' '}
                    </p>
                    <p>
                      The total retention period is defined and will be implemented in accordance
                      with the{' '}
                      <a
                        href='https://find.ey.net/discover/sitepages/home.aspx?detailPath=ZYFCSFS674FF-4-3034#detailPath=ZYFCSFS674FF-4-3034'
                        target='_blank'>
                        EY Records Retention Global Policy
                      </a>{' '}
                      and the relevant{' '}
                      <a href='Country Retention Schedule (CRS)' target='_blank'>
                        Country Retention Schedule (CRS)
                      </a>
                      .{' '}
                    </p>
                    <p>
                      Log Data will be retained in accordance with the{' '}
                      <a
                        href='https://sites.ey.com/sites/informationsecurity/Policy Library/Logging_Policy.pdf'
                        target='_blank'>
                        EY IT Logging Policy
                      </a>
                      . After the end of the data retention period, your personal data will be
                      deleted.
                    </p>
                  </div>
                  <div>
                    <b>8. Security</b>
                    <br></br>
                    <p>
                      EY protects the confidentiality and security of information it obtains in the
                      course of its business. Access to such information is limited, and policies
                      and procedures are in place that are designed to safeguard the information
                      from loss, misuse and improper disclosure. Additional information regarding
                      our approach to data protection and information security is available in our{' '}
                      <a
                        href='https://eur01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fassets.ey.com%2Fcontent%2Fdam%2Fey-sites%2Fey-com%2Fen_gl%2Ftopics%2Fconsulting%2Fey-protecting-your-data-brochure-v2.pdf&data=05%7C01%7CEvangelia.Mavrovounioti%40uk.ey.com%7C1a4a52e2631542f349d908db4bc8f0c2%7C5b973f9977df4bebb27daa0c70b8482c%7C0%7C0%7C638187098628892489%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=4aVx9v7vi%2FC%2Fw7zZjni1F89TCu6LSbe8hAPrfgxxMNA%3D&reserved=0'
                        target='_blank'>
                        ey-protecting-your-data-brochure-v2.pdf
                      </a>{' '}
                      brochure.
                    </p>
                  </div>
                  <div>
                    <b>9. Controlling your personal data</b>
                    <br></br>
                    <p>
                      EY will not transfer your personal data to third parties (other than any
                      external parties referred to in section 6 above) unless we have your
                      permission or are required by law to do so.
                    </p>
                  </div>
                  <div>
                    <b> 10. Your rights in relation to your personal data</b>
                    <br></br>
                    <p>
                      Depending on the applicable jurisdiction, you may have certain rights in
                      relation to your personal data, including:
                    </p>
                    <ul>
                      <li>
                        To request details of the personal data EY processes about you and to access
                        the personal data that EY processes about you
                      </li>
                      <li>
                        To have your personal data corrected, for example, if it is incomplete or
                        incorrect
                      </li>
                      <li>
                        To restrict or object to the processing of personal data or request the
                        erasure of your personal data{' '}
                      </li>
                      <li>
                        To receive a copy of the personal data which you have provided to EY in a
                        structured, commonly used and machine-readable format which you can re-use
                        for your own purposes (known as “data portability”)
                      </li>
                      <li>
                        Where you have provided consent to the processing of your personal data, the
                        right to withdraw your consent.
                      </li>
                      <li>
                        The right to complain to a data protection authority (see section
                        “Complaints”)
                      </li>
                    </ul>
                    <p>
                      If you have any questions about how EY processes your personal data or your
                      rights related to your personal data, please send an e-mail to{' '}
                      <a href='mailto:global.data.protection@ey.com'>
                        global.data.protection@ey.com.
                      </a>
                    </p>
                  </div>
                  <div>
                    <b>11. Compliants</b>
                    <br></br>
                    <p>
                      If you are concerned about an alleged breach of privacy law or any other
                      regulation, contact EY’s Global Privacy Leader, Office of the General Counsel,
                      1 More London Place, London, SE1 2AF, United Kingdom or via email at
                      global.data.protection@ey.com or via your usual EY representative. An EY
                      Privacy Leader will investigate your complaint and provide information about
                      how it will be handled and resolved.
                    </p>
                    <p>
                      If you are not satisfied with how EY resolved your complaint, you may have the
                      right to complain to your country’s data protection authority. You may also
                      have the right to refer the matter to a court of competent jurisdiction.{' '}
                    </p>
                    <p>
                      Certain EY member firms in countries outside the European Union (EU) and the
                      UK have appointed representatives in the EU and the UK respectively to act on
                      their behalf if, and when, they undertake data processing activities to which
                      the EU General Data Protection Regulation (GDPR) and/or the UK General Data
                      Protection Regulation (UK GDPR) applies. Further information and the contact
                      details of these representatives are available below:{' '}
                    </p>
                    <p>
                      <a
                        href='https://www.ey.com/en_gl/data-protection-representative'
                        target='_blank'>
                        EU data protection representative | EY
                      </a>
                    </p>
                    <p>
                      <a
                        href='https://www.ey.com/en_rs/uk-data-protection-representative'
                        target='_blank'>
                        UK Data protection representative (ey.com)
                      </a>
                    </p>
                  </div>
                  <div>
                    <b>12. Contact us</b>
                    <p>
                      If you have additional questions or concerns, contact your usual EY
                      representative or email{' '}
                      <a href='mailto:global.data.protection@ey.com'>
                        global.data.protection@ey.com.
                      </a>
                    </p>
                    <br></br>
                  </div>
                </>
              )}
            </Fragment>
          </div>
        }
      </StyledMyJuniorModal>
    </Modal>
  );
};
const StyledMyJuniorModal = styled.div<{
  currentPage?: number;
}>`
  min-height: 410px;
  max-height: auto;
  margin-bottom: ${(props) => (props.currentPage == 1 ? '3%' : '8%')};
  justify-content: space-around;
  color: ${lightgray};
  * {
    font-family: ${scssVar.fontInterstate} !important;
    font-size: ${'14px'} !important;
    color: #4d4d5c;
  }
  .myjunior-modal-container {
    margin: ${(props) => (props.currentPage == 1 ? '0% 3%' : '0% 2%')};
  }
  .myjunior-content {
    margin-left: 3%;
  }
  .myjunior-table {
    width: 100%;
    border-collapse: collapse;
  }
  .myjunior-table th,
  .myjunior-table td {
    border: 1px solid #000;
    padding: 8px;
  }
  .myjunior-table th {
    text-align: center;
  }
  .myjunior-table td {
    text-align: left;
  }
  .myjunior-table thead td {
    font-weight: bold;
  }
  .myjunior-table th tr td {
  }
  .triangle-list {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .triangle-right {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 10px solid #555;
    border-bottom: 5px solid transparent;
  }
  .list-item {
    padding-left: 1%;
  }
  .countries-list {
    margin-top: 10px;
  }
  .approved-usage {
    margin-top: -1%;
  }
  .continue-text {
    padding-bottom: 8%;
  }
`;
export default React.memo(MyJuniorModal);
