import React, { useEffect, useState } from 'react';
import fetchIntercept from 'fetch-intercept';
import { Guid as guid } from 'guid-typescript';
import PageLayout from './layout/pageLayout/PageLayout';
import AppStoreComponent from './components/appStoreComponent/AppStoreComponent';
import { useSelector,useDispatch } from 'react-redux';
import { IStore } from './store';
import { fetchDataSources, fetchUserApps } from './services/userProfile.service';
import Footer from './components/common/footer/Footer';
import DashboardContextProvider from './context/dashboardContext';
import { getCurrentSessionId } from './services/session.service';
import { X_TENANT_ID } from './app.constant';
import { internalSources, publicSources } from './label.constant';
import ErrorBoundary from './components/common/errorBoundary/ErrorBoundary';
import PolicyStepper from './components/common/policy/PolicyStepper';
import UnAuthorizedAccess from './components/unAuthorizedAccess/UnAuthorizedAccess';
import { useNavigate } from 'react-router-dom';
import { DigitalData } from './utils/adobeAnalytics/models/digitalDataModel';
import { digitaldata } from './utils/adobeAnalytics/config';
import { executeAnalytics, getLastUri } from './utils/adobeAnalytics/adobeAnalyticsHelperFn';
import { isTeamsApp } from './utils/commonUtils';
import { selectedSourceValues } from './store/actions/commonActions';


declare global { 
  interface Window { 
    digitaldata: DigitalData; 
  } 
} 

if (!window.digitaldata) { 
  window.digitaldata = digitaldata; 
} 
let userId: string;
function App(): JSX.Element {
  const dispatch = useDispatch();
  const showPrivacyAndTermsModal = useSelector(
    (store: IStore) => store.common.showPrivacyAndTermsModal
  );
  const IsDnfOpenAiSearchTriggered = useSelector((state: IStore) => state.common?.triggerDnfOpenAiSearchValue);
  if(IsDnfOpenAiSearchTriggered) {
    dispatch(selectedSourceValues({ checkedCheckboxes: ['Open AI']}))
  }

  const showUnauthorizedAccess = useSelector(
    (store: IStore) => store.common.showUnauthorizedAccess
  );
  const userName = useSelector((state: IStore) => state.userProfile?.userData?.displayName);
  const isFavouriteStatus = useSelector((state: IStore) => state.common?.isFavouriteStatus);
  const [customError, setCustomError] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [userApps, setUserApps] = useState<any>({});
  const [isTeamsSourceApp, setIsTeamsSourceApp] = useState<boolean>(isTeamsApp());

  const groupDataSources = (dataSources: any) => {
    const internalDataSources = dataSources?.filter((dataSource: string) => internalSources.includes(dataSource));
    const publicDataSources = dataSources?.filter((dataSource: string) => publicSources.includes(dataSource));
 
    const prioritizedInternalDataSources = internalDataSources?.sort((a: string, b: string) => {
      if (a === "EY-P Strategy projects") return -1;
      if (b === "EY-P Strategy projects") return 1;
      return 0;
    });
 
    const groupedDataSources: any = {
      public: publicDataSources,
      internal: prioritizedInternalDataSources
    };

    if (dataSources?.includes('Ey expert')) {
      groupedDataSources.findEyExpert = dataSources.findEyExpert;
    }

    if (dataSources?.includes('Upload document and query')) {
      groupedDataSources.uploadDocument = dataSources.uploadDocument;
    }
  
    // if (dataSources.includes('Upload URL and query')) {
    //   groupedDataSources.uploadUrl = dataSources.uploadUrl;
    // }
  
    return groupedDataSources;
  };

  const [allDataSources, setDataSources] = useState<any>({});
  const [errorCode, setErrorCode] = useState<any>(null);

  const getDataSources = async () => {
    setIsLoading(true);
    let result: any;
    result = await fetchDataSources();
    const groupedDataSources = groupDataSources(result?.dataSources);
    setUserApps(result.apps.filter((app: any) => app.name !== 'Market reports'));
    setDataSources(groupedDataSources);
    setIsLoading(false);
  };

  useEffect(() => {
    getDataSources();
    let prevPageLastUri = getLastUri(window.location.href);
    executeAnalytics({ page: { pageName: prevPageLastUri } });
    const timer = setInterval(() => {
      const lastUri = getLastUri(window.location.href);
      if (lastUri != prevPageLastUri) {
        executeAnalytics({ page: { pageName: lastUri } });
        prevPageLastUri = lastUri;
      }
    }, 500);
    return () => clearInterval(timer);
  }, [isFavouriteStatus,isTeamsSourceApp]);

  const sessionId = getCurrentSessionId();

  fetchIntercept.register({
    request: function (url: any, config: { headers: any }) {
      if (!config.headers) {
        config.headers = {};
      }
      config!.headers['Request-Id'] = guid.create();
      config!.headers['X-Correlation-Id'] = guid.create();
      config!.headers['X-Window-Id'] = sessionId;
      config!.headers['X-Tenant-Id'] = X_TENANT_ID;
      config!.headers['session-id'] = sessionId;
      return [url, config];
    },

    requestError: function (error: any) {
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error);
    },

    response: function (response: any) {
      // Modify the reponse object
      return response;
    },

    responseError: function (error: any) {
      // Handle an fetch error
      return Promise.reject(error);
    }
  });

  const navigate = useNavigate();

  useEffect(() => {
    const navigationEntry = performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming;
      if (navigationEntry && navigationEntry.type === 'reload') {
      setTimeout(() => {
        navigate('/');
      }, 10);
    }
  }, []);

  return (
    <div className='App'>

      <>
        {!showPrivacyAndTermsModal && (
         showUnauthorizedAccess ? (
            <UnAuthorizedAccess />
          ) : (
            <ErrorBoundary>
              <DashboardContextProvider>
                <AppStoreComponent dataSources={allDataSources} userApps={userApps} isLoading={isLoading} />
              </DashboardContextProvider>
              <Footer />
            </ErrorBoundary>
          )
        )}
        {showPrivacyAndTermsModal && (
          <div className="page-layout se-container">
            <PolicyStepper />
          </div>
        )}
      </>
    </div>
  );

}

export default App;
