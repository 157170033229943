import apis from '../../src/services/api';
import { getAuthorizationToken, isTeamsApp, routeToLogin } from '../utils/commonUtils';
import { deleteMethod, get, post } from './api.service';
import { getCurrentSessionId } from './session.service';
import errorStatus from '../utils/ErrorStatus';
import { AxiosError } from 'axios';
import chatApiRoutes from './api';
import { customErrorStatus } from './userProfile.service';
import { HttpStatusCode } from '../ui-constants/base/enum';
import { IStore } from '../store';
import { useSelector } from 'react-redux';
import { DataSourceTypes } from '../models/IDataSourceTypes';
import { authProvider } from './msalConfig';

type IUserData = {
  displayName: string;
  email: string;
  isExternal: boolean;
  profileImage: string;
  sid: { preferredName: string; rawIdToken?: string };
  rawIdToken?: string;
};
export const getEmbroynicQueryResponse = async (
  query: string,
  userData: IUserData,
  sources: string[],
  controller?: AbortController
) => {
  const sessionId = getCurrentSessionId();
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({ query: query?.trim(), sources: sources }),
    headers: {
      Authorization: `Bearer ${await getAuthorizationToken()}`,
      'Session-Id': sessionId || '',
      'IsMSTeamsClient' : isTeamsApp() ? 'true' : 'false'
    },
    signal: controller && controller.signal ? controller.signal : null
  };
  return fetch(apis.sqlEgptPrompt, { ...requestOptions })
    .then((response) => {
      if (response.status === HttpStatusCode.Unauthorized) {
        routeToLogin();
      } else return response;
    })
    .catch((error) => {
      return handleFetchException(error);
    });
};

export const getUploadURLQueryResponse = async (
  query: string,
  userData: IUserData,
  sources: string[],
  validURLinputValue: any
) => {
  const sessionId = getCurrentSessionId();
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({ query: query, urls: validURLinputValue }),
    headers: {
      Authorization: `Bearer ${await getAuthorizationToken()}`,
      'Session-Id': sessionId || '',
      'IsMSTeamsClient' : isTeamsApp() ? 'true' : 'false'
    }
  };
  return fetch(apis.uploadURL, { ...requestOptions })
    .then((response) => {
      if (response.status === HttpStatusCode.Unauthorized) {
        routeToLogin();
      } else return response;
    })
    .catch((error) => {
      return handleFetchException(error);
    });
};

export const getSectorsQueryResponse = async (summaryData: string, userData?: IUserData, controller?: AbortController) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Session-Id': getCurrentSessionId() || '',
      'jwt-token': userData?.rawIdToken || '',
      'user-email': userData?.displayName || '',
      'user-id': userData?.sid?.preferredName || '',
      Authorization: `Bearer ${await getAuthorizationToken()}`,
      'IsMSTeamsClient' : isTeamsApp() ? 'true' : 'false'
    },
    body: summaryData,
    signal: controller && controller.signal ? controller.signal : null
  };
  return fetch(apis.queryResponseSector, { ...requestOptions })
    .then((response) => {
      if (response.status === HttpStatusCode.Unauthorized) {
        routeToLogin();
      } else return response;
    })
    .catch((error) => {
      return handleFetchException(error);
    });
};

export const getMRQueryResponse = async (summaryData: string, userData?: IUserData, controller?: AbortController) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Session-Id': getCurrentSessionId() || '',
      'jwt-token': userData?.rawIdToken || '',
      'user-email': userData?.displayName || '',
      'user-id': userData?.sid?.preferredName || '',
      Authorization: `Bearer ${await getAuthorizationToken()}`,
      'IsMSTeamsClient' : isTeamsApp() ? 'true' : 'false'
    },
    body: summaryData,
    signal: controller && controller.signal ? controller.signal : null
  };
  return fetch(apis.queryResponseMarketReports, { ...requestOptions })
    .then((response) => {
      if (response.status === HttpStatusCode.Unauthorized) {
        routeToLogin();
      } else return response;
    })
    .catch((error) => {
      return handleFetchException(error);
    });
};

export const sectorsDownloadSlide = async (docData: string, userData?: IUserData) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'session-id': getCurrentSessionId() || '',
      'jwt-token': userData?.rawIdToken || '',
      'user-email': userData?.displayName || '',
      'user-id': userData?.sid?.preferredName || '',
      Authorization: `Bearer ${await getAuthorizationToken()}`,
      'IsMSTeamsClient' : isTeamsApp() ? 'true' : 'false'
    },
    body: docData
  };
  return fetch(apis.downloadSectorDoc, { ...requestOptions }).catch((error) => {
    return handleFetchException(error);
  });
};

export const getSectorsHierarchy = async (userData?: IUserData) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'session-id': getCurrentSessionId() || '',
      'jwt-token': userData?.rawIdToken || '',
      'user-email': userData?.displayName || '',
      'user-id': userData?.sid?.preferredName || '',
      Authorization: `Bearer ${await getAuthorizationToken()}`,
      'IsMSTeamsClient' : isTeamsApp() ? 'true' : 'false'
    }
  };
  return fetch(apis.sectorGeography, { ...requestOptions });
};
export const getMRSectorsHierarchy = async (userData?: IUserData) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'session-id': getCurrentSessionId() || '',
      'jwt-token': userData?.rawIdToken || '',
      'user-email': userData?.displayName || '',
      'user-id': userData?.sid?.preferredName || '',
      Authorization: `Bearer ${await getAuthorizationToken()}`,
      'IsMSTeamsClient' : isTeamsApp() ? 'true' : 'false'
    }
  };
  return fetch(apis.marketREportsSectorGeography, { ...requestOptions });
};
export const getExpertsHierarchy = async (userData?: IUserData) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'session-id': getCurrentSessionId() || '',
      'jwt-token': userData?.rawIdToken || '',
      'user-email': userData?.displayName || '',
      'user-id': userData?.sid?.preferredName || '',
      Authorization: `Bearer ${await getAuthorizationToken()}`,
      'IsMSTeamsClient' : isTeamsApp() ? 'true' : 'false'
    }
  };
  return fetch(apis.expertsHirarchy, { ...requestOptions });
};
export const getChatThreadHistory = async (): Promise<any> => {
  try {
    const data = await get(chatApiRoutes.chatThreadHistory);
    return data;
  } catch (error: any) {
    if (!error?.response?.status) return customErrorStatus(error?.message);
    return errorStatus(error?.response?.status);
  }
};

export const getChatThreadConversations = async (threadId: number): Promise<any> => {
  try {
    const data = await get(chatApiRoutes.chatConversations, { threadId: threadId });
    return data;
  } catch (error: any) {
    if (!error?.response?.status) return customErrorStatus(error?.message);
    return errorStatus(error?.response?.status);
  }
};

export const updateChatThreadTitle = async (threadId: number, title: string): Promise<any> => {
  try {
    const data = await post(chatApiRoutes.updateChatThreadTitle, {
      threadId: threadId,
      title: title
    });
    return { status: HttpStatusCode.Success, data: data };
  } catch (error: any) {
    if (!error?.response?.status) return customErrorStatus(error?.message);
    return errorStatus(error?.response?.status);
  }
};

export const updateDocThreadTitle = async (
  threadId: number,
  title: string,
  userEmail: string
): Promise<any> => {
  try {
    const data = await post(chatApiRoutes.createProject, {
      project_UUID: threadId,
      projectName: title,
      editFlag: 'Y',
      category: 'General',
      adminId: userEmail
    });
    return { status: HttpStatusCode.Success, data: data };
  } catch (error: any) {
    if (!error?.response?.status) return customErrorStatus(error?.message);
    return errorStatus(error?.response?.status);
  }
};

export const deleteChatThreadHistory = async (threadId: number): Promise<any> => {
  try {
    const data = await deleteMethod(chatApiRoutes.deleteChatThreadHistory, { threadId: threadId });
    return { status: HttpStatusCode.Success, data: data };
  } catch (error: any) {
    if (!error?.response?.status) return customErrorStatus(error?.message);
    return errorStatus(error?.response?.status);
  }
};

export const deleteDocThreadHistory = async (
  project_UUID: string,
  userData?: IUserData
): Promise<any> => {
  const requestOptions = {
    method: 'DELETE',
    body: JSON.stringify({ project_UUID: project_UUID }),
    headers: {
      'Content-Type': 'application/json',
      'Session-Id': getCurrentSessionId() || '',
      'jwt-token': userData?.rawIdToken || '',
      'user-email': userData?.displayName || '',
      'user-id': userData?.sid?.preferredName || '',
      Authorization: `Bearer ${await getAuthorizationToken()}`,
      'IsMSTeamsClient' : isTeamsApp() ? 'true' : 'false'
    }
  };
  return fetch(chatApiRoutes.deleteDocThreadHistory, { ...requestOptions }).catch((error) => {
    return handleFetchException(error);
  });
};

export const saveUserChatHistory = (reqBody: any): Promise<any> => {
  return post(chatApiRoutes.saveChatHistory, reqBody)
    .then((response) => {
      return response;
    })
    .catch((error: AxiosError) => {
      const { response } = error;
      if (
        response?.status === HttpStatusCode.BadRequest ||
        response?.status === HttpStatusCode.NotFound
      ) {
        return response;
      }
      return error;
    });
};

export const getDocumentThreadHistory = async (userEmail: string): Promise<any> => {
  try {
    const data = await post(chatApiRoutes.chatdocumentHistory, {
      email: userEmail
    });
    return data;
  } catch (error: any) {
    if (!error?.response?.status) return customErrorStatus(error?.message);
    return errorStatus(error?.response?.status);
  }
};

export const getSurveysQueryResponse = async (summaryData: string, controller: AbortController, userData?: IUserData) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Session-Id': getCurrentSessionId() || '',
      'jwt-token': userData?.rawIdToken || '',
      'user-email': userData?.displayName || '',
      'user-id': userData?.sid?.preferredName || '',
      Authorization: `Bearer ${await getAuthorizationToken()}`,
      'IsMSTeamsClient' : isTeamsApp() ? 'true' : 'false'
    },
    body: summaryData,
    signal: controller && controller.signal ? controller.signal : null
  };
  return fetch(apis.surveySummaryChunks, { ...requestOptions })
    .then((response) => {
      if (response.status === HttpStatusCode.Unauthorized) {
        routeToLogin();
      } else return response;
    })
    .catch((error) => {
      return handleFetchException(error);
    });
};
export const getSurveysListResponse = async (summaryData: string, userData?: IUserData) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'session-id': getCurrentSessionId() || '',
      'jwt-token': userData?.rawIdToken || '',
      'user-email': userData?.displayName || '',
      'user-id': userData?.sid?.preferredName || '',
      Authorization: `Bearer ${await getAuthorizationToken()}`,
      'IsMSTeamsClient' : isTeamsApp() ? 'true' : 'false'
    },
    body: summaryData
  };
  return fetch(apis.surveyLists, { ...requestOptions })
    .then((response) => {
      if (response.status === HttpStatusCode.Unauthorized) {
        routeToLogin();
      } else return response;
    })
    .catch((error) => {
      return handleFetchException(error);
    });
};

export const removeThreadFromCache = async (targetAPIURL: string, sessionId: string) => {
  return post(targetAPIURL, {}, sessionId)
    .then((response) => {
      return response;
    })
    .catch((error: AxiosError) => {
      return handleFetchException(error);
    });
};

export const handleFetchException = (error: any) => {
  if (error?.response?.status === HttpStatusCode.Unauthorized) {
    routeToLogin();
  } else return errorStatus(error?.response?.status);
};

export const getQueryResponseEngagement = async (summaryData: string, userData?: IUserData, controller?: AbortController) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Session-Id': getCurrentSessionId() || '',
      'jwt-token': userData?.rawIdToken || '',
      'user-email': userData?.displayName || '',
      'user-id': userData?.sid?.preferredName || '',
      Authorization: `Bearer ${await getAuthorizationToken()}`,
      'IsMSTeamsClient' : isTeamsApp() ? 'true' : 'false'
    },
    body: summaryData,
    signal: controller && controller.signal ? controller.signal : null
  };
  return fetch(apis.queryResponseExperts, { ...requestOptions })
    .then((response) => {
      if (response.status === HttpStatusCode.Unauthorized) {
        routeToLogin();
      } else return response;
    })
    .catch((error) => {
      return handleFetchException(error);
    });
};
export const getEyIpKcResponse = async (summaryData: string, userData?: IUserData, controller?: AbortController) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Session-Id': getCurrentSessionId() || '',
      'jwt-token': userData?.rawIdToken || '',
      'user-email': userData?.displayName || '',
      'user-id': userData?.sid?.preferredName || '',
      Authorization: `Bearer ${await getAuthorizationToken()}`
    },
    body: summaryData,
    signal: controller && controller.signal ? controller.signal : null
  };
  return fetch(apis.getEyIpKcResponse, { ...requestOptions })
    .then((response) => {
      if (response.status === HttpStatusCode.Unauthorized) {
        routeToLogin();
      } else return response;
    })
    .catch((error) => {
      return handleFetchException(error);
    });
};
export const saveDownloadChatActivity = async (activityType: string): Promise<any> => {
  try {
    const data = await post(chatApiRoutes.saveDownloadChatActivity, { activity: activityType, activityinfo: "" });
    return data;
  } catch (error: any) {
    if (!error?.response?.status) return customErrorStatus(error?.message);
    return errorStatus(error?.response?.status);
  }
};
