// import React from 'react';
import { internalSources, publicSources } from '../../../label.constant';
import { RESPONSE_BY } from '../../../models/IQueryType';
import { Guid } from 'guid-typescript';
// import { useDispatch, useSelector } from 'react-redux';
// import { IStore } from "../../../store";

// const eyIPInterRadio = useSelector((state: IStore) => state?.common?.eyIPInterRadio);
// const validURLinputValue = useSelector((state: IStore) => state?.common?.validURLinputValue);
// const checkedCheckboxes = useSelector((state: any) => state.common?.checkedCheckboxes);

export const charLimit = 1000;

export const sanitizeHeaderText = (header: string) => {
  return header.replace('company_', '').replace('_', ' ');
};

export const toPascalCase = (str: string) => {
  return str.replace(/\w+/g, function (w) {
    return w[0].toUpperCase() + w.slice(1).toLowerCase();
  });
};

const formatDate = (date: any) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${day}${month}${year}${hours}${minutes}${seconds}`;
};

export const downloadFilenameGenerator = (
  from: RESPONSE_BY | 'ALL',
  extension: 'ppt' | 'docx' | 'csv'
) => {
  const guid = Guid.create();
  const today = new Date();
  const formattedDate = formatDate(today);

  const source =
    // extension === "docx"
    //   ? "E-GPT"
    from === RESPONSE_BY.OPENAI
      ? 'OpenAI'
      : from === RESPONSE_BY.EMBROYNIC
        ? 'Emb'
        : from === RESPONSE_BY.SURVEY
          ? 'SurveyInsights'
          : from === RESPONSE_BY.SECTOR
            ? 'SectorInsights'
            : from === RESPONSE_BY.EMBAI
              ? 'EmbOpenAI'
              : from === RESPONSE_BY.URL
                ? 'URL'
                : from === RESPONSE_BY.EXPERTS
                  ? 'FindEYExpert'
                  : from === RESPONSE_BY.PESPECTIVE
                    ? 'Perspective'
                    : from === RESPONSE_BY.MARKET_REPORT
                      ? 'MarketReports'
                      : 'User';

  const fileName = `${source}-${
    extension === 'docx' ? 'conversation' : 'chat response'
  }-${formattedDate}.${extension}`;
  return fileName;
};

export const downloadFilenameGeneratorForPPT = (
  checkedCheckboxes: any,
  eyIPInterRadio: any,
  validURLinputValue: any,
  extension: 'ppt' | 'docx' | 'csv' | 'zip'
) => {
  const guid = Guid.create();
  const today = new Date();
  const formattedDate = formatDate(today);

  let sourceType = '';
  if (checkedCheckboxes && checkedCheckboxes.length > 0) {
    if (checkedCheckboxes.length === 1 && checkedCheckboxes.includes(publicSources[0]))
      sourceType = 'Emb';
    else if (checkedCheckboxes.length === 1 && checkedCheckboxes.includes(publicSources[1]))
      sourceType = 'OpenAI';
    else if (checkedCheckboxes.length === 1 && checkedCheckboxes.includes(publicSources[2]))
      sourceType = 'MarketReports';
    else if (
      checkedCheckboxes.length === 2 &&
      checkedCheckboxes.includes(publicSources[0]) &&
      checkedCheckboxes.includes(publicSources[1])
    )
      sourceType = 'EmbOpenAI';
  }
  if (eyIPInterRadio) {
    if (eyIPInterRadio === internalSources[0]) {
      sourceType = 'SurveyInsights';
    }
    if (eyIPInterRadio === internalSources[1]) {
      sourceType = 'SectorInsights';
    }
    if (eyIPInterRadio === internalSources[2]) {
      sourceType = 'Perspective';
    }
  }
  if (validURLinputValue) {
    sourceType = 'URL';
  }

  const fileName = `${sourceType}-${'conversation'}-${formattedDate}.${extension}`;
  return fileName;
};
