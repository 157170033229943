import React, { useEffect } from 'react';
import { sanitizeHeaderText } from '../utils';
import { IRelevantFiles, ISectorContent } from '../../../models/IQueryType';
import { Guid } from 'guid-typescript';
import './TableMarketReport.scss';
import {  downloadReport } from '../../../services/api.service';
import apiRoutes from '../../../services/api';

type TableContentProps = {
  tableContent: Partial<IRelevantFiles>[];
  handleSlideDownload?: (data: any) => void;
};

const TableMarketReport = ({ tableContent, handleSlideDownload }: TableContentProps) => {
  const [showAllRecords, setShowAllRecords] = React.useState<boolean>(false);
  const [visibleRecords, setVisibleRecords] = React.useState<any>();

  const visibledata = () => {
    if (!showAllRecords) {
      setVisibleRecords(tableContent?.slice(0, 3));
    } else {
      setVisibleRecords(tableContent?.slice(0, 5));
    }
  };

  const handleFileDownload = async (fileName: string) => {
    const response = await downloadReport(apiRoutes.marketFileDownload + fileName);
    if (response) {
      const linkSource = `data:application/pdf;base64,${response.pdf_data}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = response.pdf_filename;
      downloadLink.click();
    }
  };

  useEffect(() => {
    visibledata();
  }, [showAllRecords]);

  return (
    <div className='mrtable__wrapper-sector' key={`key-${Guid.create()}`}>
      <table className='mrtable__content-table-sector'>
        <tbody>
          <tr key={`mrtable-header-${'files'}`}>
            <th>{sanitizeHeaderText('Document Title')}</th>
            <th key={`mrtable-header-${'Page'}`}>{sanitizeHeaderText('Page no.s')}</th>
            <th key={`mrtable-header-${'Source'}`}>{sanitizeHeaderText('Source')}</th>
          </tr>

          {visibleRecords &&
            visibleRecords.length > 0 &&
            visibleRecords?.map((value: ISectorContent, i: number) => (
              <tr key={`mrtable-row-${value.file_name}`}>
                <td key={`mrtable-data-value-${value.file_name}`}>
                  <div
                    className='files-download-btn'
                    onClick={() => handleFileDownload(value.file_name)}>
                    {value?.file_name}
                  </div>
                </td>
                <td>
                  <span>
                    {value?.page_number?.map((val: any, i: number) =>
                      value.page_number.length - 1 === i ? (
                        <React.Fragment key={i.toString() + val}>
                          {' '}
                          <span
                            className='files-slide-download-btn'
                            key={i.toString() + val}>{`${val}`}</span>
                        </React.Fragment>
                      ) : (
                        <React.Fragment key={i.toString() + val}>
                          {' '}
                          <span
                            key={i.toString() + val}
                            className='files-slide-download-btn'>{`${val},`}</span>
                        </React.Fragment>
                      )
                    )}
                  </span>
                </td>
                <td>
                  <span>{value?.source}</span>
                </td>
              </tr>
            ))}
          {tableContent && tableContent?.length > 10 && (
            <tr key={`mrtable-row-${Guid.create()}`}></tr>
          )}
          {tableContent && tableContent?.length > 3 && (
            <tr key={`mrtable-row-${Guid.create()}`}>
              <td colSpan={3} className='mrtable__footer'>
                <button
                  className='btn-show'
                  onClick={() => {
                    setShowAllRecords(!showAllRecords);
                  }}>
                  {showAllRecords ? 'View less' : 'View more'}
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(TableMarketReport);
