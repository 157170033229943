import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import SectorGeographyHierarchy from '../../Hierarchy/SectorGeographyHierarchy';
import Dropdown from '../../NewDropdown/Dropdown';
import { SectorGeographyHierarchyFilter } from '../../Hierarchy/SectorGeographyHierarchyFilter';
import DropdownFooter from './DropdownFooter';
import './MarketReportsInsights.scss';

interface CustomFilterDropdownProps {
  isLoading: boolean;
  checkedSource: any[];
  isRightSideBarOpen?: boolean;
  isSideBarOpen?: boolean;
  filter: any;
  setInputFilter: boolean;
  treeNode: any;
  customCondition?: boolean;
  hasDefaultChecks?: boolean;
  defaultExpanded?: boolean;
  customCss?: string;
  setClearFilter?: boolean;
  placeholder?: string;
  title: string;
  toggleSelectedSource: () => void;
  setCheckBoxValue: (value: any) => void;
  clearAll: () => void;
  closeFilter: () => void;
  checkedNodes: any;
}

const CustomFilterDropdown: React.FC<CustomFilterDropdownProps> = ({
  isLoading,
  checkedSource,
  isRightSideBarOpen,
  isSideBarOpen,
  filter,
  setInputFilter,
  treeNode,
  setClearFilter,
  customCondition,
  hasDefaultChecks,
  defaultExpanded,
  customCss,
  placeholder,
  title,
  setCheckBoxValue,
  toggleSelectedSource,
  clearAll,
  closeFilter,
  checkedNodes = []
}) => {
  const memoizedCheckedNodes = useMemo(() => checkedNodes, [checkedNodes]);

  return (
    <>
      <div>
        <Dropdown
          isLoading={isLoading}
          disabled={isLoading || customCondition}
          options={[{ label: ' ', value: ' ' }]}
          onFocus={toggleSelectedSource}
          placeholder={placeholder}
          classCss={'no-class dropdown-parent-next'}
          showBackground={checkedSource?.length > 0 ? true : false}
          isSideBarOpen={isRightSideBarOpen}
        />
      </div>
      <div
        className={`${isRightSideBarOpen ? (isRightSideBarOpen && isSideBarOpen ? 'tableoverlapgeography-with-width-chat' : 'tableoverlapgeography-with-chat') : 'tableoverlapgeography'} ${customCss}`}>
        <div className='hierarchy-box'>
          <SectorGeographyHierarchyFilter
            filter={filter}
            placeholder={'Search'}
            setInputFilter={setInputFilter}
          />

          <div className='sticky-filter'>
            <SectorGeographyHierarchy
              title={title}
              treeNode={treeNode}
              setCheckBoxValue={setCheckBoxValue}
              setClearFilter={setClearFilter}
              disabled={isLoading || customCondition}
              hasDefaultChecks={hasDefaultChecks}
              defaultExpanded={defaultExpanded}
              checkedNodes={memoizedCheckedNodes}
            />
          </div>

          <DropdownFooter clearAll={clearAll} closeFilter={closeFilter} />
        </div>
      </div>
    </>
  );
};

export default CustomFilterDropdown;
