import React, { Fragment } from 'react';
import styled from 'styled-components';
import LoadIndicator from '../loadIndicator/LoadIndicator';
import sassVars from '../../../styles/variables.module.scss';
import { ModalType } from '../../../models/ModalType';
import Checkbox from '../Checkbox';
const { gray40, gray50 } = sassVars;

interface IOwnProps {
  type: ModalType;
  actionBtnEnabled: boolean;
  actionBtnText: string;
  cancelBtnText: string;
  onActionBtnClicked?: () => void;
  onCancelBtnClicked?: () => void;
  onCloseBtnClicked: () => void;
  onSkipBtnClicked?: () => void;
  isLoading?: boolean;
  footerClass?: string;
  tabIndex?: number;
  teamsWebURL?: string;
  onTeamsChannelClicked?: () => void;
  onChangeCheckBox?: (e: React.SyntheticEvent<HTMLInputElement>) => void;
}

const ModalFooter: React.FC<IOwnProps> = ({
  type,
  actionBtnEnabled,
  actionBtnText,
  cancelBtnText = 'Cancel',
  onActionBtnClicked,
  onCancelBtnClicked,
  onCloseBtnClicked,
  onSkipBtnClicked,
  isLoading,
  footerClass,
  tabIndex = 0,
  teamsWebURL,
  onTeamsChannelClicked,
  onChangeCheckBox
}: IOwnProps) => {
  const typeToLower = ModalType[type].toLowerCase();
  const getTriggerBtnClass = (): string => {
    switch (type) {
      case ModalType.Submit:
        return 'yellow-btn';
      case ModalType.Confirmation:
        return 'yellow-btn';
      case ModalType.Privacy:
        return 'yellow-btn';
      case ModalType.SourceChange:
        return 'cancel-btn';
      case ModalType.AdvancedSearch:
        return 'logout-btn';
      case ModalType.CreateNewProject:
        return 'next-preview-btn';
      default:
        return 'yellow-btn';
    }
  };

  return (
    <StyledModalFooter
      isLoading={isLoading}
      className={`${typeToLower}-modal ${footerClass} `}
      tabIndex={tabIndex}
      type={type}>
      {isLoading === true ? (
        <span className='loader'>
          <LoadIndicator />
        </span>
      ) : (
        <Fragment>
          {type === ModalType.Error ? (
            <div>
              {isLoading ? (
                <LoadIndicator />
              ) : (
                <button onClick={onCloseBtnClicked} className='btn secondary-btn'>
                  {actionBtnText}
                </button>
              )}
            </div>
          ) : (
            <Fragment>
              {type === ModalType.Terms ? (
                <>
                  {
                    <button className='btn secondary-btn privacy-modal' onClick={onCloseBtnClicked}>
                      {!isLoading ? 'Acknowledge' : ''}
                    </button>
                  }
                </>
              ) : type === ModalType.Privacy ? (
                <>
                  {
                    <button className='btn secondary-btn privacy-modal' onClick={onCloseBtnClicked}>
                      {!isLoading ? 'Acknowledge' : ''}
                    </button>
                  }
                </>
              ) : type === ModalType.Confirmation ? (
                <>
                  <button
                    className={`btn ${getTriggerBtnClass()}`}
                    disabled={!actionBtnEnabled}
                    onClick={onActionBtnClicked}>
                    {actionBtnText}
                  </button>
                  <button className='btn secondary-btn' onClick={onCloseBtnClicked}>
                    Cancel
                  </button>
                </>
              ) : type === ModalType.Warning ? (
                <div>
                  {isLoading ? (
                    <LoadIndicator />
                  ) : (
                    <button onClick={onCloseBtnClicked} className='btn yellow-btn'>
                      {actionBtnText}
                    </button>
                  )}
                </div>
              ) : type === ModalType.AdvancedSearch ? (
                <Fragment>
                  <button className='secondary-logout-btn' onClick={onCloseBtnClicked}>
                    {cancelBtnText}
                  </button>
                  <button
                    className={`${getTriggerBtnClass()}`}
                    disabled={!actionBtnEnabled}
                    onClick={onActionBtnClicked}>
                    {actionBtnText}
                  </button>
                </Fragment>
              ) : type === ModalType.SourceChange ? (
                <div className='group-btn'>
                  <button
                    className={`btn ${getTriggerBtnClass()}`}
                    disabled={!actionBtnEnabled}
                    onClick={onActionBtnClicked}>
                    {actionBtnText}
                  </button>
                  <button className='btn continue-btn' onClick={onCloseBtnClicked}>
                    Continue
                  </button>
                </div>
              ) : type === ModalType.CreateNewProject ? (
                <Fragment>
                  {tabIndex <= 5 && (
                    <Fragment>
                      {cancelBtnText && (
                        <button className='back-cancel-btn' onClick={onCancelBtnClicked}>
                          {cancelBtnText}
                        </button>
                      )}
                      {tabIndex === 2 && (
                        <button className='back-cancel-btn' onClick={onSkipBtnClicked}>
                          Skip step
                        </button>
                      )}
                      <button
                        className={`${getTriggerBtnClass()}`}
                        disabled={!actionBtnEnabled}
                        onClick={onActionBtnClicked}>
                        {actionBtnText}
                      </button>
                    </Fragment>
                  )}
                  {(tabIndex === 6 || tabIndex === 7) && (
                    <a
                      onClick={onTeamsChannelClicked}
                      className={
                        'teams-channel-' + (tabIndex === 6 || !teamsWebURL ? 'disabled' : 'enabled')
                      }>
                      Open Teams Channel
                    </a>
                  )}
                </Fragment>
              ) : type === ModalType.Feedback ? (
                <Fragment>
                  {cancelBtnText && (
                    <button className='back-cancel-btn' onClick={onCancelBtnClicked}>
                      Cancel
                    </button>
                  )}
                  {actionBtnText && (
                    <button
                      className='btn secondary-btn feedback-submit-button'
                      onClick={onActionBtnClicked}
                      disabled={!actionBtnEnabled}>
                      {!isLoading ? 'Submit' : ''}
                    </button>
                  )}
                </Fragment>
              ) : type === ModalType.MyJuniorAI ? (
                <Fragment>
                  <>
                    <span className='left-footer'>
                      {tabIndex == 2 && (
                        <Checkbox
                          text={'Acknowledge, I agree to the privacy statement.'}
                          checked={actionBtnEnabled}
                          key={'Acknowledge, I agree to the privacy statement.'}
                          name='agree' //@ts-ignore
                          onChange={onChangeCheckBox}
                        />
                      )}
                    </span>
                    <span className='right-footer'>
                      {cancelBtnText && (
                        <button className='back-cancel-btn' onClick={onCancelBtnClicked}>
                          Cancel
                        </button>
                      )}
                      {
                        <button
                          className={`btn secondary-btn myjunior-modal-btn ${actionBtnEnabled ? 'enabled' : 'disabled'}`}
                          onClick={onActionBtnClicked}
                          disabled={tabIndex == 1 ? false : !actionBtnEnabled}>
                          {tabIndex == 1 ? 'Acknowledge' : 'Launch My Junior.AI'}
                        </button>
                      }
                    </span>
                  </>
                </Fragment>
              ) : (
                <>
                  <button className='btn secondary-btn' onClick={onCancelBtnClicked}>
                    Cancel
                  </button>
                  <button
                    className={`btn ${getTriggerBtnClass()}`}
                    disabled={!actionBtnEnabled}
                    onClick={onActionBtnClicked}>
                    {actionBtnText}
                  </button>
                </>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </StyledModalFooter>
  );
};

ModalFooter.displayName = 'ModalFooter';

const StyledModalFooter = styled.div<{
  isLoading?: boolean;
  tabIndex?: number;
  type?: ModalType;
}>`
  position: fixed;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  padding: ${(props) =>
    props.tabIndex == 1 && props.type == ModalType.MyJuniorAI ? '25px 10px' : '10px'} !important;
  bottom: 0;
  background: white;
  width: ${(props) =>
    props.isLoading
      ? 'auto'
      : props.tabIndex == 2 && props.type == ModalType.MyJuniorAI
        ? '99%'
        : '98%'} !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: ${(props) =>
    props.tabIndex == 2 && props.type == ModalType.MyJuniorAI
      ? '1px solid grey'
      : 'none'} !important;
  z-index: 1;
  .loader {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .center-allign {
    text-align: center;
  }

  .btn .secondary-btn {
    background: ${gray50} !important;
  }
  .cancel-btn {
    color: #2e2e38;
    border: none;
    background-color: #fff;
    border: 1px solid ${gray40};
    width: 111px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
  }

  .group-btn {
    justify-content: flex-end;
    display: flex;
    margin-right: 18px;
    margin-top: -10px;
  }
  .continue-btn {
    color: #f9f9fa;
    margin-left: 5px;
    background: #2e2e38;
    width: 115px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
    &:hover {
      background: grey;
    }

    &:disabled {
      border: 1px solid #3e404c;
    }
  }

  .btn .secondary-btn {
    background: ${gray50} !important;
  }
  .cancel-btn {
    color: #2e2e38;
    border: none;
    background-color: #fff;
    border: 1px solid ${gray40};
    width: 111px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
  }

  .group-btn {
    justify-content: flex-end;
    display: flex;
    margin-right: 18px;
    margin-top: -10px;
  }
  .continue-btn {
    color: #f9f9fa;
    margin-left: 5px;
    background: #2e2e38;
    width: 115px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
    &:hover {
      background: grey;
    }

    &:disabled {
      border: 1px solid #3e404c;
    }
  }

  .btn .secondary-btn {
    background: ${gray50} !important;
  }
  .cancel-btn {
    color: #2e2e38;
    border: none;
    background-color: #fff;
    border: 1px solid ${gray40};
    width: 111px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
  }

  .group-btn {
    justify-content: flex-end;
    display: flex;
    margin-right: 18px;
    margin-top: -10px;
  }
  .continue-btn {
    color: #f9f9fa;
    margin-left: 5px;
    background: #2e2e38;
    width: 115px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
    &:hover {
      background: grey;
    }

    &:disabled {
      border: 1px solid #3e404c;
    }
  }

  .btn .secondary-btn {
    background: ${gray50} !important;
  }
  .cancel-btn {
    color: #2e2e38;
    border: none;
    background-color: #fff;
    border: 1px solid ${gray40};
    width: 111px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
  }

  .group-btn {
    justify-content: flex-end;
    display: flex;
    margin-right: 18px;
    margin-top: -10px;
  }
  .continue-btn {
    color: #f9f9fa;
    margin-left: 5px;
    background: #2e2e38;
    width: 115px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
    &:hover {
      background: grey;
    }

    &:disabled {
      border: 1px solid #3e404c;
    }
  }

  .btn .secondary-btn {
    background: ${gray50} !important;
  }
  .cancel-btn {
    color: #2e2e38;
    border: none;
    background-color: #fff;
    border: 1px solid ${gray40};
    width: 111px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
  }

  .group-btn {
    justify-content: flex-end;
    display: flex;
    margin-right: 18px;
    margin-top: -10px;
  }
  .continue-btn {
    color: #f9f9fa;
    margin-left: 5px;
    background: #2e2e38;
    width: 115px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
    &:hover {
      background: grey;
    }

    &:disabled {
      border: 1px solid #3e404c;
    }
  }

  .btn .secondary-btn {
    background: ${gray50} !important;
  }
  .cancel-btn {
    color: #2e2e38;
    border: none;
    background-color: #fff;
    border: 1px solid ${gray40};
    width: 111px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
  }

  .group-btn {
    justify-content: flex-end;
    display: flex;
    margin-right: 18px;
    margin-top: -10px;
  }
  .continue-btn {
    color: #f9f9fa;
    margin-left: 5px;
    background: #2e2e38;
    width: 115px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
    &:hover {
      background: grey;
    }

    &:disabled {
      border: 1px solid #3e404c;
    }
  }

  .btn .secondary-btn {
    background: ${gray50} !important;
  }
  .cancel-btn {
    color: #2e2e38;
    border: none;
    background-color: #fff;
    border: 1px solid ${gray40};
    width: 111px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
  }

  .group-btn {
    justify-content: flex-end;
    display: flex;
    margin-right: 18px;
    margin-top: -10px;
  }
  .continue-btn {
    color: #f9f9fa;
    margin-left: 5px;
    background: #2e2e38;
    width: 115px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
    &:hover {
      background: grey;
    }

    &:disabled {
      border: 1px solid #3e404c;
    }
  }

  .btn .secondary-btn {
    background: ${gray50} !important;
  }
  .cancel-btn {
    color: #2e2e38;
    border: none;
    background-color: #fff;
    border: 1px solid ${gray40};
    width: 111px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
  }

  .group-btn {
    justify-content: flex-end;
    display: flex;
    margin-right: 18px;
    margin-top: -10px;
  }
  .continue-btn {
    color: #f9f9fa;
    margin-left: 5px;
    background: #2e2e38;
    width: 115px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
    &:hover {
      background: grey;
    }

    &:disabled {
      border: 1px solid #3e404c;
    }
  }

  .btn .secondary-btn {
    background: ${gray50} !important;
  }
  .cancel-btn {
    color: #2e2e38;
    border: none;
    background-color: #fff;
    border: 1px solid ${gray40};
    width: 111px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
  }

  .group-btn {
    justify-content: flex-end;
    display: flex;
    margin-right: 18px;
    margin-top: -10px;
  }
  .continue-btn {
    color: #f9f9fa;
    margin-left: 5px;
    background: #2e2e38;
    width: 115px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
    &:hover {
      background: grey;
    }

    &:disabled {
      border: 1px solid #3e404c;
    }
  }

  .btn .secondary-btn {
    background: ${gray50} !important;
  }
  .cancel-btn {
    color: #2e2e38;
    border: none;
    background-color: #fff;
    border: 1px solid ${gray40};
    width: 111px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
  }

  .group-btn {
    justify-content: flex-end;
    display: flex;
    margin-right: 18px;
    margin-top: -10px;
  }
  .continue-btn {
    color: #f9f9fa;
    margin-left: 5px;
    background: #2e2e38;
    width: 115px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
    &:hover {
      background: grey;
    }

    &:disabled {
      border: 1px solid #3e404c;
    }
  }

  .btn .secondary-btn {
    background: ${gray50} !important;
  }
  .cancel-btn {
    color: #2e2e38;
    border: none;
    background-color: #fff;
    border: 1px solid ${gray40};
    width: 111px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
  }

  .group-btn {
    justify-content: flex-end;
    display: flex;
    margin-right: 18px;
    margin-top: -10px;
  }
  .continue-btn {
    color: #f9f9fa;
    margin-left: 5px;
    background: #2e2e38;
    width: 115px;
    height: 48px;
    font-family: EYInterstate;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 400;
    border-radius: 4px;
    padding: 10px, 18px, 10px, 18px;
    &:hover {
      background: grey;
    }

    &:disabled {
      border: 1px solid #3e404c;
    }
  }

  &.error-modal {
    justify-content: center;
  }

  &.submit-modal,
  &.confirmation-modal {
    justify-content: space-between;
  }

  .teams-channel-disabled {
    color: #9897a6;
    pointer-events: none;
  }
  .teams-channel-enabled {
    color: #155cb4;
    cursor: pointer;
  }
  .left-footer {
    padding: ${(props) =>
      props.tabIndex == 2 && props.type == ModalType.MyJuniorAI ? '1%' : '1% 0% 1% 1%'} !important;
    margin-top: 1%;
    width: 50%;
  }
  .right-footer {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    padding: ${(props) =>
      props.tabIndex == 2 && props.type == ModalType.MyJuniorAI ? '1% 0%' : '0%'} !important;
  }
  .myjunior-modal-btn {
    &:disabled {
      opacity: 0.5;
      border: 1px solid #3e404c;
      cursor: not-allowed !important;
    }
  }
`;

export default ModalFooter;
